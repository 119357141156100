import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { readFileAsDataURL } from '../../../utils/files';
import { isImageDrop, getExternalImageSource } from './images';
const addImage = (schema, view, source, position, dispatchTransaction) => {
    const node = schema.nodes.image.create({
        src: source
    });
    const existingImageBelow = view.state.doc.nodeAt(position - 2);
    const existingImageSameRow = view.state.doc.nodeAt(position);
    const existingImageAbove = view.state.doc.nodeAt(position - 1);
    if ((existingImageBelow && existingImageBelow.type.name === 'image' && existingImageBelow.attrs.src === source) ||
        (existingImageSameRow && existingImageSameRow.type.name === 'image' && existingImageSameRow.attrs.src === source) ||
        (existingImageAbove && existingImageAbove.type.name === 'image' && existingImageAbove.attrs.src === source)) {
        return;
    }
    const transaction = view.state.tr.insert(position, node);
    // edge case: when there are more than one active editors on the text editor
    // and when dropping external image from google (not local image) we should not dispatch transaction
    // because by default once the transaction is dispatched and we are getting the same image duplicated.
    // dispatch the transaction only for local images
    if (dispatchTransaction) {
        view.dispatch(transaction);
    }
};
export const DropImageEventHandler = Extension.create({
    name: 'dropImageEventHandler',
    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('dropImageEventHandler'),
                props: {
                    handleDrop(view, event) {
                        if (isImageDrop(event)) {
                            const images = Array
                                .from(event.dataTransfer.files)
                                .filter(file => (/image/i).test(file.type));
                            event.preventDefault();
                            const { schema } = view.state;
                            const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
                            if (!coordinates)
                                return;
                            if (images.length) {
                                // local image
                                images.forEach(async (image) => {
                                    const file = await readFileAsDataURL(image);
                                    addImage(schema, view, file, coordinates.pos, true);
                                });
                            }
                            else {
                                // external image
                                const source = getExternalImageSource(event);
                                if (source) {
                                    addImage(schema, view, source, coordinates.pos, false);
                                }
                            }
                        }
                    }
                }
            })
        ];
    }
});
