import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';
export const StrikeThrough = Extension.create({
    name: 'strikeThrough',
    addOptions() {
        return {
            types: ['textStyle']
        };
    },
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    textDecoration: {
                        default: null,
                        parseHTML: element => element.style.textDecoration,
                        renderHTML: attributes => {
                            if (!attributes.textDecoration) {
                                return {};
                            }
                            return {
                                style: `text-decoration: ${attributes.textDecoration}`
                            };
                        }
                    }
                }
            }
        ];
    },
    addCommands() {
        return {
            toggleStrikeThrough: () => ({ commands, editor }) => {
                const isActive = editor.isActive('textStyle', { textDecoration: 'line-through' });
                if (isActive) {
                    return commands.updateAttributes('textStyle', { textDecoration: null });
                }
                else {
                    // because we used the strike extension before this, we need to unset the mark first
                    // otherwise we would have two marks
                    commands.unsetMark('strike');
                    return commands.updateAttributes('textStyle', { textDecoration: 'line-through' });
                }
            }
        };
    }
});
