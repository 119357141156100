import { maxLength, minLength, maxValue, minValue, integer, required } from 'vuelidate/lib/validators';
export const FIELD_TYPE = {
    TEXT: 'text',
    NUMBER: 'number',
    COMBO_BOX: 'choice',
    DATE_PICKER: 'date',
    COMPONENT: 'component'
};
export const FIELDS_PER_IC_STATUS = {
    REWORK: [{
            type: FIELD_TYPE.TEXT,
            name: 'reason_to_rework',
            label: 'Reason to rework',
            validation: { required, maxLength: maxLength(1024) }
        }],
    ONHOLD: [{
            type: FIELD_TYPE.TEXT,
            name: 'reason_to_hold',
            label: 'Reason to save for later',
            validation: { required, maxLength: maxLength(1024) }
        }],
    STOP: [{
            type: FIELD_TYPE.COMBO_BOX,
            name: 'reason_to_stop',
            label: 'Reason to stop',
            choices: [
                'Prior art - Full',
                'Prior art - Partial',
                'Out of scope - Known',
                'Out of scope - Unclear scope',
                'Overly complex',
                'Not inventive',
                'Not enabled',
                'Non-technical',
                'Embodiment not proven/convincing',
                'Benefit not compelling',
                'Perception issues',
                'Similar to other Iprova invention',
                'Unknown'
            ],
            validation: { required, maxLength: maxLength(1024) }
        }],
    ACCPTD: [{
            type: FIELD_TYPE.DATE_PICKER,
            name: 'accepted_date',
            label: 'Accepted date',
            maxDate: (new Date()).toISOString().split('T')[0],
            showDatePicker: false,
            validation: { required }
        }],
    REJCTD: [{
            type: FIELD_TYPE.COMBO_BOX,
            name: 'reason_to_stop',
            label: 'Reason to stop',
            choices: [
                'Prior art - Full',
                'Prior art - Partial',
                'Out of scope - Known',
                'Out of scope - Unclear scope',
                'Overly complex',
                'Embodiment not proven/convincing',
                'Benefit not compelling',
                'Perception issues',
                'Similar to other Iprova invention',
                'Unknown'
            ],
            validation: { required, maxLength: maxLength(1024) }
        }]
};
export const FIELD_VALIDATION = {
    required: function () { return required; },
    maxLength: function (length) { return maxLength(length); },
    minLength: function (length) { return minLength(length); },
    maxValue: function (value) { return maxValue(value); },
    minValue: function (value) { return minValue(value); },
    integer: function () { return integer; }
};
