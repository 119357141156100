import { isString } from 'lodash';
const mdEscapeRe = /[\\`*_{}[\]()#]/g;
export function markdownEscape(s) {
    return s.replace(mdEscapeRe, (match) => `\\${match}`);
}
export function htmlEscape(string) {
    if (!string || !isString(string))
        return string;
    return string
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;');
}
export function normalize(text) {
    return text.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}
/*
    cyrb53 (c) 2018 bryc (github.com/bryc)
    License: Public domain. Attribution appreciated.
    A fast and simple 53-bit string hash function with decent collision resistance.
    Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.

    DO NOT USE THIS FOR CRYPTOGRAPHY!
*/
export const hash = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};
export const ensureUrlHasProtocol = (url) => {
    if (!url || url.includes('://'))
        return url;
    return `https://${url}`;
};
