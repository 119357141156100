import isEqual from 'lodash/isEqual'

function getRouteUniqueKey() {
  return `pending-change-${document.location.pathname}`
}

let loadPage = {}

function processValueLoad(storeKey, component) {
  if (!storeKey) {
    throw Error('The directive should always have value. Example: v-store-pending-change="my_var"')
  }

  const routeKey = getRouteUniqueKey()

  if (!Object.hasOwn(loadPage, routeKey)) {
    const storedFormValues = JSON.parse(localStorage.getItem(routeKey) || '{}')

    if (!storedFormValues) {
      loadPage[routeKey] = false
      return
    }

    const storedNodeValue = storedFormValues[storeKey]
    if (!storedNodeValue || isEqual(storedNodeValue, component.value)) return

    const shouldLoad = window.confirm('Some unsaved changes have been detected on the current page. Would you like to reload them?')

    loadPage[routeKey] = shouldLoad ? storedFormValues : false
  }

  if (loadPage[routeKey]?.[storeKey]) {
    component.$emit('input', loadPage[routeKey][storeKey])
  }
}

function processValueChange(storeKey, component) {
  if (!storeKey) {
    throw Error('The directive should always have value. Example: v-store-pending-change="my_var"')
  }

  const currentRouteValue = JSON.parse(localStorage.getItem(getRouteUniqueKey()) || '{}')
  if (!currentRouteValue[storeKey] && !component.value) return
  currentRouteValue[storeKey] = component.value

  localStorage.setItem(getRouteUniqueKey(), JSON.stringify(currentRouteValue))
}

export default {
  bind(el, { value: storeKey }, { componentInstance: component }) {
    processValueLoad(storeKey, component)
  },
  update(el, { value: storeKey }, { componentInstance: component }) {
    processValueChange(storeKey, component)
  },
  clearPendingChanges() {
    localStorage.removeItem(getRouteUniqueKey())
    loadPage = {}
  }
}
