import { noop, useDebounceFn, useThrottleFn } from '@vueuse/core';
import { computed, ref } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
export function useAsyncState(provider, initialState, options = {}) {
    const initial = cloneDeep(initialState);
    const { immediate = false, onError = noop, resetOnExecute = false, debounceMs = 0, throttleMs = 0, allowParallel = false, singleton = false } = options;
    if (debounceMs > 0 && throttleMs > 0) {
        throw new Error('useAsyncState: both debounce and throttle cannot be used together');
    }
    const state = ref(initialState);
    const promise = ref();
    const loading = computed(() => promise.value !== undefined);
    const error = ref(undefined);
    const loaded = ref(false);
    const reset = () => {
        state.value = initial;
        promise.value = undefined;
        error.value = undefined;
    };
    const abortController = ref(null);
    let execute = async () => {
        if (promise.value && (!allowParallel || singleton))
            return promise.value;
        if (singleton && loaded.value)
            return state.value;
        if (resetOnExecute)
            state.value = initial;
        error.value = undefined;
        try {
            // In order abort controller to work, 'allowParallel' must be enabled (true)
            if (abortController.value)
                abortController.value.abort();
            // create a new controller for the current request
            abortController.value = new AbortController();
            const abortSignal = abortController.value.signal;
            promise.value = provider(abortSignal);
            state.value = await promise.value;
            loaded.value = true;
        }
        catch (e) {
            error.value = e;
            state.value = initialState;
            onError(e);
        }
        finally {
            promise.value = undefined;
        }
        return state.value;
    };
    if (debounceMs > 0)
        execute = useDebounceFn(execute, debounceMs);
    if (throttleMs > 0)
        execute = useThrottleFn(execute, throttleMs);
    if (immediate)
        execute();
    return { state, loading, error, execute, reset };
}
