import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { isValidHttpUrl } from '../../../utils/validations';
import { useAsyncState } from '../../../utils/asyncState';
import { fetchForUrls } from '../../../api/triggers';
import { useTrackedTriggersStore } from '../../triggers/store/trackedTriggers';
export const useInsertTriggerDialogStore = defineStore('insertTriggerDialogStore', () => {
    const inputText = ref('');
    const inputTitle = ref('');
    const isUrl = computed(() => isValidHttpUrl(inputText.value));
    const isTrackedId = computed(() => /^#?\d+$/.test(inputText.value));
    const isValid = computed(() => Boolean(trigger));
    const trackedTriggersStore = useTrackedTriggersStore();
    const trigger = useAsyncState(async () => {
        if (isTrackedId.value) {
            return trackedTriggersStore.trackedTriggers.find((tt) => tt.id === parseInt(inputText.value) || tt.virtual_id === inputText.value);
        }
        else if (isUrl.value) {
            const foundTt = trackedTriggersStore.trackedTriggers.find((tt) => tt.url === inputText.value);
            if (foundTt)
                return foundTt;
            const triggers = (await fetchForUrls([inputText.value])).data.triggers;
            if (!triggers?.length) {
                return {
                    url: inputText.value,
                    title: inputTitle.value,
                    essential_features: []
                };
            }
            return triggers[0];
        }
    }, undefined, { debounceMs: 500 });
    watch(inputText, trigger.execute);
    watch(inputTitle, () => {
        if (trigger.state.value && trigger.state.value.title !== inputTitle.value) {
            trigger.state.value.title = inputTitle.value;
        }
    });
    watch(trigger.state, () => {
        if (trigger.state.value) {
            if (trigger.state.value.title && trigger.state.value.title !== inputTitle.value) {
                inputTitle.value = trigger.state.value.title;
            }
        }
    }, { deep: true });
    function reset() {
        inputText.value = '';
        inputTitle.value = '';
        trigger.reset();
    }
    return {
        inputText,
        inputTitle,
        isUrl,
        isTrackedId,
        isValid,
        trigger: trigger.state,
        loading: trigger.loading,
        reset
    };
});
