export default function (code) {
    if (!window.requestIdleCallback)
        return Promise.resolve(code());
    return new Promise((resolve, reject) => {
        requestIdleCallback(() => {
            try {
                resolve(code());
            }
            catch (e) {
                reject(e);
            }
        });
    });
}
