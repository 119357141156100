import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { deleteInventionConcept, getInventionConcepts } from '../../../api/inventionConcepts';
import { projectsStore } from '../../projects/store';
import { EXPORT_FIELDS } from '../utils/constants';
import { getInventionConceptsExcel } from '../../../api/inventionConceptsExport';
import { downloadFile } from '../../../utils/export';
import sortBy from 'lodash/sortBy';
import { useUsersStore } from '../../users/store';
import { fuzzySearchSync } from '../../../utils/search';
import { FILE_TYPES } from '../../../interfaces/exports';
export const useInventionConceptsStore = defineStore('invention-concepts', () => {
    const allInventionConcepts = ref([]);
    const selectedInventionConcepts = ref([]);
    const allICsSelected = computed(() => {
        return selectedInventionConcepts.value.length > 0;
    });
    const sortColumn = ref('');
    const sortDirection = ref(true); // true = asc, false = desc
    const projectStore = projectsStore();
    const usersStore = useUsersStore();
    const handleSort = (column) => {
        if (sortColumn.value === column) {
            sortDirection.value = !sortDirection.value;
        }
        else {
            sortColumn.value = column;
            sortDirection.value = true;
        }
    };
    const filters = ref({
        search: '',
        status: [],
        owner_ids: []
    });
    const page = ref(1);
    const itemsPerPage = 11;
    const inventionConcepts = computed(() => {
        return fuzzySearchSync(allInventionConcepts.value, filters.value.search, ['title', 'concept'], [{
                enabled: () => filters.value.status.length > 0,
                filter: (ic) => filters.value.status.includes(ic.status ?? '')
            }, {
                enabled: () => filters.value.owner_ids.length > 0,
                filter: (ic) => filters.value.owner_ids.includes(ic.owner.id)
            }]);
    });
    const numberOfPages = computed(() => {
        return Math.ceil(inventionConcepts.value.length / itemsPerPage);
    });
    const icsToDisplay = computed(() => {
        let filtered = inventionConcepts.value;
        if (sortColumn.value) {
            if (sortColumn.value === 'owner')
                filtered = sortBy(filtered, ic => ic.owner.first_name);
            else
                filtered = sortBy(filtered, sortColumn.value);
            if (!sortDirection.value)
                filtered = filtered.reverse();
        }
        return filtered.slice((page.value - 1) * itemsPerPage, Math.min(filtered.length, page.value * itemsPerPage));
    });
    const getAllInventionConcepts = async () => {
        if (!projectStore.currentProjectId)
            return;
        allInventionConcepts.value = (await getInventionConcepts({ project_id: projectStore.currentProjectId })).data;
    };
    const getInventorFullName = (user) => {
        return `${user.first_name} ${user.last_name}`;
    };
    const exportIcs = async (icId) => {
        const params = {
            id__in: icId ? icId.toString() : selectedInventionConcepts.value.join(','),
            fields: EXPORT_FIELDS.join(',')
        };
        const response = await getInventionConceptsExcel(params, FILE_TYPES.XLSX);
        let projectName = '';
        if (projectStore.currentProject?.name) {
            projectName = projectStore.currentProject.name;
        }
        else {
            projectName = 'file';
        }
        downloadFile(response.data, FILE_TYPES.XLSX, projectName);
    };
    const toggleAllICsSelected = () => {
        if (allICsSelected.value) {
            selectedInventionConcepts.value = [];
        }
        else {
            selectedInventionConcepts.value = inventionConcepts.value.map(ic => ic.id);
        }
    };
    const removeInventionConcept = async (inventionConceptId) => {
        await deleteInventionConcept(inventionConceptId);
        await getAllInventionConcepts();
    };
    const bulkDeleteInventionConcepts = async () => {
        await Promise.all(selectedInventionConcepts.value.map(async (icId) => {
            await deleteInventionConcept(icId);
        }));
        await getAllInventionConcepts();
        selectedInventionConcepts.value = [];
    };
    const usersToFilter = computed(() => {
        return projectStore.currentProjectUsers?.map(userInProject => {
            const user = usersStore.allUsers?.find(user => user.id === userInProject.id);
            if (!user)
                return null;
            return { text: `${user?.first_name} ${user?.last_name}`, value: user?.id };
        }).filter(user => user !== null);
    });
    const titleSortOrder = computed(() => {
        if (!sortDirection.value && sortColumn.value === 'title')
            return 'mdi-sort-alphabetical-descending';
        return 'mdi-sort-alphabetical-ascending';
    });
    const statusSortOrder = computed(() => {
        if (!sortDirection.value && sortColumn.value === 'status')
            return 'mdi-sort-alphabetical-descending';
        return 'mdi-sort-alphabetical-ascending';
    });
    const inventorSortOrder = computed(() => {
        if (!sortDirection.value && sortColumn.value === 'owner')
            return 'mdi-sort-alphabetical-descending';
        return 'mdi-sort-alphabetical-ascending';
    });
    const dateSortOrder = computed(() => {
        if (!sortDirection.value && sortColumn.value === 'created_at')
            return 'mdi-sort-calendar-descending';
        return 'mdi-sort-calendar-ascending';
    });
    const idSortOrder = computed(() => {
        if (!sortDirection.value && sortColumn.value === 'id_in_project')
            return 'mdi-sort-numeric-descending';
        return 'mdi-sort-numeric-ascending';
    });
    watch(() => projectStore.currentProjectId, getAllInventionConcepts);
    return {
        allInventionConcepts,
        inventionConcepts,
        selectedInventionConcepts,
        allICsSelected,
        page,
        numberOfPages,
        icsToDisplay,
        filters,
        sortColumn,
        sortDirection,
        usersToFilter,
        titleSortOrder,
        statusSortOrder,
        inventorSortOrder,
        dateSortOrder,
        idSortOrder,
        handleSort,
        getAllInventionConcepts,
        getInventorFullName,
        toggleAllICsSelected,
        exportIcs,
        removeInventionConcept,
        bulkDeleteInventionConcepts
    };
});
