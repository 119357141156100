import debounce from 'lodash/debounce';
import { iwbEventBus } from './EventBus';
// callback is called on intersection change
// exporting it in order to be tested
export function onIntersection(entries) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            iwbEventBus.$emit('setCurrentBlock', entry.target.id);
        }
    });
}
const onIntersectionDebounced = debounce(onIntersection, 2000);
export const createObserver = function (root = null, threshold = 1) {
    return new IntersectionObserver(onIntersectionDebounced, {
        root,
        threshold // percentage of target's visible area. Triggers "onIntersection"
    });
};
