export default [
  {
    path: '/legacy/projects',
    name: 'legacy-projects',
    meta: {
      noProjectNeeded: true,
      legacy: true
    },
    component: () => import(/* webpackChunkName: "projects" */ '@/legacy_modules/projects/views/Projects.vue')
  },
  {
    path: '/legacy/project/new',
    name: 'legacy-project-new',
    meta: {
      noProjectNeeded: true,
      legacy: true
    },
    component: () => import(/* webpackChunkName: "project-new" */ '@/legacy_modules/projects/views/ProjectForm.vue')
  },
  {
    path: '/legacy/project/:project_id',
    name: 'legacy-project',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "project" */ '@/legacy_modules/projects/views/Project.vue')
  },
  {
    path: '/legacy/project/:project_id/edit',
    name: 'legacy-project-edit',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "project-edit" */ '@/legacy_modules/projects/views/ProjectForm.vue')
  }
]
