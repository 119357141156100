export function downloadFile(file, fileType, name) {
    const blob = new Blob([file], { type: fileType.dataType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // set the file name
    a.download = `${name.replace(/ /g, '_')}.${fileType.format}`;
    a.click();
    window.URL.revokeObjectURL(url);
}
export function getSVGString(svgNode) {
    svgNode.setAttribute('xlink', 'http://www.w3.org/1999/xlink');
    svgNode.setAttribute('width', svgNode.clientWidth); // This two lines are a bug fix for Mozilla Firefox, this is a workaround
    svgNode.setAttribute('height', svgNode.clientHeight); // source:https://stackoverflow.com/questions/70584615/canvas-toblob-working-in-chrome-but-not-working-with-firefox
    const serializer = new XMLSerializer();
    let svgString = serializer.serializeToString(svgNode);
    svgString = svgString.replace(/(\w+)?:?xlink=/g, 'xmlns:xlink=');
    svgString = svgString.replace(/NS\d+:href/g, 'xlink:href'); // namespace fix for Safari
    return svgString;
}
export function downloadSvgStringAsImage(svgString, fileType, fileName, width, height) {
    const imgsrc = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgString)))}`;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    const image = new Image();
    image.onload = function () {
        if (context === null)
            return;
        context.clearRect(0, 0, width, height);
        context.drawImage(image, 0, 0, width, height);
        canvas.toBlob(function (blob) {
            if (blob === null)
                return;
            downloadFile(blob, fileType, fileName);
        });
    };
    image.src = imgsrc;
}
