import { attachWatcher } from '@/utils/instrumentizeCore/componentModifiers'
import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'
import isFunction from 'lodash/isFunction'

export function monitorStates(component, valueName, config) {
  /**
     * DON'T USE DIRECTLY. See instrumentize.js:monitorStates
     */
  function statesWatcher() {
    this.$instruments.spans[valueName]?.end()
    if (config.ignore.indexOf(this[valueName]) >= 0) delete this.$instruments.spans[valueName]
    else {
      this.$instruments.spans[valueName] = this.$instruments.transaction.startSpan(
                `${component.name}.${valueName}:${this[valueName]}`,
                `custom.monitorStates.${valueName}`
      )
      this.$instruments.spans[valueName]?.addLabels(this.$instruments.labels)
    }
  }

  component.$instrumentizedWatchers.push(statesWatcher)

  attachWatcher(component, valueName, statesWatcher)
}

export function logCalls(component, methodName) {
  /**
     * DON'T USE DIRECTLY. See instrumentize.js:logCalls
     */
  const methodToWrap = component.methods[methodName]

  async function wrappedFunction() {
    if (this.$instruments.spans[methodName]) throw Error(`recursive call to ${methodName}`)
    this.$instruments.spans[methodName] = this.$instruments.transaction.startSpan(
            `${component.name}:${methodName}`,
            `custom.logCall.${methodName}`
    )
    this.$instruments.spans[methodName]?.addLabels(this.$instruments.labels)
    if (isObject(component.apmLabels)) {
      this.$instruments.spans[methodName]?.addLabels(
        mapValues(component.apmLabels, value => isFunction(value) ? value.call(this) : value)
      )
    }
    try {
      await methodToWrap.call(this, ...arguments)
    } finally {
      this.$instruments.spans[methodName].end()
      this.$instruments.spans[methodName] = undefined
    }
  }

  component.methods[methodName] = wrappedFunction
}
