import { isEqual } from 'lodash/lang'
import storePendingChanges from '@/directive/storePendingChanges/storePendingChanges'
import { appStore } from '@/store/modules/app'

const leaveGuardArg = {
  DIRTY: 'dirty',
  VALUE: 'value'
}
const validArguments = Object.values(leaveGuardArg)

function setPendingChangesFlag(hasPendingChanges) {
  appStore().hasPendingChanges = hasPendingChanges
  if (!hasPendingChanges) {
    storePendingChanges.clearPendingChanges()
  }
}

function processDirective({ oldValue, value, arg }) {
  /**
   * value: What is after the '=' (Depends on each instruction.)
   * arg: What is after the ':' (Possible values: dirty, value)
   * v-leaveGuard:dirty expects Boolean
   * v-leaveGuard:value expects the field value
   *
   * Example: v-leaveGuard:dirty="true"
   */

  // If no argument and value are provided to the directive raise an error
  if (!arg || value === undefined) {
    throw new Error('The directive should always have arg and value. Example: v-leaveGuard:arg="value"')
  }
  if (!validArguments.includes(arg)) {
    throw new Error(`The ${arg} is not a valid leaveGuard argument!
    Valid arguments: ${validArguments.join(', ')}`
    )
  }
  // if the element is dirty, than there are pending changes
  if (arg === leaveGuardArg.DIRTY) {
    setPendingChangesFlag(Boolean(value))
  }
  // if we send the element value to the directive than we set the pending changes
  // if the value has changed
  if (arg === leaveGuardArg.VALUE && !isEqual(value, oldValue)) {
    setPendingChangesFlag(true)
  }
}

export default {
  inserted(el, binding) {
    processDirective(binding)
  },
  update(el, binding) {
    processDirective(binding)
  },
  setPendingChangesFlag
}
