export default [
  {
    path: '/project/:project_id/inventionconcepts',
    name: 'project-inventionconcepts',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "project-inventionconcepts" */ '@/legacy_modules/inventionConcept/views/InventionConcepts.vue')
  },
  {
    path: '/project/:project_id/inventionconcepts/:ic_id',
    name: 'project-inventionconcept',
    meta: { pageTitle: 'IC list', legacy: true },
    component: () => import(/* webpackChunkName: "project-inventionconcepts" */ '@/legacy_modules/inventionConcept/views/InventionConcepts.vue')
  },
  {
    path: '/project/:project_id/inventionconcept/:ic_id',
    name: 'project-inventionconcept-details',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "project-inventionconcepts" */ '@/legacy_modules/inventionConcept/views/InventionConceptDetails.vue')
  }
]
