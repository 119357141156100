<template lang="pug">
  GenericDialog(
    title="Create new Invention Canvas"
    :on-save="createWorkbook"
    :can-save="canSave"
    width="400px"
  )
    template(v-slot:activator="{ on }")
      slot(v-bind:on="on")
    template(v-slot:content="")
      v-row
        v-col
          v-text-field(
            v-model="v$.iwbTitle.$model"
            :error-messages="genericValidationFunction('Title', v$.iwbTitle.$model)"
            label="Title"
          )
</template>

<script>

import { maxLength, required } from 'vuelidate/lib/validators'
import GenericDialog from '@/components/common/GenericDialog'
import { genericValidationFunction } from '@/utils/validations'
import { createInventionWorkbook } from '@/api/inventionWorkbooks'
import { storeToRefs } from 'pinia'
import { projectsStore } from '@/modules/projects/store'
import { useCanvasStore } from '@/modules/canvas/store'
import { useVuelidate } from '@vuelidate/core'
import { computed, ref } from 'vue'
import store from '@/store'
import { useRouter } from 'vue-router/composables'

export default {
  name: 'CreateWorkbookDialog',
  components: { GenericDialog },
  setup() {
    const iwbTitle = ref('')
    const { currentProjectId } = storeToRefs(projectsStore())
    const router = useRouter()

    const rules = {
      iwbTitle: { required, maxLength: maxLength(256) }
    }

    const v$ = useVuelidate(rules, { iwbTitle })

    const canSave = computed(() => {
      return v$.value.iwbTitle.$dirty && !v$.value.iwbTitle.$invalid
    })

    const createWorkbook = async() => {
      try {
        const result = await createInventionWorkbook({
          title: iwbTitle.value,
          project: currentProjectId.value
        })
        await useCanvasStore().fetchCanvassesForProject()
        await router.push({
          name: 'project-inventionworkbook-details',
          params: { workbook_uuid: result.data.uuid }
        })
      } catch (e) {
        store.commit('snackbar/show', {
          color: 'error',
          message: 'There was an error while trying to create the Canvas.'
        })
      }
    }

    return {
      v$,
      canSave,
      iwbTitle,
      createWorkbook,
      genericValidationFunction
    }
  }
}
</script>
