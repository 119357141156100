import http from '../plugins/http';
const ENDPOINT = 'users';
export function getUsers() {
    return http.get(ENDPOINT);
}
export function changePassword(data) {
    return http.post(`${ENDPOINT}/change_password`, data);
}
export function getUsersProfile() {
    // @ts-ignore
    return http.get(`${ENDPOINT}/profile`, { meta: { bypassLock: true } });
}
export function createUser(data) {
    return http.post(ENDPOINT, data);
}
export function updateUser(userId, data) {
    return http.patch(`${ENDPOINT}/${userId}/`, data);
}
export function destroyUser(userId) {
    return http.delete(`${ENDPOINT}/${userId}/`);
}
