export const FILE_TYPES = {
    XLSX: {
        format: 'xlsx',
        dataType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    DOCX: {
        format: 'docx',
        dataType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
};
export function makeExportHeaders(exportDataType) {
    return {
        headers: {
            Accept: exportDataType
        },
        responseType: 'blob'
    };
}
