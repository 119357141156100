export const isImageDrop = (event) => {
    const dataTransfer = event.dataTransfer;
    if (dataTransfer) {
        if (dataTransfer.files?.length)
            return true;
        const htmlData = event.dataTransfer.getData('text/html');
        if (!htmlData)
            return false;
        const urlMatch = /src\s*=\s*"(.+?)"/.exec(htmlData);
        const url = urlMatch ? urlMatch[1] : null;
        if (url)
            return true;
    }
    return false;
};
export const getExternalImageSource = (event) => {
    const htmlData = event.dataTransfer.getData('text/html');
    if (!htmlData)
        return null;
    const urlMatch = /src\s*=\s*"(.+?)"/.exec(htmlData);
    const source = urlMatch ? urlMatch[1] : null;
    return source;
};
