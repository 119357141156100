import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { STANDARDS_FILTERS } from '../../triggers/constants';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, LegendScrollComponent, DataZoomComponent, GridComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { standardsDocsStatistics } from '../../../api/triggers';
import { useStandardsTriggerListStore } from './standardsTriggerList';
import debounce from 'lodash/debounce';
import vtf from '@/plugins/vuetify';
import sortBy from 'lodash/sortBy';
import { useTriggersListStore } from '../../triggers/store/triggersList';
import { projectsStore } from '../../projects/store';
export const useStandardsStatisticsStore = defineStore('standardsStatistics', () => {
    const standardsTriggersStore = useStandardsTriggerListStore();
    const triggersStore = useTriggersListStore();
    const apiParameters = computed(() => projectsStore().currentProject ? triggersStore._apiParameters : standardsTriggersStore._apiParameters);
    const standardsChartView = ref(false);
    const levelOneDimension = ref('Work Item');
    const levelTwoDimension = ref('Source');
    const totalDocs = ref(0);
    const standardsStatistics = ref(null);
    const subcategories = ref([]);
    const loadingChart = ref(false);
    const levelOneOptions = Object.freeze(Object.keys(STANDARDS_FILTERS).map((filter) => filter.split('_').join(' ')));
    const chartThemeColor = computed(() => (vtf.framework.theme.isDark ? 'white' : 'black'));
    const chartTableTitle = ref('');
    const standardsSearchParams = ref({
        offset: 0,
        limit: 10,
        sort_field: '',
        sort_order: 'desc'
    });
    const zoomEnd = ref(0);
    echarts.use([
        BarChart,
        TitleComponent,
        TooltipComponent,
        LegendComponent,
        LegendScrollComponent,
        DataZoomComponent,
        GridComponent,
        CanvasRenderer
    ]);
    // @ts-ignore
    const maxData = computed(() => (100 * (Math.min(standardsStatistics.value?.length, 10) / standardsStatistics.value?.length) + zoomEnd.value));
    const getStandardsStatistics = debounce(async () => {
        if (!standardsChartView.value)
            return;
        try {
            loadingChart.value = true;
            const res = (await standardsDocsStatistics(apiParameters.value)).data;
            if (levelTwoDimension.value) {
                const levelTwoDimensionValue = levelTwoDimension.value.split(' ').join('_');
                subcategories.value = [];
                for (const item of res.buckets) {
                    // @ts-ignore
                    const itemSubcategories = item[STANDARDS_FILTERS[levelTwoDimensionValue]].buckets.map((bucket) => bucket.key);
                    for (const subcategory of itemSubcategories) {
                        if (!subcategories.value.includes(subcategory)) {
                            subcategories.value.push(subcategory);
                        }
                    }
                }
                standardsStatistics.value = orderAndAddSubcategories(res.buckets, subcategories.value);
            }
            else {
                standardsStatistics.value = res.buckets;
            }
            totalDocs.value = res.num_total_docs;
        }
        finally {
            loadingChart.value = false;
        }
    }, 500);
    const chartOptions = computed(() => {
        if (!standardsStatistics.value)
            return {};
        const categories = standardsStatistics.value.map((item) => item.key);
        const legendData = [];
        const seriesData = [];
        if (!levelTwoDimension.value) {
            legendData.push('Total');
            seriesData.push({
                name: 'Total',
                type: 'bar',
                label: {
                    show: true
                },
                data: standardsStatistics.value.map((item) => item.doc_count)
            });
        }
        else {
            let subcategoryCounter = 0;
            for (const subcategory of subcategories.value) {
                legendData.push(subcategory);
                seriesData.push({
                    name: subcategory,
                    type: 'bar',
                    stack: 'stack',
                    label: {
                        show: true
                    },
                    data: standardsStatistics.value.map((item) => {
                        // @ts-ignore
                        const subcategoryData = item[STANDARDS_FILTERS[levelTwoDimension.value.split(' ').join('_')]];
                        return subcategoryData?.buckets?.[subcategoryCounter]?.doc_count ?? 0;
                    })
                });
                subcategoryCounter++;
            }
        }
        return {
            title: {
                text: levelTwoDimension.value ? `${levelOneDimension.value} by ${levelTwoDimension.value}` : levelOneDimension.value,
                textStyle: {
                    color: chartThemeColor.value,
                    fontSize: 24
                },
                left: 'center',
                top: 10,
                padding: [0, 0, 20, 0]
            },
            dataZoom: [
                {
                    type: 'inside',
                    yAxisIndex: 0,
                    start: 0,
                    end: maxData.value,
                    zoomOnMouseWheel: 'ctrl'
                },
                {
                    type: 'slider',
                    yAxisIndex: 0,
                    brushSelect: false,
                    width: 10,
                    left: '85%',
                    start: 0,
                    end: maxData.value,
                    minData: 1,
                    maxData: maxData.value,
                    handleSize: 0,
                    backgroundColor: '#efefef',
                    fillerColor: '#888888',
                    zoomLock: true,
                    textStyle: {
                        color: chartThemeColor.value
                    }
                }
            ],
            grid: {
                left: '2%',
                right: '20%',
                bottom: '3%',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: function (params) {
                    let tooltipContent = '';
                    params.forEach(function (item) {
                        if (item.value > 0) {
                            const colorCircle = `<span style="display: inline-block; width: 10px; height: 10px; background-color: ${item.color}; border-radius: 50%; margin-right: 6px;"></span>`;
                            tooltipContent += `${colorCircle}${item.seriesName}: ${item.value}<br>`;
                        }
                    });
                    if (params.length > 0) {
                        tooltipContent = `<strong>${params[0].name}</strong><br>${tooltipContent}`;
                    }
                    return tooltipContent;
                }
            },
            legend: {
                data: legendData,
                type: 'scroll',
                orient: 'vertical',
                padding: [40, 0, 40, 0],
                left: '87%',
                top: 50,
                textStyle: {
                    color: chartThemeColor.value
                }
            },
            xAxis: {
                type: 'value',
                name: 'Number of documents',
                nameLocation: 'middle',
                nameTextStyle: {
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: [10, 0, 0, 0],
                    color: chartThemeColor.value
                },
                axisLabel: {
                    color: chartThemeColor.value
                }
            },
            yAxis: {
                type: 'category',
                data: categories,
                axisLabel: {
                    color: chartThemeColor.value
                }
            },
            series: seriesData
        };
    });
    const levelTwoOptions = computed(() => {
        return Object.keys(STANDARDS_FILTERS).filter((filter) => filter !== levelOneDimension.value).map((filter) => filter.split('_').join(' '));
    });
    watch(standardsChartView, (newValue) => {
        if (newValue) {
            getStandardsStatistics();
        }
    });
    watch(levelOneDimension, (newValue) => {
        if (newValue === levelTwoDimension.value) {
            levelTwoDimension.value = '';
        }
        getStandardsStatistics();
    });
    watch(levelTwoDimension, (newValue) => {
        if (!newValue) {
            standardsStatistics.value = sortBy(standardsStatistics.value, 'doc_count').reverse();
            return;
        }
        getStandardsStatistics();
    });
    function orderAndAddSubcategories(data, subcategories) {
        for (const obj of data) {
            const newBuckets = [];
            const levelTwoDimensionValue = levelTwoDimension.value.split(' ').join('_');
            let totalDocsPerLevelOneDimension = 0;
            for (const subcategory of subcategories) {
                // @ts-ignore
                const matchingBucket = obj[STANDARDS_FILTERS[levelTwoDimensionValue]]?.buckets?.find((bucket) => bucket.key === subcategory);
                if (matchingBucket) {
                    newBuckets.push(matchingBucket);
                    totalDocsPerLevelOneDimension += matchingBucket.doc_count;
                }
                else {
                    newBuckets.push({
                        key: subcategory,
                        doc_count: 0
                    });
                }
            }
            // @ts-ignore
            obj.total_docs = totalDocsPerLevelOneDimension;
            // @ts-ignore
            obj[STANDARDS_FILTERS[levelTwoDimensionValue]].buckets = newBuckets;
        }
        // @ts-ignore
        return data.sort((a, b) => b.total_docs - a.total_docs);
    }
    return {
        standardsChartView,
        levelOneDimension,
        levelTwoDimension,
        totalDocs,
        standardsStatistics,
        chartOptions,
        levelOneOptions,
        levelTwoOptions,
        loadingChart,
        getStandardsStatistics,
        standardsSearchParams,
        chartTableTitle,
        zoomEnd,
        maxData
    };
});
