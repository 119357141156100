import http from '../plugins/http';
const ENDPOINT = 'triggers';
// triggers/feature-mining/scatter/
export const getFeatureMiningScatter = (params) => {
    return http({
        url: `${ENDPOINT}/feature-mining/scatter/`,
        method: 'get',
        params
    });
};
// triggers/feature-mining/heatmap/
export const getFeatureMiningHeatmap = (params) => {
    return http({
        url: `${ENDPOINT}/feature-mining/heatmap/`,
        method: 'get',
        params
    });
};
// triggers/feature-mining/heatmap-annotations/
export const getFeatureMiningHeatmapAnnotations = (params) => {
    return http({
        url: `${ENDPOINT}/feature-mining/heatmap-annotations/`,
        method: 'get',
        params
    });
};
export function getConnectedAspects(aspectName, query) {
    return http.get('triggers/connected-aspects/', { params: { aspect: aspectName, query } });
}
export function getConvergingAspects(aspectName, query) {
    return http.get('triggers/converging-aspects/', { params: { aspect: aspectName, query } });
}
export const getAspectDocuments = (queryParams) => {
    return http.get('triggers/feature-mining/aspects-documents/', { params: queryParams });
};
export const getAspectDescription = (aspectName) => {
    return http.get('aspects/description/', { params: { aspect_name: aspectName } });
};
export function getAspectsTiles(params) {
    return http.get(`${ENDPOINT}/feature-mining/aspects-tiles/`, { params });
}
export const autocompleteAspects = (query) => http.get('aspects/search/', { params: { query } });
// triggers/competitor-patents/
export const getCompetitorsPatents = (params) => {
    return http({
        url: `${ENDPOINT}/competitor-patents/`,
        method: 'get',
        params
    });
};
