import template from 'lodash/template';
import { ref, watch } from 'vue';
import { getUserPreferences, setUserPreferences } from '../api/preferences';
import { authStore } from '../store/modules/auth';
/**
 * Represent all the queryString possible to specify. Each string should be a valid Lodash Template
 * String (https://lodash.com/docs#template)
 * @type {Object<string>}
 */
const queryStrings = {
    PROJECT_LIST_FILTER: 'projects.list_filters',
    DEFAULT_FEED: 'projects.<%=projectId%>.default_feed',
    EVALUATION_CRITERIA_PANEL: 'projects.<%=projectId%>.evaluation_criteria_panel',
    TEAM_PANEL_EXPANDED: 'projects.<%=projectId%>.team_panel_expanded',
    LAST_SEARCH_PARAMS: 'projects.<%=projectId%>.collect_triggers.last_search_params',
    LAST_SEARCH_PARAMS_PROBLEM_MINING: 'projects.<%=projectId%>.problem_mining.<%=feedId%>.last_search_params',
    COLLECT_TRIGGERS_FILTERS: 'projects.<%=projectId%>.collect_triggers.filters',
    LAST_OPENED_SCOPING_FEED: 'projects.<%=projectId%>.last_opened_scoping_feed',
    SCOPING_FEED_FILTERS: 'projects.<%=projectId%>.scoping_feeds.<%=feedId%>.filters',
    SCOPING_FEED_MORE_SETTINGS: 'projects.<%=projectId%>.scoping_feeds.<%=feedId%>.show_more_settings',
    SCOPING_FEED_ACTIVE_FILTER: 'projects.<%=projectId%>.scoping_feeds.<%=feedId%>.<%=filterType%>.active_filter',
    MAIN_MENU_COLLAPSED: 'layout.sidebar.main_menu_collapsed',
    INVENTION_CONCEPTS_SPLIT_RATIO: 'layout.invention_concept_list.split_ratio',
    INVENTION_WORKBOOKS_LAST_USED_BLOCK_TYPE: 'projects.invention_workbooks.preferences.block_type',
    INVENTION_WORKBOOKS_POPOVER_SETTINGS: 'projects.<%=projectId%>.invention_workbooks.popover.settings',
    INVENTION_WORKBOOKS_LAST_VIEWED_BLOCK: 'projects.invention_workbooks.<%=workbookId%>.last_viewed_block',
    INVENTION_WORKBOOKS_CANVAS_LAST_USED_TEXT_NODE_COLOR: 'projects.invention_workbooks.blocks.<%=blockId%>.text_node_color',
    INVENTION_WORKBOOKS_BLOCK_FOLDED: 'projects.invention_workbooks.blocks.<%=blockId%>.folded',
    OPPORTUNITY_ATLAS_ADDITIONAL_ASPECTS_COMBINATION_MODE: 'projects.opportunity_atlas.focus.aspects.combination_mode',
    OPPORTUNITY_ATLAS_HEATMAP_HIDE_LEGEND: 'projects.opportunity_atlas.heatmap.hide_legend',
    DATE_RANGE_PICKER_LAST_VALUES: 'fields.date_range_picker.<%=key%>.last_values',
    DISMISSED_HELP_BOXES: 'helpbox.<%=pageId%>.dismissed',
    KPIS_FILTERS: 'kpis.filters',
    CURRENT_THEME: 'profile.current_theme',
    STANDARDS_EXPLORE_FILTER: 'explore.standards_filters',
    PROJECT_TRIGGERS_FILTERS: 'projects.<%=projectId%>.triggers_filters',
    PROJECT_SAVED_VIEWS: 'projects.<%=projectId%>.saved_views',
    PROJECT_USED_TRIGGERS: 'projects.<%=projectId%>.used_triggers'
};
/**
 * Compiled a query from a queryString and the corresponding variables.
 * @param {string} queryString: The query string that should be a key of the queryStrings variable
 * @param {Object} variablesDict: A dict containing all the keys to compile the queryString
 * @return {string}: The query compiled
 * @example:
 * const queryStrings = {
 *   DEFAULT_FEED: 'projects.<%=projectId%>.default_feed'
 * }
 *
 * const res = getQuery("DEFAULT_FEED", {projectId: 5})
 * console.log(res) // projects.5.default_feed
 */
function getQuery(queryString, variablesDict) {
    if (!queryString) {
        throw new Error('Please specified a preferences string that fit the \'queryStrings\' object');
    }
    if (!Object.hasOwn(queryStrings, queryString)) {
        throw new Error(`Please specified a valid preferences string, available:
    ${Object.keys(queryStrings)}`);
    }
    const qString = queryStrings[queryString];
    const compiled = template(qString);
    return compiled(variablesDict);
}
/**
 * Transform a query to a dictionary in order to send it as a body to patch the preferences
 * @param {string} query: The query compiled
 * @param {*} value: The new value of the preference that should be set for the query
 * @return {Object}: A dictionary containing the path of the query with the value specified
 */
function transformQueryToDict(query, value) {
    const result = {};
    let current = result;
    const splitted = query.split('.');
    splitted.forEach((part, index) => {
        if (index === splitted.length - 1) {
            current[part] = value;
            return;
        }
        current[part] = {};
        current = current[part];
    });
    return result;
}
/**
 * Find the preference from the store from a query
 * @param {string} query: The query compiled
 * @return {Promise<*>}: The value of the preference
 */
function findStorePreferenceFromQuery(query) {
    let prefs = authStore().preferences;
    const splitted = query.split('.');
    for (const part of splitted) {
        if (!Object.hasOwn(prefs, part))
            return;
        prefs = prefs[part];
    }
    return prefs;
}
/**
 * Patch the preference from a queryString to a new value. It patch both on the backend and in the
 * store
 * @param {string} queryString: The query string that should be a key of the queryStrings variable
 * @param {Object} variablesDict: A dict containing all the keys to compile the queryString
 * @param {*} value: The new preference value
 * @return {Promise<AxiosResponse<undefined>>}: The Axios promise
 * @example:
 * const queryStrings = {
 *   DEFAULT_FEED: 'projects.<%=projectId%>.default_feed'
 * }
 *
 * // This will set projects.5.default_feed=10
 * setPreference("DEFAULT_FEED", {projectId: 5}, 10)
 */
export async function setPreference(queryString, value, variablesDict = {}) {
    const query = getQuery(queryString, variablesDict);
    const body = transformQueryToDict(query, value);
    return setUserPreferences(body).then(response => {
        authStore().preferences = response.data;
    });
}
/**
 * Get a preference from a queryString
 * @param {string} queryString: The query string that should be a key of the queryStrings variable
 * @param {Object} variablesDict: A dict containing all the keys to compile the queryString
 * @return {Promise<any>}: The value of the preference
 * @example:
 * const queryStrings = {
 *   DEFAULT_FEED: 'projects.<%=projectId%>.default_feed'
 * }
 * // If projects.5.default_feed = 10
 *
 * const res = getPreference("DEFAULT_FEED", {projectId: 5})
 * console.log(res) // 10
 */
export function getPreference(queryString, variablesDict = {}) {
    const query = getQuery(queryString, variablesDict);
    return findStorePreferenceFromQuery(query);
}
/**
 * Get a specific preference from the Backend.
 * WARNING: This function doesn't get it from the store and so is unoptimized. It's only used to get
 * large preference from the store that are not get with all the preferences
 * !!! Not use for now !!!
 * @param {string} queryString: The query string that should be a key of the queryStrings variable
 * @param {Object} variablesDict: A dict containing all the keys to compile the queryString
 * @return {AxiosPromise<Object>}: The Axios promise that contain the preference value
 *
 * @example:
 * const queryStrings = {
 *   DEFAULT_FEED: 'projects.<%=projectId%>.default_feed'
 * }
 * // If projects.5.default_feed = 10
 *
 * const res = getSpecificPreference("DEFAULT_FEED", {projectId: 5})
 * console.log(res) // 10
 */
export function getSpecificPreference(queryString, variablesDict = {}) {
    const query = getQuery(queryString, variablesDict);
    return getUserPreferences(query);
}
export function usePreferenceSyncedRef(defaultValue, queryString, variablesDict = {}) {
    const result = ref(getPreference(queryString, variablesDict) ?? defaultValue);
    watch(result, () => setPreference(queryString, result.value, variablesDict));
    return result;
}
