export const DefaultFlagsValues = {
    STANDARDS_ACCESS: false,
    INVENTION_TRACKER_ENABLED: false,
    EVO_ACCESS_ENABLED: true,
    LEGACY_ACCESS_ENABLED: true,
    AI_CONCEPT_GENERATION_ENABLED: true,
    USERS_DISCOVERABILITY: 'all',
    SCOPING_TOOL_ENABLED: true,
    OPPORTUNITY_ATLAS_ENABLED: false
};
