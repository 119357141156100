<template lang="pug">
  GenericDialog(
    :title="dialogTitle"
    width="920"
    is-persistent
    v-model="show"
    :on-open="initTriggerData"
    @cancel="cancel"
  )
    template(v-slot:activator="{ on }")
      slot(v-bind:on="on")
    template(v-slot:content="")
      v-card-text(v-if="!addingManually" class="px-0 pt-0")
        div {{ dateFormatted }}
        div
          a(:href="url" target="_blank") {{ title }}

      v-container(class="pa-0")
        v-row(v-if="addingManually")
          v-col(cols="12" class="pb-0")
            v-text-field(
              label="Title"
              class="required"
              hide-details="auto"
              v-model="title"
              @blur="$v.title.$touch"
              :error-messages="genericValidationFunction('Title', $v.title)"
              :messages="titleMessages"
            )

          v-col(cols="9" class="pb-0")
            v-text-field(
              label="URL"
              class="required"
              hide-details="auto"
              v-model="url"
              @blur="$v.url.$touch"
              :error-messages="genericValidationFunction('URL', $v.url)"
              :messages="urlMessages"
            )

          v-col(cols="3" class="pb-0")
            v-menu(
              v-model="showDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            )
              template(v-slot:activator="{ on }")
                v-text-field(
                  v-model="dateFormatted"
                  label="Trigger date"
                  class="required"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  readonly
                  @blur="$v.date.$touch"
                  :error-messages="genericValidationFunction('Trigger date', $v.date)"
                )
              v-date-picker(
                v-model="date"
                no-title
                @input="showDatePicker = false"
                type="month"
                scrollable
              )
          v-col(cols=12)
            v-textarea(
              auto-grow
              rows="1"
              dense
              label="Content"
              v-model="$v.content.$model"
              :error-messages="genericValidationFunction('Content', $v.content)"
            )
        v-row(no-gutters class="pa-0 ma-0")
          v-col(class="text-right")
            v-tooltip(top)
              template(v-slot:activator="{ on, attrs }")
                v-icon(v-on="on") mdi-information-outline
              v-container(class="px-2 py-5 ma-0")
                v-row(class="pb-1")
                  span
                    v-chip(:color="currentProjectColor" small) Project
                    // Small trick to not have problem with the equal sign at the beggining of the
                    // sentence
                    span(class="pl-1") = Current project
                v-row(class="pb-1")
                  v-chip(disabled small) Project
                    // Small trick to not have problem with the equal sign at the beggining of the
                    // sentence
                  span(class="pl-1") = Trigger is already tracked in this project
                v-row(class="pb-1")
                  v-chip(small) Project
                    // Small trick to not have problem with the equal sign at the beggining of the
                    // sentence
                  span(class="pl-1") = Trigger will be tracked in this project once saved
                v-row(class="pt-1")
                  span Select at least one project that doesn't already track this trigger
        v-row
          v-col(cols="12" class="pt-0 mt-0")
            v-select(
              v-model="selectedProjects"
              :items="projectsTrackedTriggers"
              label="Projects"
              class="required"
              multiple
              return-object
              @blur="$v.selectedProjects.$touch"
              :error-messages="selectedProjectsErrors"
              item-value="id"
              :item-disabled="disabledProjects"
              item-text="name")
              template(v-slot:selection="{ item, index }")
                v-chip(
                  small
                  close
                  @click:close="selectedProjects = selectedProjects.filter(p => p.uuid !== item.uuid)"
                  :disabled="disabledProjects(item)"
                  :color="item.uuid === currentProjectUuid ? currentProjectColor : ''")
                  span {{ item.name }}

        template(v-if="canAddHunchesAndEFs")
          v-row(dense)
            v-col(class="subtitle-1") Essential features
            v-col(class="shrink")
              v-btn(@click="addEssentialFeature" small tile elevation="0") Add
          v-divider
          template(v-for="(v, index) in $v.essentialFeatures.$each.$iter")
            v-row(dense class="mt-3")
              v-col(class="shrink")
                v-select(
                  :items="essentialFeatureTypes"
                  v-model="v.type.$model"
                  placeholder="Type"
                  class="ef-type-select"
                  :menu-props="{'nudge-left': 15}"
                )
                  template(v-slot:item="{ item }")
                    v-chip(
                      small
                      label
                      class="ef-type-select-item ef-type-chip justify-center"
                      dark
                      :color="ESSENTIAL_FEATURE_COLORS[item.value]"
                    ) {{ item.text }}
                  template(v-slot:selection="{ item, index }")
                    v-chip(
                      small
                      label
                      class="ef-type-chip justify-center"
                      dark
                      :color="ESSENTIAL_FEATURE_COLORS[item.value]"
                    ) {{ item.text }}
              v-col(class="pt-0")
                v-textarea(
                  class="required"
                  auto-grow
                  dense
                  rows="1"
                  label="Feature"
                  v-model="v.content.$model"
                  :messages="v.content.$model ? '': 'This feature is empty and will not be saved'"
                )
              v-col(class="pt-0")
                v-textarea(
                  auto-grow
                  rows="1"
                  dense
                  label="Initial Thought"
                  v-model="v.hunch.$model"
                  :error-messages="genericValidationFunction('Initial Thought', v.hunch)"
                )
              v-col(class="shrink")
                v-btn(
                  icon
                  small
                  color="accent lighten-2"
                  @click="removeEssentialFeature(index)"
                )
                  v-icon mdi-delete-outline

            v-divider
          v-row
            v-col
              div(
                v-for="(error, index) in essentialFeaturesErrors"
                :key="'essentialFeaturesErrors-' + index"
                class="red--text") {{error}}

          v-row
            v-col
              div(class="text--secondary font-italic") Any essential feature added here are going to be added only in the tracked trigger of the current project.
        v-row(v-else class="text-center" dense)
          v-col
            div(class="text--secondary font-italic") You need to add this trigger to the current project to be able to add essential features.

    template(v-slot:actions="")
        v-spacer
        v-chip(class="mr-8") Sprint {{ sprintNumber }}
        v-btn(
          @click="save"
          color="iprovaPrimary"
          :dark="!disableSaveButton"
          :disabled="disableSaveButton"
          tile
          elevation="0"
        ) Save
</template>

<script>
import dayjs from 'dayjs'
import { maxLength, required, url } from 'vuelidate/lib/validators'
import { createTrackedTrigger } from '@/api/trackedTriggers'
import { createEssentialFeature } from '@/api/essentialFeatures'
import { getProjectsTrackedTriggers } from '@/api/projects'
import {
  dateToIsoDate,
  DocumentType,
  ESSENTIAL_FEATURE_COLORS,
  ESSENTIAL_FEATURE_TYPES
} from '@/utils/constants'
import { genericValidationFunction } from '@/utils/validations'
import { getDocs } from '@/api/triggers'
import GenericDialog from '@/components/common/GenericDialog'
import { authStore } from '@/store/modules/auth'
import cloneDeep from 'lodash/cloneDeep'
import { projectsStore } from '@/modules/projects/store'

const extractMessages = data => Array.isArray(data) ? data : Object.values(data)

// Validation function for projects field
const mustProjectsBeEnabled = (value, vm) => {
  return Boolean(value.filter(i => !vm.disabledProjects(i)).length)
}

export default {
  components: { GenericDialog },
  props: {
    headerTitle: {
      type: String,
      default: ''
    },
    titleProp: {
      type: String,
      default: ''
    },
    urlProp: {
      type: String,
      default: ''
    },
    dateProp: {
      type: String,
      default: ''
    },
    websiteProp: {
      type: String,
      default: ''
    },
    sourceProp: {
      type: String,
      default: ''
    },
    contentProp: {
      type: String,
      default: ''
    },
    typeProp: {
      type: String,
      default: ''
    },
    essentialFeaturesProp: {
      type: Array,
      default: () => [{ content: '', type: 'ABILITY' }]
    },
    value: {
      type: Boolean,
      default: false
    },
    feedId: {
      type: Number,
      default: null
    },
    currentProjectToUse: {
      type: Object,
      default: undefined
    },
    addingManuallyProp: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentProjectColor: 'iprovaPrimary',
      essentialFeatureType: 'ABILITY',
      essentialFeatureTypes: [],
      selectedProjects: [],
      show: Boolean(this.value),
      sprint_id: null,
      title: '',
      url: '',
      date: dateToIsoDate(new Date()),
      website: '',
      content: '',
      type: '',
      essentialFeatures: cloneDeep(this.essentialFeaturesProp),
      showDatePicker: false,
      projectsTrackedTriggers: []
    }
  },

  validations: {
    title: { required, maxLength: maxLength(256) },
    essentialFeatures: {
      maxLength: maxLength(50),
      $each: {
        content: {},
        hunch: { maxLength: maxLength(50) },
        type: {}
      }
    },
    url: { required, url, maxLength: maxLength(2000) },
    date: { required },
    selectedProjects: { required, mustProjectsBeEnabled },
    content: {}
  },

  computed: {
    canAddHunchesAndEFs() {
      const project = this.selectedProjects.find(i => i.uuid === this.currentProjectUuid)
      if (project) {
        return !this.disabledProjects(project)
      }
      return false
    },
    currentProject() {
      return this.currentProjectToUse || projectsStore().currentProject
    },
    currentProjectUuid() {
      return this.currentProject?.uuid
    },
    addingManually() {
      return this.addingManuallyProp || !this.titleProp || !this.urlProp
    },

    dialogTitle() {
      if (this.headerTitle) return this.headerTitle
      return this.addingManually
        ? 'Manually add a Trigger to the Tracker'
        : 'Track this Trigger'
    },
    disableSaveButton() {
      return this.$v.$invalid
    },

    selectedProjectsErrors() {
      const errors = []
      // This is a small hack to trigger the reactivity
      if (!this.$v.selectedProjects.$invalid) return errors
      if (!this.$v.selectedProjects.$dirty) return errors
      if (!this.$v.mustProjectsBeEnabled) errors.push('Trigger is already tracked in selected project(s).')
      return errors
    },

    titleMessages() {
      const projectNames = this.selectedProjects
        .filter(p => p.tracked_triggers.some(t => t.title === this.$v.title.$model))
        .map(p => p.name)
      if (projectNames.length) {
        return [`Trigger with this title already exists in projects: ${projectNames.join(', ')}.`]
      }
      return []
    },

    urlMessages() {
      const projectNames = this.selectedProjects
        .filter(p => p.tracked_triggers.some(t => t.url === this.$v.url.$model))
        .map(p => p.name)
      if (projectNames.length) {
        return [`Trigger with this URL already exists in projects: ${projectNames.join(', ')}.`]
      }
      return []
    },

    dateFormatted() {
      if (!this.date) return null

      return dayjs(this.date).format('LL')
    },

    sprintNumber() {
      return this.currentProject?.current_sprint?.number || '0'
    },

    essentialFeaturesErrors() {
      const errors = []
      if (!this.$v.essentialFeatures.$dirty) return []
      if (!this.$v.essentialFeatures.maxLength) {
        errors.push(`There cannot be more than ${this.$v.essentialFeatures.$params.maxLength.max} essential features.`)
      }
      return errors
    }
  },

  watch: {
    value() {
      this.show = Boolean(this.value)
    },
    show() {
      this.$emit('input', this.show)
      if (this.show) {
        this.getProjectsTrackedTriggers()
      }
    }
  },

  async created() {
    this.ESSENTIAL_FEATURE_COLORS = ESSENTIAL_FEATURE_COLORS
    this.ESSENTIAL_FEATURE_TYPES = ESSENTIAL_FEATURE_TYPES
    for (const [value, text] of Object.entries(ESSENTIAL_FEATURE_TYPES)) {
      this.essentialFeatureTypes.push({ text, value })
    }
    this.initSprint()
  },

  methods: {
    genericValidationFunction,
    addEssentialFeature() {
      this.essentialFeatures.push({ content: '', type: this.essentialFeatureType })
    },
    removeEssentialFeature(index) {
      this.essentialFeatures.splice(index, 1)
    },
    async getProjectsTrackedTriggers() {
      const params = {
        archived: false,
        completed: false
      }
      this.projectsTrackedTriggers = (await getProjectsTrackedTriggers(params)).data
      // Check if the current project is tracked
      const currentProjectTracked = this.projectsTrackedTriggers.find(i => i.uuid === this.currentProjectUuid)
      // Check if the current project is already in the input
      const currentProjectAlreadyInInput = this.selectedProjects.some(i => i.uuid === this.currentProjectUuid)
      for (const project of this.projectsTrackedTriggers) {
        // Check if this project is already in the input
        const projectAlreadyInInput = this.selectedProjects.some(i => i.uuid === project.uuid)
        if (this.disabledProjects(project) && !projectAlreadyInInput) {
          // If the project is disable but still not in the input, add it in the input
          this.selectedProjects.push(project)
        }
      }
      // If the current project is tracked but not in the input and not disable,
      // we add it in the input
      if (
        currentProjectTracked &&
        !currentProjectAlreadyInInput &&
        !this.disabledProjects(currentProjectTracked)) {
        this.selectedProjects.push(currentProjectTracked)
      }
    },
    // trigger must have unique title and url in the given project
    disabledProjects(project) {
      return project.tracked_triggers.some(i => i.title === this.title || i.url === this.url)
    },
    async saveEssentialFeature(essentialFeature, trackedTriggerId) {
      const data = {
        ...essentialFeature,
        tracked_trigger: trackedTriggerId
      }
      return createEssentialFeature(data)
    },
    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const quotedSavedProjectNames = []
      const warningMessages = []
      let currentProjectNewTrackedTrigger

      // If the project is disabled, we don't send it
      for (const project of this.selectedProjects.filter(p => !this.disabledProjects(p))) {
        try {
          if (this.type !== DocumentType.PATENT) this.type = DocumentType.JOURNAL
          const res = (await createTrackedTrigger({
            sprint_id: this.sprint_id,
            title: this.title,
            url: this.url,
            project_id: project.uuid,
            trigger_date: dateToIsoDate(new Date(this.date)),
            user_id: authStore().profile.user_id,
            feed_id: this.feedId,
            website: this.website,
            content: this.content,
            doc_type: this.type,
            essential_features: this.essentialFeatures.filter(ef => ef.content),
            ...(this.sourceProp && { source: this.sourceProp })
          })).data
          if (this.currentProjectUuid === project.uuid) currentProjectNewTrackedTrigger = res
          quotedSavedProjectNames.push(`"${project.name}"`)
        } catch (e) {
          if (e.response?.status === 400) {
            warningMessages.push(...extractMessages(e.response.data))
          } else {
            this.$store.commit('snackbar/show', {
              color: 'error',
              message: 'Failed to save. Please contact support.'
            })
            throw e
          }
        }
      }

      if (quotedSavedProjectNames.length) {
        this.$store.commit('snackbar/show', {
          color: 'success',
          message: `Trigger successfully added to tracker for projects ${quotedSavedProjectNames.join(', ')}.`
        })
      }
      if (warningMessages.length) {
        this.$store.commit('snackbar/show', {
          color: 'warning',
          message: warningMessages.join('</br>'),
          timeout: 15000
        })
      }
      if (currentProjectNewTrackedTrigger) {
        this.$emit('created', currentProjectNewTrackedTrigger.id, currentProjectNewTrackedTrigger.virtual_id)
      }
      this.$emit('tracked')
      this.cancel()
    },

    cancel() {
      this.show = false
      // eslint-disable-next-line prefer-destructuring
      this.sprint_id = null
      this.essentialFeatureType = 'ABILITY'
      this.essentialFeatures = [{ content: '', type: 'ABILITY' }]
      this.selectedProjects = []
      this.title = ''
      this.url = ''
      this.date = dateToIsoDate(new Date())
      this.website = ''
      this.content = ''
      this.initSprint()
      this.$v.$reset()
    },

    async initTriggerData() {
      this.title = this.titleProp
      this.url = this.urlProp
      this.dateProp && (this.date = this.dateProp) // if dateProp is missing use current date
      this.website = this.websiteProp
      this.essentialFeatures = cloneDeep(this.essentialFeaturesProp)
      this.content = this.contentProp
      this.type = this.typeProp
      if (!this.addingManually) {
        this.content = this.contentProp || await this.getContentFromIndex(this.urlProp)
      }
    },

    initSprint() {
      if (this.currentProject?.current_sprint) {
        this.sprint_id = this.currentProject.current_sprint.id
      }
    },
    async getContentFromIndex() {
      const params = {
        project_uuid: this.currentProjectUuid,
        query: `url: "${this.url}"`
      }
      try {
        const res = (await getDocs(params)).data
        return res.docs[0]?.summary.join(' ')
      } catch (e) {
        return ''
      }
    }
  }
}
</script>
<style scoped lang="scss">
:deep {
  .ef-type-select-item, .ef-type-chip { min-width: 88px; }
  .ef-type-select {
    padding-top: 0px;
    margin-left: 25px;
    width: 111px;
    .ef-type-chip {
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
    &.v-input--is-dirty input { display: none; }
    .v-input__slot { align-items: start; }
    .v-input__icon--append .v-icon { margin-top: 0px !important; }
    .v-input__slot:before, .v-input__slot:after {border: none;}
    .v-input__append-inner { margin-top: 0px; }
  }
}
</style>
