import dayjs from 'dayjs';
import Vue from 'vue';
export default Vue.extend({
    props: {
        commentedItem: {
            type: Object,
            required: true
        },
        initialData: {
            type: Array,
            default: () => undefined
        }
    },
    data() {
        return {
            loading: false,
            ordering: 'created_at',
            comments: []
        };
    },
    async created() {
        if (this.initialData === undefined) {
            await this.fetchComments();
        }
        else {
            this.comments = this.initialData;
        }
    },
    methods: {
        dayjs,
        async fetchComments() {
            this.loading = true;
            this.comments = (await this.commentsAPIs.fetchCommentsAPI(this.commentedItem.id, { ordering: this.ordering })).data;
            this.loading = false;
        }
    }
});
