<template lang="pug">
  v-app(id="inspire")
    v-main
      v-container(class="fill-height" fluid)
        v-row(align="center" justify="center")
          v-col(cols="12" sm="8", md="4")
            v-card(class="elevation-12 overflow-hidden")
              v-form
                v-toolbar(color="iprovaDark" dark flat)
                  v-toolbar-title Log into Invention Studio
                v-row(
                  v-for="comp in authComponents"
                  :key="comp.type"
                  justify="center"
                  class="ma-0")
                  v-col(v-if="comp.active")
                    component(
                      :is="comp.component"
                      :meta="comp.meta")
    main-snackbar
</template>

<script>
import MainSnackbar from '@/components/MainSnackbar.vue'
import PasswordAuth from '@/modules/auth/views/modules/PasswordAuth.vue'
import ActiveDirectorySSOAuth from '@/modules/auth/views/modules/ActiveDirectorySSOAuth.vue'
import { AUTHENTICATION_TYPES } from '@/utils/constants'
import { authStore } from '@/store/modules/auth'

export default {
  name: 'LoginView',
  components: {
    ActiveDirectorySSOAuth,
    PasswordAuth,
    MainSnackbar
  },
  data() {
    return {
      authComponents: [
        {
          component: PasswordAuth,
          active: false,
          type: AUTHENTICATION_TYPES.PASSWORD
        },
        {
          component: ActiveDirectorySSOAuth,
          active: false,
          type: AUTHENTICATION_TYPES.ACTIVE_DIRECTORY_SSO
        },
        {
          component: ActiveDirectorySSOAuth,
          active: false,
          type: AUTHENTICATION_TYPES.ACTIVE_DIRECTORY_SSO_TRUSTED
        }
      ]
    }
  },
  async created() {
    const auth = authStore()
    if (auth.isAuthenticated) return
    for (const comp of this.authComponents) {
      const route = await auth.getRoute(comp.type)
      if (route) {
        this.$set(comp, 'active', route.display)
        this.$set(comp, 'meta', route.meta)
      }
    }
  }
}
</script>
