import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import EssentialFeatureComponent from '../components/EssentialFeatureComponent';
export default Node.create({
    name: 'essentialfeature',
    group: 'trigger',
    content: 'inline*',
    addAttributes() {
        // Return an object with attribute configuration
        return {
            eftype: {
                default: 'ABILITY'
            }
        };
    },
    parseHTML() {
        return [
            {
                tag: 'essentialfeature'
            }
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['essentialfeature', mergeAttributes(HTMLAttributes), 0];
    },
    addNodeView() {
        return VueNodeViewRenderer(EssentialFeatureComponent);
    },
    addKeyboardShortcuts() {
        return {
            Enter: ({ editor }) => {
                const { commands, state } = editor;
                const efNodePos = state.selection.$anchor.pos - state.selection.$anchor.parentOffset - 1;
                if (efNodePos < 0)
                    return false;
                const efNode = state.doc.nodeAt(efNodePos);
                const triggerNode = state.doc.childBefore(state.selection.$anchor.pos)?.node;
                if (!efNode || efNode.type.name !== 'essentialfeature' || !triggerNode || triggerNode.type.name !== 'trigger') {
                    return false;
                }
                const isAtFirstPosition = triggerNode.firstChild === efNode && state.selection.$anchor.parentOffset === 0;
                if (isAtFirstPosition) {
                    // if we're at the very beginning, just add a new paragraph above the current, allowing to move the node down
                    return commands.insertContentAt(efNodePos - 1, {
                        type: 'paragraph',
                        content: []
                    });
                }
                // on enter, make sure all the essential features are visible
                // @ts-ignore
                triggerNode.attrs.essentialFeaturesCollapsed = false;
                const nextNode = state.doc.nodeAt(efNodePos + efNode.nodeSize);
                // when typing enter at the end of the last EF, add a new empty EF. Type enter again, and this
                // should add a new paragraph after the trigger, and move the cursor there
                const isAtEndOnEmptyEssentialFeature = nextNode?.type?.name !== 'essentialfeature' && efNode.textContent.length === 0;
                if (isAtEndOnEmptyEssentialFeature) {
                    return commands.insertContent({
                        type: 'paragraph',
                        content: []
                    });
                }
                // when typing enter in an empty essential feature, tiptap will try and split the trigger into two.
                // not only is this indesirable in general, it causes the code that syncs tracked triggers and
                // text editor triggers to explode. Returning true prevents the splitCode function to be called.
                return efNode.textContent.length === 0;
            }
        };
    }
});
