import http from '../plugins/http';
const ENDPOINT = 'tracked-triggers';
export function getTrackedTriggers(params) {
    return http.get(`${ENDPOINT}/`, { params });
}
export function getTrackedTrigger(id) {
    return http.get(`${ENDPOINT}/${id}/`);
}
export function createTrackedTrigger(data) {
    return http.post(`${ENDPOINT}/`, data);
}
export function patchTrackedTrigger(id, data) {
    return http.patch(`${ENDPOINT}/${id}/`, data);
}
export function deleteTrackedTrigger(id) {
    return http.delete(`${ENDPOINT}/${id}/`);
}
