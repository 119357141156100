<template lang="pug">
span
  v-card-text
    v-text-field(
      label="Username"
      name="login"
      prepend-icon="mdi-account"
      type="text"
      v-model="username"
      @blur="$v.username.$touch"
      @input="resetSubmitErrorMessage"
      :error-messages="usernameErrors"
    )

    v-text-field(
      id="password"
      label="Password"
      name="password"
      prepend-icon="mdi-lock"
      type="password"
      v-model="password"
      @blur="$v.password.$touch"
      @input="resetSubmitErrorMessage"
      :error-messages="passwordErrors"
    )

  v-card-actions
    v-btn(
      block
      type="submit"
      color="iprovaPrimary"
      :dark="!$v.$invalid"
      @click.prevent="login"
      :disabled="$v.$invalid"
      tile elevation="0"
    ) login
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { authenticate } from '@/api/auth'
import { AUTHENTICATION_TYPES } from '@/utils/constants'
import { authStore } from '@/store/modules/auth'

export default {
  name: 'PasswordAuth',
  data() {
    return {
      username: '',
      password: '',
      submitErrorMessage: null
    }
  },
  validations: {
    username: { required },
    password: { required }
  },
  computed: {
    usernameErrors() {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      if (!this.$v.username.required) errors.push('Username is required.')
      if (this.submitErrorMessage) errors.push('')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      if (!this.$v.password.required) errors.push('Password is required.')
      if (this.submitErrorMessage) errors.push(this.submitErrorMessage)
      return errors
    }
  },
  methods: {
    async login() {
      this.$v.$touch()

      if (this.$v.$invalid) return

      const auth = authStore()
      try {
        await auth.login(
          AUTHENTICATION_TYPES.PASSWORD,
          (route) => authenticate(this.username, this.password, route)
        )
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.submitErrorMessage = e.response.data?.detail ?? 'Login failed.'
        } else if (e.response && e.response.status === 429) this.submitErrorMessage = e.response.data
        else throw e
      }
    },
    resetSubmitErrorMessage() {
      this.submitErrorMessage = null
    }
  }
}
</script>

<style scoped>
  .or {
    text-align: center;
    font-weight: bold;
  }
</style>
