import { computed, ref } from 'vue';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import unionBy from 'lodash/unionBy';
import store from '@/store';
export function makeAutocompleteBase(makeApiCall, filterValues, apiParametersProvider) {
    const lastFetchedForParams = ref(null);
    const lastFetchedText = ref('');
    const isLoading = ref(false);
    const fetchedItems = ref([]);
    const items = computed(() => {
        return unionBy(fetchedItems.value, filterValues().map((v) => ({ key: v, doc_count: 0 })), 'key');
    });
    const getItems = debounce(async (text) => {
        const apiParameters = apiParametersProvider();
        if (text === null || isLoading.value ||
            (lastFetchedText.value === text && isEqual(lastFetchedForParams.value, apiParameters)))
            return;
        lastFetchedText.value = text;
        lastFetchedForParams.value = cloneDeep(apiParameters);
        isLoading.value = true;
        try {
            fetchedItems.value = await makeApiCall(text, apiParameters);
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Error while fetching autocomplete items'
            });
        }
        finally {
            isLoading.value = false;
        }
    }, 500);
    return { getItems, isLoading, items };
}
