import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store';
import { getTranslations } from '@/api/languageResources';
/**
 * We use the lazy loading approach to load the translations from the API.
 * more info here (https://kazupon.github.io/vue-i18n/guide/lazy-loading.html)
 * For translating, where possible make use of the custom directive v-t="" because it is more performant
 * more info here (https://kazupon.github.io/vue-i18n/guide/directive.html#t-vs-v-t)
 */
Vue.use(VueI18n);
/**
 * Get the json with the translations from localstorage
 * Fetch it from the API if not fetched yet.
 */
async function getLanguageResources() {
    // @ts-ignore
    const localLanguageResources = JSON.parse(localStorage.getItem('languageResources'));
    if (localLanguageResources && localLanguageResources.version === store.getters['app/version']) {
        return localLanguageResources.data;
    }
    try {
        const languageResources = (await getTranslations()).data.catalog;
        localStorage.setItem('languageResources', JSON.stringify({ data: languageResources, version: store.getters['app/version'] }));
        return languageResources;
    }
    catch (error) {
        console.error(error);
    }
}
export const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en'
});
const loadedLanguages = [];
export async function loadLanguageAsync(lang = 'en') {
    if (loadedLanguages.includes(lang)) {
        return;
    }
    await Promise.resolve(getLanguageResources()).then(languageResources => {
        loadedLanguages.push(lang);
        i18n.setLocaleMessage(lang, languageResources);
    });
}
/**
 * This method is needed when we want to return different default value than the translation key.
 * Since i18n.t function returns the key when no translation exits.
 */
export function translateOrDefault(key, defaultValue) {
    return i18n.te(key) ? i18n.t(key) : defaultValue;
}
