import { $, go } from '../../../plugins/gojs';
import { DIRECTED_LINK, showSmallPorts, TEXT_NODE_PANEL, TRACKED_TRIGGER_PANEL, UNDIRECTED_LINK, IMAGE_NODE_PANEL } from '../utils/VisualEditorTemplates';
import { LINK_CATEGORIES, NODE_CATEGORIES } from '../utils/VisualEditorConstants';
const makePort = (name, spot, output, input, addNode) => {
    // the port is basically just a small transparent square
    return $(go.Shape, 'Circle', 
    // @ts-ignore
    {
        fill: null,
        stroke: null,
        desiredSize: new go.Size(7, 7),
        alignment: spot,
        alignmentFocus: spot,
        portId: name,
        fromSpot: spot,
        toSpot: spot,
        fromLinkable: output,
        toLinkable: input,
        cursor: 'pointer',
        click: addNode
    });
};
export const setNodeTemplateMap = (addNode) => {
    const trackedTriggerTemplate = $(go.Node, 'Spot', new go.Binding('position', 'location').makeTwoWay(), 
    // @ts-ignore
    {
        selectable: true,
        resizable: true,
        resizeObjectName: 'TRACKED_TRIGGER_PANEL',
        // handle mouse enter/leave events to show/hide the ports
        mouseEnter: function (e, node) { showSmallPorts(node, true); },
        mouseLeave: function (e, node) { showSmallPorts(node, false); }
    }, TRACKED_TRIGGER_PANEL(), 
    // four small named ports, one on each side:
    makePort('T', go.Spot.Top, true, true, addNode), makePort('L', go.Spot.Left, true, true, addNode), makePort('R', go.Spot.Right, true, true, addNode), makePort('B', go.Spot.Bottom, true, true, addNode));
    const textNodeTemplate = $(go.Node, 'Spot', new go.Binding('position', 'location').makeTwoWay(), 
    // @ts-ignore
    {
        selectable: true,
        resizable: true,
        resizeObjectName: 'TEXT_NODE_PANEL',
        // handle mouse enter/leave events to show/hide the ports
        mouseEnter: function (e, node) { showSmallPorts(node, true); },
        mouseLeave: function (e, node) { showSmallPorts(node, false); }
    }, TEXT_NODE_PANEL(), 
    // four small named ports, one on each side:
    makePort('T', go.Spot.Top, true, true, addNode), makePort('L', go.Spot.Left, true, true, addNode), makePort('R', go.Spot.Right, true, true, addNode), makePort('B', go.Spot.Bottom, true, true, addNode));
    const imageNodeTemplate = $(go.Node, 'Spot', new go.Binding('position', 'location').makeTwoWay(), new go.Binding('desiredSize', 'size', go.Size.parse).makeTwoWay(go.Size.stringify), 
    // @ts-ignore
    {
        selectable: true,
        resizable: true,
        resizeObjectName: 'PICTURE',
        minSize: new go.Size(100, 30),
        mouseEnter: function (e, node) {
            showSmallPorts(node, true);
        },
        mouseLeave: function (e, node) {
            showSmallPorts(node, false);
        }
    }, IMAGE_NODE_PANEL(), 
    // four small named ports, one on each side:
    makePort('T', go.Spot.Top, true, true, addNode), makePort('L', go.Spot.Left, true, true, addNode), makePort('R', go.Spot.Right, true, true, addNode), makePort('B', go.Spot.Bottom, true, true, addNode));
    const nodeTemplateMap = new go.Map();
    // @ts-ignore
    nodeTemplateMap.add(NODE_CATEGORIES.TRACKED_TRIGGER, trackedTriggerTemplate);
    // @ts-ignore
    nodeTemplateMap.add(NODE_CATEGORIES.IMAGE, imageNodeTemplate);
    // @ts-ignore
    nodeTemplateMap.add(NODE_CATEGORIES.DEFAULT, textNodeTemplate);
    return nodeTemplateMap;
};
export const setLinkTemplateMap = () => {
    const linkTemplateMap = new go.Map();
    // @ts-ignore
    linkTemplateMap.add(LINK_CATEGORIES.UNDIRECTED, UNDIRECTED_LINK());
    // @ts-ignore
    linkTemplateMap.add(LINK_CATEGORIES.DIRECTED, DIRECTED_LINK());
    // set the default link template to be directed link. This will be used by gojs if link category is not provided
    // @ts-ignore
    linkTemplateMap.add(LINK_CATEGORIES.DEFAULT, DIRECTED_LINK());
    return linkTemplateMap;
};
