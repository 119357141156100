import { hash } from '../../../utils/strings';
export const HSLToRGB = (h, s, l) => {
    s /= 100;
    l /= 100;
    const k = (n) => (n + h / 30) % 12;
    const a = s * Math.min(l, 1 - l);
    const f = (n) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
    return [Math.floor(255 * f(0)), Math.floor(255 * f(8)), Math.floor(255 * f(4))];
};
export const stringToColour = (str) => {
    const hashed = hash(str);
    const h = (hashed % 360);
    const s = 100;
    const l = 50;
    const rgb = HSLToRGB(h, s, l).map(c => c.toString(16).padStart(2, '0'));
    return `#${rgb[0]}${rgb[1]}${rgb[2]}`;
};
export const getHexColorLuminosity = (hexColor) => {
    const R = parseInt(hexColor.substring(1, 3), 16);
    const G = parseInt(hexColor.substring(3, 5), 16);
    const B = parseInt(hexColor.substring(5, 7), 16);
    const redLuminance = 0.2126;
    const greenLuminance = 0.7152;
    const blueLuminance = 0.0722;
    return redLuminance * R + greenLuminance * G + blueLuminance * B;
};
