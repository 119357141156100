function get(baseProtocol) {
    let baseHost = '';
    try {
        baseHost = process.env.VUE_APP_API_HOST ?? document.location.host;
    }
    catch (e) {
        console.error('Environment: get', e);
    }
    if (!baseHost)
        return '';
    const useSecureProtocol = baseHost.endsWith('.com');
    return `${baseProtocol}${useSecureProtocol ? 's' : ''}://${baseHost}`;
}
export const API_HOST = get('http');
export const WS_HOST = get('ws');
