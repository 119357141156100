import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getUsers } from '../../../api/users';
export const useUsersStore = defineStore('users', () => {
    const allUsers = ref([]);
    const fetchAll = async () => {
        allUsers.value = (await getUsers()).data;
    };
    return {
        allUsers,
        fetchAll
    };
});
