import { defineStore } from 'pinia';
import Vue from 'vue';
/**
 * Vuex module to manage the application
 */
export const appStore = defineStore('app', {
    state: () => ({
        hasPendingChanges: false,
        isRUMActive: false
    }),
    actions: {
        setRUMUser(user) {
            if (!this.isRUMActive)
                return;
            Vue.prototype.$apm.setUserContext({
                id: user.username,
                username: user.username,
                email: user.email
            });
        },
        resetRUMUser() {
            if (!this.isRUMActive)
                return;
            Vue.prototype.$apm.setUserContext({
                id: null,
                username: null,
                email: null
            });
        }
    }
});
