<template lang="pug">
  GenericDialog(
    title="Delete Tracked Trigger"
    :on-delete="deleteTrigger"
    @cancel="emitClose"
    @click-outside="emitClose"
    :can-delete="!errorMessage"
  )
    template(v-slot:activator="{ on }")
      slot(name="activator" v-bind:on="on")
    template(v-slot:content="")
      v-container(class="pa-0" fluid)
        v-row
          v-col
            div(v-if="!errorMessage")
              span Are you sure you want to delete this tracked trigger?
              div
                b Warning:
                span This action cannot be undone!
            div(v-else)
              span(class="warning--text") {{ errorMessage }}
</template>

<script>
import GenericDialog from '@/components/common/GenericDialog'
import { deleteTrackedTrigger } from '@/api/trackedTriggers'

export default {
  name: 'DeleteTrackedTriggerDialog',
  components: { GenericDialog },
  props: {
    trigger: {
      type: Object,
      required: true
    }
  },
  emits: ['close-menu', 'delete-trigger'],
  data() {
    return {
      errorMessage: ''
    }
  },
  methods: {
    async deleteTrigger() {
      try {
        await deleteTrackedTrigger(this.trigger.id)
        this.$emit('delete-trigger', this.trigger.id)
        this.$store.commit('snackbar/show', {
          color: 'info',
          message: `Trigger '${this.trigger.virtual_id}' successfully deleted.`
        })
        this.emitClose()
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.errorMessage = error.response.data.error
          throw error
        } else {
          this.$store.commit('snackbar/show', {
            color: 'error',
            message: 'Error deleting trigger'
          })
        }
      }
      this.errorMessage = ''
    },
    emitClose() {
      this.$emit('close-menu')
      this.errorMessage = ''
    }
  }
}
</script>
