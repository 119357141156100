import http from '../plugins/http';
const ENDPOINT = 'projects/workbooks/blocks/comments';
export function getInventionWorkbookBlockComments(inventionWorkbookBlockId, params = {}) {
    return http.get(`${ENDPOINT}/`, {
        params: {
            ...params,
            invention_workbook_block_id: inventionWorkbookBlockId
        }
    });
}
export function createInventionWorkbookBlockComment(data) {
    return http.post(`${ENDPOINT}/`, data);
}
export function patchInventionWorkbookBlockComment(inventionWorkbookBlockCommentId, data) {
    return http.patch(`${ENDPOINT}/${inventionWorkbookBlockCommentId}/`, data);
}
export function deleteInventionWorkbookBlockComment(inventionWorkbookBlockCommentId) {
    return http.delete(`${ENDPOINT}/${inventionWorkbookBlockCommentId}/`);
}
