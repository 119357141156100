import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import TriggerComponent from '../components/TriggerComponent';
export default Node.create({
    name: 'trigger',
    group: 'block',
    content: 'essentialfeature+',
    draggable: true,
    addAttributes() {
        return {
            link: {
                default: ''
            },
            trackedTrigger: {
                default: ''
            },
            summary: {
                default: ''
            },
            source: {
                default: ''
            },
            aspects: {
                default: null
            },
            date: {
                default: ''
            },
            feedId: {
                default: null
            },
            type: {
                default: ''
            },
            triggerTitle: {
                default: ''
            },
            essentialFeaturesCollapsed: {
                default: false
            }
        };
    },
    parseHTML() {
        return [
            {
                tag: 'trigger'
            }
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['trigger', mergeAttributes(HTMLAttributes), 0];
    },
    addNodeView() {
        return VueNodeViewRenderer(TriggerComponent);
    }
});
