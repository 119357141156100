<template lang="pug">
  v-card-actions
    v-btn(
      color="warning"
      block
      @click="startLoginFlow"
      tile elevation="0"
      :loading="loginFlowExecuting"
      :disabled="loginFlowExecuting"
    ) use your company account
</template>

<script>
import { authStore } from '@/store/modules/auth'

export default {
  name: 'AdfsSSOAuth',
  props: {
    meta: {
      default: () => ({}),
      type: Object
    }
  },
  data() {
    return {
      loginFlowExecuting: false
    }
  },
  computed: {
    loginCode() {
      if (this.$route.name !== 'login') return undefined
      return this.$route.query.code
    }
  },
  watch: {
    loginCode() {
      return this.executeLogin()
    }
  },
  mounted() {
    return this.executeLogin()
  },
  methods: {
    async startLoginFlow() {
      this.loginFlowExecuting = true
      try {
        await authStore().startAadSSOAuthFlow()
      } catch (error) {
        this.$store.commit('snackbar/show', {
          color: 'error',
          message: 'Failed to login. Please contact support.'
        })
      }
      this.loginFlowExecuting = false
    },
    async executeLogin() {
      if (this.$route.name !== 'login') localStorage.setItem('afterLogin', this.$route.fullPath)
      const auth = authStore()
      if (!this.loginCode || auth.isAuthenticated) return
      try {
        this.loginFlowExecuting = true
        await auth.finishAadSSOAuthFlow(this.$route.query)
      } catch (error) {
        let message = 'Failed to login. Please contact support.'
        let color = 'error'
        if (error.response?.status === 400) {
          color = 'warning'
          message = error.response?.data?.error_message
        }
        if (error.response?.status === 429) {
          color = 'warning'
          message = error.response?.data
        }
        this.$store.commit('snackbar/show', { color, message })
        this.$router.replace({ name: 'login' })
      }
      this.loginFlowExecuting = false
    }
  }
}
</script>
