/**
 * KPIs store
 */
import { getTypeFilters } from '@/api/kpis'
import store from '@/store'

const storeGetters = {
  typeFilterOptions: (state) => state.typeFilterOptions
}

const storeMutations = {
  setTypeFilterOptions(state, newTypeFilterOptions) {
    state.typeFilterOptions = newTypeFilterOptions
    localStorage.setItem('kpis_typeFilterOption',
      JSON.stringify({ data: newTypeFilterOptions, version: store.getters['app/version'] }))
  }
}

const storeActions = {
  /**
   * Get the type filters for the KPIs
   * Fetch it from the API if not fetched yet.
   */
  async getTypeFilterOptions({ commit, getters }) {
    let typeFilterOptions = getters.typeFilterOptions
    if (typeFilterOptions) return typeFilterOptions

    const localTypeFilterOptions = JSON.parse(localStorage.getItem('kpis_typeFilterOption'))
    if (localTypeFilterOptions && localTypeFilterOptions.version === store.getters['app/version']) {
      typeFilterOptions = localTypeFilterOptions.data
    } else {
      typeFilterOptions = (await getTypeFilters()).data
    }
    commit('setTypeFilterOptions', typeFilterOptions)
    return typeFilterOptions
  }
}

export default {
  namespaced: true,

  state: {
    typeFilterOptions: null
  },

  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions
}
