import { fromPairs } from 'lodash/array';
import { makeIdeaRefinementState } from './refinementState';
import isNumber from 'lodash/isNumber';
import { cloneDeep } from 'lodash/lang';
function getAscendants(idea, ideasByUuid) {
    if (!idea.request_concept_to_refine_id || !ideasByUuid[idea.request_concept_to_refine_id])
        return [idea];
    return [...getAscendants(ideasByUuid[idea.request_concept_to_refine_id], ideasByUuid), idea];
}
export function conceptsToDisplay(candidateIdeas, idToMatch) {
    const ideasByUuid = fromPairs(candidateIdeas.map((idea) => [idea.uuid, idea]));
    const refinementStates = [];
    const refinementStatesByConceptUuid = {};
    let matchedIndex = -1;
    candidateIdeas
        .sort((a, b) => b.created_at.localeCompare(a.created_at));
    candidateIdeas
        .forEach((idea) => {
        const ascendants = getAscendants(idea, ideasByUuid);
        const refinementStateIndex = ascendants.findIndex((ascendant) => refinementStatesByConceptUuid[ascendant.uuid]);
        const foundRefinementState = refinementStateIndex >= 0 ? refinementStatesByConceptUuid[ascendants[refinementStateIndex].uuid] : null;
        if (foundRefinementState) {
            foundRefinementState.bookmarkedConcept = foundRefinementState.bookmarkedConcept ?? ascendants.find(a => a.bookmarked);
            foundRefinementState.savedAsInventionConcept = foundRefinementState.savedAsInventionConcept ?? ascendants.find(a => isNumber(a.invention_concept_id));
        }
        else {
            const refinementState = makeIdeaRefinementState(ascendants[ascendants.length - 1]);
            refinementState.bookmarkedConcept = ascendants.find(a => a.bookmarked);
            refinementState.savedAsInventionConcept = ascendants.find(a => isNumber(a.invention_concept_id));
            refinementState.ideaStack = ascendants;
            refinementState.editionTracker = ascendants.map((ascendant) => ({
                assessed: cloneDeep(ascendant.content),
                initial: cloneDeep(ascendant.content)
            }));
            refinementState.currentIndex = ascendants.length - 1;
            ascendants.forEach((ascendant) => {
                refinementStatesByConceptUuid[ascendant.uuid] = refinementState;
                if (ascendant.uuid === idToMatch)
                    matchedIndex = refinementStates.length;
            });
            refinementStates.push(refinementState);
        }
    });
    return [refinementStates, matchedIndex];
}
