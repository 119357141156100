import Vue from 'vue';
import { sleep } from '../../utils/sleep';
import { Mutex } from 'async-mutex';
import { keyBy, orderBy } from 'lodash/collection';
import { deleteAllReadNotifications, deleteNotification, getNotifications, getNotificationsTypes, markAllAsRead, patchNotification } from '../../api/notifications';
import { NOTIFICATION_CATEGORY_NORMAL, NOTIFICATION_CATEGORY_TODAY_AT_A_GLANCE } from '../../utils/constants';
import { defineStore } from 'pinia';
import { authStore } from '../../store/modules/auth';
import { mapValues } from 'lodash/object';
import { template } from 'lodash/string';
import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';
import { useConfigStore } from './configuration';
const fetchNotificationMutex = new Mutex();
const filterNotification = (state, category) => {
    if (isEmpty(state.notificationTypes))
        return [];
    return orderBy(state.allNotifications.filter((n) => state.notificationTypes[n.verb_id]?.category === category), ['timestamp'], ['desc']);
};
export const notificationStore = defineStore('notifications', {
    state: () => ({
        notificationTypes: {},
        allNotifications: [],
        isLiveFetching: false
    }),
    getters: {
        notifications: state => filterNotification(state, NOTIFICATION_CATEGORY_NORMAL),
        todayAtAGlance: state => filterNotification(state, NOTIFICATION_CATEGORY_TODAY_AT_A_GLANCE)
    },
    actions: {
        async getNotificationTypes() {
            if (!isEmpty(this.notificationTypes))
                return this.notificationTypes;
            if (!authStore().isAuthenticated)
                return Promise.reject(new Error('Not authenticated'));
            let notificationTypes;
            const localNotificationTypes = JSON.parse(localStorage.getItem('notificationTypes') ?? 'null');
            const config = useConfigStore();
            if (localNotificationTypes && localNotificationTypes.version === config.INVENTION_STUDIO_VERSION) {
                notificationTypes = localNotificationTypes.content;
            }
            else {
                notificationTypes = (await getNotificationsTypes()).data;
                localStorage.setItem('notificationTypes', JSON.stringify({ content: notificationTypes, version: config.INVENTION_STUDIO_VERSION }));
            }
            notificationTypes.forEach(type => {
                const queryParams = type.param_mapping.query_params;
                if (queryParams) {
                    const queryParamTemplateCache = mapValues(queryParams, (mapping) => template(`$\{${mapping}}`));
                    type.mapQueryParams = notification => mapValues(queryParams, (_, key) => queryParamTemplateCache[key](notification));
                    delete type.param_mapping.query_params;
                }
                else {
                    type.mapQueryParams = () => undefined;
                }
                const routeParamTemplateCache = mapValues(type.param_mapping, (mapping) => template(`$\{${mapping}}`));
                type.mapRouteParams = notification => mapValues(type.param_mapping, (_, key) => routeParamTemplateCache[key](notification));
                // eslint-disable-next-line no-template-curly-in-string
                type.doRenderTemplate = template(type.render_template.replaceAll(/\${(.+?)}/g, '<b>${$1}</b>'));
            });
            this.notificationTypes = keyBy(notificationTypes, 'id');
            return this.notificationTypes;
        },
        async fetchNotifications() {
            const auth = authStore();
            const isAuthenticated = auth.isAuthenticated;
            if (!isAuthenticated)
                return;
            const notifications = await fetchNotificationMutex.runExclusive(() => getNotifications({}));
            this.allNotifications = notifications.data;
            return this.allNotifications;
        },
        async startLiveFetchNotifications() {
            const auth = authStore();
            if (this.isLiveFetching)
                return;
            this.isLiveFetching = true;
            await this.fetchNotifications();
            while (this.isLiveFetching) {
                await auth.waitForAuthenticated();
                if (document.visibilityState !== 'hidden') {
                    try {
                        const notifications = await fetchNotificationMutex.runExclusive(() => getNotifications({ since: max(this.allNotifications.map(n => n.timestamp)) }));
                        this.allNotifications.push(...notifications.data);
                    }
                    catch (e) {
                        console.warn('failed to fetch notifications.', e); // let's not make a fuss about it
                    }
                }
                await sleep(60000);
            }
            this.isLiveFetching = false;
        },
        async deleteNotification(notification) {
            await deleteNotification(notification.id);
            this.allNotifications.splice(this.allNotifications.findIndex(n => n.id === notification.id), 1);
        },
        async markAllAsRead() {
            await markAllAsRead();
            await this.fetchNotifications();
        },
        async deleteAllReadNotifications() {
            await deleteAllReadNotifications();
            await this.fetchNotifications();
        },
        async patchNotification(id, patch) {
            const response = await patchNotification(id, patch);
            Vue.set(this.allNotifications, this.allNotifications.findIndex(n => n.id === response.data.id), response.data);
        }
    }
});
