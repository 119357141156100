import http from '../plugins/http';
const ENDPOINT = 'generate-ideas';
const CANDIDATE_IDEA_ENDPOINT = 'candidate-idea';
export function createIdeaGenerationRequest(params) {
    return http.post(ENDPOINT, params);
}
export function getIdeaGenerationRequest(uuid) {
    return http.get(`${ENDPOINT}/${uuid}/`);
}
export function patchCandidateIdea(uuid, params) {
    return http.patch(`${CANDIDATE_IDEA_ENDPOINT}/${uuid}/`, params);
}
export function analyzePhrase(text) {
    return http.post(`${ENDPOINT}/analyse_phrase/`, { text });
}
export function getRefinementOptions(data) {
    return http.post(`${ENDPOINT}/refine_idea/`, data, { timeout: 30000 });
}
export function listCandidateIdeas(params) {
    return http.get(`${CANDIDATE_IDEA_ENDPOINT}/`, { params });
}
export function createCandidateIdea(content, projectUuid) {
    return http.post(`${CANDIDATE_IDEA_ENDPOINT}/`, { ...content, project: projectUuid });
}
export function deleteCandidateIdea(uuid) {
    return http.delete(`${CANDIDATE_IDEA_ENDPOINT}/${uuid}/`);
}
export function reassessCandidateIdea(uuid) {
    return http.post(`${CANDIDATE_IDEA_ENDPOINT}/${uuid}/reassess_idea/`, {}, { timeout: 60000 });
}
export function saveInvention(uuid, data) {
    return http.post(`${CANDIDATE_IDEA_ENDPOINT}/${uuid}/save_invention/`, data);
}
