import { defineStore } from 'pinia';
import { useAsyncState } from '../../../utils/asyncState';
import { autocompleteAspects } from '../../../api/opportunityAtlas';
import { computed, reactive, ref, watch } from 'vue';
import unionBy from 'lodash/unionBy';
export const useAspectsAutocompleteStore = defineStore('aspects-autocomplete-store', () => {
    // query for the aspects (topics) autocomplete
    const aspectsAutocompleteQuery = ref('');
    const selectedAspects = ref([]);
    // topics aspects (topics) results
    const autocompleteAspectsResults = useAsyncState(async () => {
        const result = await autocompleteAspects(aspectsAutocompleteQuery.value);
        return result.data;
    }, [], { allowParallel: false, immediate: false, debounceMs: 500 });
    const handleAutocompleteAspectsSearch = (query) => {
        aspectsAutocompleteQuery.value = query;
    };
    // search for aspects (topics) autocomplete result when the query changes
    watch(aspectsAutocompleteQuery, (newValue) => {
        if (newValue)
            return autocompleteAspectsResults.execute();
    });
    const autocompleteAspectsItems = computed(() => unionBy(autocompleteAspectsResults.state.value.map((v) => v.name), selectedAspects.value));
    const state = ref({
        aspectsAutocompleteQuery,
        selectedAspects,
        autocompleteAspectsLoading: autocompleteAspectsResults.loading,
        autocompleteAspectsItems
    });
    const actions = reactive({
        handleAutocompleteAspectsSearch
    });
    return { state, actions };
});
