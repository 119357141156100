import { Extension, findParentNodeClosestToPos } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';
import { triggerAsTiptapContent } from './helpers';
import { TRACKED_TRIGGER_SOURCE } from '../../triggers/constants';
export const DropTriggerEventHandler = Extension.create({
    name: 'dropEventHandler',
    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('dropEventHandler'),
                props: {
                    handleDrop(view, event) {
                        const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
                        if (!coordinates)
                            return;
                        let nodeContent;
                        if (event.dataTransfer?.types.includes('problemmining/problem')) {
                            const problem = JSON.parse(event.dataTransfer.getData('problemmining/problem'));
                            nodeContent = {
                                type: 'trigger',
                                attrs: {
                                    type: problem.type,
                                    triggerTitle: problem.title,
                                    link: problem.url,
                                    summary: problem.doc_content.join(' '),
                                    source: TRACKED_TRIGGER_SOURCE.OPP_ATLAS,
                                    date: problem.date
                                },
                                content: [{
                                        type: 'essentialfeature',
                                        attrs: { eftype: 'PROBLEM' },
                                        content: [{
                                                text: problem.problem_text,
                                                type: 'text'
                                            }]
                                    }]
                            };
                        }
                        if (event.dataTransfer?.types.includes('trackedtrigger')) {
                            try {
                                nodeContent = triggerAsTiptapContent(JSON.parse(event.dataTransfer.getData('trackedtrigger')));
                            }
                            catch (e) {
                                console.error('Error handling dropped data', event.dataTransfer.getData('trackedtrigger'));
                                return false; // prevents a crash if there is an issue with the json data
                            }
                        }
                        if (nodeContent) {
                            const nodetoInsert = view.state.schema.nodeFromJSON(nodeContent);
                            let posToInsert = coordinates.pos;
                            const parentTrigger = findParentNodeClosestToPos(view.state.doc.resolve(coordinates.pos), node => node.type.name === 'trigger');
                            if (parentTrigger)
                                posToInsert = parentTrigger.pos + parentTrigger.node.nodeSize;
                            view.dispatch(view.state.tr.insert(posToInsert, nodetoInsert));
                            return true;
                        }
                    }
                }
            })
        ];
    }
});
