import { ESSENTIAL_FEATURE_TYPES, NEWS_FEED } from '../../../utils/constants';
function makeStandardsTriggerTitle(trigger) {
    if (!trigger.standards_metadata)
        return '';
    return trigger.title === '' ? trigger.standards_metadata?.tdoc_id : `${trigger.standards_metadata?.tdoc_id}: ${trigger.title}`;
}
export function turnTriggerIntoTracked(trigger, projectId) {
    return {
        id: -1,
        project_id: projectId,
        feed_id: NEWS_FEED.uuid,
        essential_features: !trigger.essential_features
            ? undefined
            : trigger.essential_features.filter(ef => ef.type?.toUpperCase() in ESSENTIAL_FEATURE_TYPES)
                .slice(0, 10)
                .map(ef => ({
                id: -1,
                tracked_trigger: null,
                created_by: null,
                content: ef.text.length > 350 ? `${ef.text.slice(0, 350)}...` : ef.text,
                location_y: ef.location_y,
                location_x: ef.location_x,
                type: ef.type?.toUpperCase()
            })),
        title: trigger.type === 'standard' ? makeStandardsTriggerTitle(trigger) : trigger.title,
        url: trigger.url,
        assignee: trigger.assignee,
        trigger_date: trigger.date ?? trigger.trigger_date,
        content: trigger.type === 'standard' ? trigger.standards_content_summary : trigger.content?.join('\n') ?? '',
        doc_type: trigger.type,
        topics: trigger.topics ?? [],
        bookmark_id: trigger.bookmark_id,
        location_x: trigger.location_x,
        location_y: trigger.location_y,
        patent_number: trigger.patent_number ?? '',
        ...extractStandardsMetadata(trigger)
    };
}
export function extractStandardsMetadata(trigger) {
    const standardsMetadata = {};
    if (trigger.standards_metadata?.doc_type)
        standardsMetadata.tdoc_type = trigger.standards_metadata.doc_type;
    if (trigger.standards_metadata?.status)
        standardsMetadata.standards_status = trigger.standards_metadata.status;
    if (trigger.standards_metadata?.group)
        standardsMetadata.standards_group = trigger.standards_metadata.group;
    if (trigger.standards_metadata?.meeting)
        standardsMetadata.standards_meeting = trigger.standards_metadata.meeting;
    if (trigger.standards_metadata?.release)
        standardsMetadata.standards_release = trigger.standards_metadata.release;
    if (trigger.standards_metadata?.sources)
        standardsMetadata.standards_sources = trigger.standards_metadata.sources;
    if (trigger.standards_metadata?.agenda_item)
        standardsMetadata.standards_agenda_item = trigger.standards_metadata.agenda_item;
    if (trigger.standards_metadata?.for)
        standardsMetadata.standards_for = trigger.standards_metadata.for;
    if (trigger.standards_metadata?.revised_to)
        standardsMetadata.standards_revised_to = trigger.standards_metadata.revised_to;
    if (trigger.standards_metadata?.revision_of)
        standardsMetadata.standards_revision_of = trigger.standards_metadata.revision_of;
    if (trigger.standards_metadata?.tdoc_id)
        standardsMetadata.tdoc_id = trigger.standards_metadata.tdoc_id;
    if (trigger.standardization_score !== undefined)
        standardsMetadata.standardization_score = trigger.standardization_score;
    if (trigger.standards_metadata?.study_work_item)
        standardsMetadata.study_work_item = trigger.standards_metadata.study_work_item;
    return standardsMetadata;
}
