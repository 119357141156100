export default [
  {
    path: '/project/:project_id/scoping',
    name: 'scoping-home',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "scoping-home" */ '@/legacy_modules/scoping/views/ScopingHome.vue'),
    props: { nextView: 'scoping-feed' }
  },
  {
    path: '/project/:project_id/scoping/:feed_id',
    name: 'scoping-feed',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "scoping-feed" */ '@/legacy_modules/scoping/views/ScopingFeed.vue')
  }
]
