import http from '../plugins/http';
const ENDPOINT = 'essential-features';
export function createEssentialFeature(data) {
    return http.post(`${ENDPOINT}/`, data);
}
export function patchEssentialFeature(essentialFeatureId, data) {
    return http.patch(`${ENDPOINT}/${essentialFeatureId}/`, data);
}
export function deleteEssentialFeature(essentialFeatureId) {
    return http.delete(`${ENDPOINT}/${essentialFeatureId}`);
}
