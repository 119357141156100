import { instrumentizeCreation, instrumentizeDeletion } from '@/utils/instrumentizeCore/componentModifiers';
import { monitorStates as monitorStatesInstrument, logCalls as logCallsInstrument } from '@/utils/instrumentizeCore/instruments';
import { makeInstrumentWithConfig } from '@/utils/instrumentizeCore/utilities';
import { isFunction } from 'lodash';
export function monitorStates(...args) {
    /**
     * Instrument to monitor a given variable (data or computed) of a component.
     * instrumentize(
     *   MyComponent,
     *   { myVariable: monitorStates({ ignore: ['useless'] }) }
     * )
     * will watch MyComponent.myVariable, and log spans except when its value is `'useless'`
     */
    return makeInstrumentWithConfig(monitorStatesInstrument, { ignore: [] }, args);
}
export function logCalls(...args) {
    /**
     * Instrument to log the calls of a method component.
     * instrumentize(
     *   MyComponent,
     *   { myMethod: logCalls }
     * )
     * The parameters won't be logged
     */
    return makeInstrumentWithConfig(logCallsInstrument, {}, args);
}
export function instrumentize(component, config) {
    // @ts-ignore
    if (isFunction(component))
        component = component.options;
    component.$instrumentizedWatchers = [];
    instrumentizeCreation(component, config);
    instrumentizeDeletion(component);
    for (const [componentKey, monitorFunc] of Object.entries(config)) {
        if (!componentKey.startsWith('$'))
            monitorFunc(component, componentKey, config);
    }
    return component;
}
