import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { projectsStore } from '../../projects/store';
import { createCandidateIdea, createIdeaGenerationRequest, getIdeaGenerationRequest, listCandidateIdeas, patchCandidateIdea } from '../../../api/ideaGeneration';
import sleep from '../../../utils/sleep';
import { fromPairs } from 'lodash/array';
import { conceptsToDisplay } from './state_helpers/conceptOrganizer';
import { makeIdeaRefinementState } from './state_helpers/refinementState';
import router from '../../../router/index';
import { extractQueryString } from '../../../utils/routes';
import keyBy from 'lodash/keyBy';
import { useFlagsStore } from '../../../store/modules/flags';
import store from '@/store';
import { getPreference, setPreference } from '../../../utils/preferencesManager';
import { isEqual } from 'lodash';
export const ideaCreationSpaceStore = defineStore('idea-creation-space', () => {
    const goal = ref(projectsStore().currentProjectGoals[0] ?? '');
    const mustNotMatch = ref([]); // excluded terms
    const triggers = ref([]);
    const triggersInPreferences = ref([]);
    const currentRequest = ref(null);
    const refinementStates = ref([]);
    const filterOnState = ref('All');
    const filterOnType = ref([]);
    const refinementMap = computed(() => fromPairs(refinementStates.value.flatMap((state) => state.ideaStack.map((idea) => [idea.uuid, state]))));
    const ideas = computed(() => refinementStates.value.map((state) => state.ideaStack[state.currentIndex]));
    const selectedIndex = ref(0);
    async function fetchIdeasForCurrentProject() {
        const currentProjectId = projectsStore().currentProjectId;
        if (!currentProjectId)
            return;
        const ideas = await listCandidateIdeas({ project_id: currentProjectId, limit: 1000 });
        const [newRefinementStates, matchedIndex] = conceptsToDisplay(ideas.data.results, extractQueryString(router.currentRoute.query.conceptId));
        refinementStates.value = newRefinementStates;
        if (matchedIndex >= 0)
            selectedIndex.value = matchedIndex;
    }
    async function loadInitialState() {
        goal.value = projectsStore().currentProjectGoals[0] ?? '';
        triggers.value = await getTriggersFromPreference();
        currentRequest.value = null;
        refinementStates.value = [];
        selectedIndex.value = 0;
    }
    const setTriggersInPreference = () => {
        const projectId = projectsStore().currentProject?.uuid;
        const triggersAlreadySaved = isEqual(triggers.value, triggersInPreferences.value);
        if (!projectId || triggersAlreadySaved)
            return;
        setPreference('PROJECT_USED_TRIGGERS', {
            // save in preference only `ability` - `problem` are used just in current session for `invention goal`
            triggers: triggers.value.filter(trigger => trigger.trigger_type !== 'PROBLEM')
        }, { projectId });
    };
    const getTriggersFromPreference = async () => {
        const projectId = projectsStore().currentProject?.uuid;
        if (!projectId)
            return [];
        const res = await getPreference('PROJECT_USED_TRIGGERS', { projectId });
        if (res?.triggers) {
            triggersInPreferences.value = res.triggers;
            return res.triggers;
        }
        else {
            return [];
        }
    };
    loadInitialState();
    let lastProjectId = projectsStore().currentProjectId;
    watch(() => projectsStore().currentProjectId, function (newProjectId) {
        if (!newProjectId || newProjectId === lastProjectId)
            return;
        lastProjectId = newProjectId;
        loadInitialState();
    });
    const isLoading = computed(() => currentRequest.value?.state === 'CREATED' || currentRequest.value?.state === 'COMPUTING');
    const selectedIdea = computed(() => {
        if (ideas.value.length < 1)
            return null;
        return ideas.value[selectedIndex.value];
    });
    watch(() => ideas.value, (newValue) => {
        if (selectedIndex.value >= newValue.length)
            selectedIndex.value = 0;
    });
    watch(triggers, () => {
        setTriggersInPreference();
    }, { deep: true });
    async function addCustomIdea(title) {
        const projectId = projectsStore().currentProjectId;
        if (!projectId)
            return;
        const idea = (await createCandidateIdea({
            content: {
                title,
                concept: '',
                problem: ''
            }
        }, projectId)).data;
        refinementStates.value.splice(0, 0, makeIdeaRefinementState(idea));
        selectedIndex.value = 0;
    }
    async function createIdeaFromCanvas(params) {
        const projectId = projectsStore().currentProjectId;
        if (!projectId)
            return;
        try {
            return (await createCandidateIdea({
                content: {
                    title: params.title,
                    concept: params.concept,
                    problem: params.problem
                }
            }, projectId)).data;
        }
        catch (e) {
            store.commit('snackbar/show', {
                message: 'Failed to save Invention Concept',
                color: 'error'
            });
        }
    }
    async function setVote(idea, vote) {
        if (idea.vote === vote)
            vote = 0;
        const response = await patchCandidateIdea(idea.uuid, { vote });
        idea.vote = response.data.vote;
    }
    const likeIdea = (idea) => setVote(idea, 1);
    const dislikeIdea = (idea) => setVote(idea, -1);
    const conceptGenerationEnabled = computed(() => {
        return useFlagsStore().AI_CONCEPT_GENERATION_ENABLED && Boolean(projectsStore().currentProject?.enable_ai_concept_generation);
    });
    async function startIdeaGenerationRequest() {
        if (!goal.value)
            return;
        let response = await createIdeaGenerationRequest({
            goal: goal.value,
            triggers: triggers.value.map((trigger) => ({ ...trigger, doc_type: trigger.doc_type?.toUpperCase() })),
            project: projectsStore().currentProjectId,
            types_to_generate: filterOnType.value.length ? filterOnType.value : undefined,
            must_not_match: mustNotMatch.value
        });
        const currentUuid = response.data.uuid;
        do {
            currentRequest.value = response.data;
            await sleep(1000);
            response = await getIdeaGenerationRequest(currentRequest.value.uuid);
            response.data.ideas.forEach((idea) => {
                idea.parent = response.data.uuid;
                if (!Object.hasOwn(refinementMap.value, idea.uuid)) {
                    refinementStates.value.splice(0, 0, makeIdeaRefinementState(idea));
                    selectedIndex.value++;
                }
                else {
                    refinementMap.value[idea.uuid].ideaStack[0].scores = idea.scores;
                }
            });
        } while (currentRequest.value.uuid === currentUuid && (response.data.state === 'COMPUTING' || response.data.state === 'CREATED'));
        if (currentRequest.value.uuid === currentUuid)
            currentRequest.value = response.data;
    }
    const conceptShouldBeShown = (state) => {
        const originalIdea = state.ideaStack[0];
        if (filterOnType.value.length > 0 && !filterOnType.value.includes(originalIdea.idea_type)) {
            return false;
        }
        return ({
            All: () => true,
            Bookmarked: () => Boolean(state.bookmarkedConcept),
            Generated: () => Boolean(originalIdea.request_id),
            Manual: () => originalIdea.request_id === null
        })[filterOnState.value]();
    };
    const conceptsToShow = computed(() => {
        return keyBy(refinementStates.value.filter(conceptShouldBeShown), (state) => state.ideaStack[0].uuid);
    });
    return {
        goal,
        triggers,
        ideas,
        currentRequest,
        addCustomIdea,
        isLoading,
        selectedIndex,
        selectedIdea,
        startIdeaGenerationRequest,
        likeIdea,
        dislikeIdea,
        refinementStates,
        refinementMap,
        fetchIdeasForCurrentProject,
        loadInitialState,
        createIdeaFromCanvas,
        conceptGenerationEnabled,
        filterOnState,
        filterOnType,
        conceptsToShow,
        mustNotMatch
    };
});
