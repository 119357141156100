import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { getInventionConcept, patchInventionConcept } from '../../../api/inventionConcepts';
import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import { useInventionConceptsStore } from './inventionConceptsStore';
import store from '../../../store';
import { projectsStore } from '../../projects/store';
import omit from 'lodash/omit';
import { useUsersStore } from '../../users/store';
import differenceBy from 'lodash/differenceBy';
export const useSelectedInventionConceptStore = defineStore('selected-invention-concept', () => {
    const selectedIc = ref({});
    const icDataToPatch = ref({});
    const icStore = useInventionConceptsStore();
    const inventorToAdd = ref({});
    const inventorIds = ref([]);
    const allICInventors = computed({
        get: () => [icDataToPatch.value.owner, ...icDataToPatch.value.inventors],
        set: (value) => {
            if (!value.length)
                throw new Error('Inventors cannot be empty');
            icDataToPatch.value.owner_id = value[0].id;
            icDataToPatch.value.owner = value[0];
            icDataToPatch.value.inventors = value.slice(1, value.length);
            inventorIds.value = icDataToPatch.value.inventors.map(inventor => inventor.id);
        }
    });
    const projectStore = projectsStore();
    const usersStore = useUsersStore();
    const mainTriggers = computed(() => {
        return icDataToPatch.value.tracked_triggers
            .filter(trigger => trigger.essential_features_ic
            .some(feature => feature.is_main_trigger))
            .map(trigger => ({
            ...trigger,
            essential_features_ic: trigger.essential_features_ic
                .filter(feature => feature.is_main_trigger && feature.invention_concept === selectedIc.value.id)
        }));
    });
    const supportingTriggers = computed(() => {
        return icDataToPatch.value.tracked_triggers.map(trigger => ({
            ...trigger,
            essential_features_ic: trigger.essential_features_ic
                .filter(feature => !feature.is_main_trigger && feature.invention_concept === selectedIc.value.id)
        })).filter(trigger => trigger.essential_features_ic.length);
    });
    const getSelectedIc = async (id) => {
        selectedIc.value = (await getInventionConcept(id)).data;
    };
    const addInventorToIc = (inventor) => {
        allICInventors.value = [...allICInventors.value, inventor];
        inventorToAdd.value = {};
    };
    const removeInventor = (inventor) => {
        allICInventors.value = allICInventors.value.filter(inventorInIc => inventorInIc.id !== inventor.id);
    };
    const updateIc = async () => {
        // @ts-ignore
        let data = pickBy(icDataToPatch.value, (value, key) => !isEqual(value, selectedIc.value[key]));
        data = omit(data, ['inventors', 'owner']);
        if (inventorIds.value.length) {
            data.inventors_ids = inventorIds.value;
        }
        try {
            await patchInventionConcept(selectedIc.value.id, data);
            await icStore.getAllInventionConcepts();
            selectedIc.value = {};
            store.commit('snackbar/show', {
                color: 'success',
                message: `IC "${icDataToPatch.value.title}" has been successfully updated.`
            });
        }
        catch (error) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Something went wrong. Please try again.'
            });
        }
    };
    const revertIcChanges = () => {
        const tempICForUndo = cloneDeep(icDataToPatch.value);
        icDataToPatch.value = cloneDeep(selectedIc.value);
        store.commit('snackbar/show', {
            color: 'success',
            message: 'IC changes reverted',
            extraActions: [{
                    title: 'Undo',
                    action: () => {
                        icDataToPatch.value = cloneDeep(tempICForUndo);
                    }
                }]
        });
    };
    const updateICCanvasBlocks = async (icId, canvasBlockIds) => {
        try {
            await patchInventionConcept(icId, { invention_workbook_blocks_ids: canvasBlockIds });
        }
        catch (error) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Adding canvas blocks to IC failed.'
            });
        }
    };
    const usersInProject = computed(() => {
        return differenceBy(projectStore.currentProjectUsers?.map(userInProject => {
            const user = usersStore.allUsers?.find(user => user.id === userInProject.id);
            return user || null;
        }), allICInventors.value, 'id').filter(user => user?.is_active);
    });
    const nothingToSave = computed(() => {
        return isEqual(icDataToPatch.value, selectedIc.value);
    });
    const toggleBlockFolded = async (blockId) => {
        icDataToPatch.value.invention_workbook_blocks = icDataToPatch.value.invention_workbook_blocks
            .map(block => block.id === blockId ? { ...block, folded: !block.folded } : block);
    };
    watch(selectedIc, async () => {
        icDataToPatch.value = cloneDeep(selectedIc.value);
    });
    watch(() => projectsStore().currentProjectId, () => {
        icDataToPatch.value = {};
        selectedIc.value = {};
    });
    return {
        selectedIc,
        icDataToPatch,
        inventorToAdd,
        inventorIds,
        nothingToSave,
        usersInProject,
        allICInventors,
        removeInventor,
        addInventorToIc,
        getSelectedIc,
        updateIc,
        revertIcChanges,
        updateICCanvasBlocks,
        toggleBlockFolded,
        mainTriggers,
        supportingTriggers
    };
});
