/**
 * Vuex module to manage the authentication
 */
import { readonly, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { authStore } from './auth';
import { DefaultFlagsValues } from '../../interfaces/flags';
import { getFlags } from '../../api/flags';
import mapValues from 'lodash/mapValues';
import http from '../../plugins/http';
import throttle from 'lodash/throttle';
export const useFlagsStore = defineStore('feature-flags', () => {
    const flags = mapValues(DefaultFlagsValues, ref);
    let lastFlagCacheKey = '';
    const auth = authStore();
    let loadedResolve;
    let loadedReject;
    const loaded = ref(new Promise((resolve, reject) => {
        loadedResolve = resolve;
        loadedReject = reject;
    }));
    // rate limit the flag fetching, because:
    // 1. there can be a race condition between the token refresh and the flag fetching
    // 2. in case there's a bug in the flag fetching, we don't want to spam the server
    const fetchFlags = throttle(async () => {
        if (!auth.isAuthenticated)
            return;
        try {
            const response = await getFlags();
            if (!response?.data)
                return; // shouldn't ever happen, but some edge case related to async on the CI causes this to fail
            for (const flag of response.data) {
                if (!Object.hasOwn(flags, flag.key)) {
                    console.warn(`Unknown flag ${flag.key}`);
                    continue;
                }
                flags[flag.key].value = flag.value;
            }
            lastFlagCacheKey = response.headers['x-flags-cache-key'];
            loadedResolve();
        }
        catch (e) {
            loadedReject(e);
        }
    }, 100, { leading: false, trailing: true });
    // make things async, because otherwise we can create a deadlock when the token is refreshed
    watch(() => auth.profile.user_id, fetchFlags, { immediate: true });
    http.interceptors.response.use((x) => {
        // this prevents that when refreshing a token, we fetch the flags twice (because for the
        // refresh token request, the user is not authenticated, as the access token is expired)
        // since the user is not authenticated, we get flags for unauthenticated user, although we're
        // actually authenticated since we have a refresh token
        // @ts-ignore
        if (!x?.headers || (auth.isAuthenticated && x.config?.meta?.noAuth))
            return x;
        const cacheKey = x.headers['x-flags-cache-key'];
        if (lastFlagCacheKey && cacheKey && cacheKey !== lastFlagCacheKey) {
            lastFlagCacheKey = cacheKey;
            fetchFlags();
        }
        return x;
    }, (error) => Promise.reject(error));
    const flagValues = mapValues(flags, readonly);
    return {
        ...flagValues,
        loaded,
        forceValueInTest(key, value) {
            loadedResolve();
            flags[key].value = value;
        }
    };
});
