import mergeWith from 'lodash/mergeWith';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
export const mergeDeepRight = (obj1, obj2) => {
    return mergeWith(obj1, obj2, (objValue, srcValue) => {
        if (isObject(objValue)) {
            return merge(objValue, srcValue);
        }
    });
};
