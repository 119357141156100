/**
 * Vuex module to manage the main snackbar
 */

export const mutations = {
  close(state) {
    state.show = false
    state.extraActions = []
  },

  show(state, { color, message, timeout, showProgressBar = false, extraActions = [] }) {
    state.color = color
    state.message = message
    state.show = true
    state.timeout = timeout || 10000
    state.extraActions = extraActions
    state.showProgressBar = showProgressBar
  }
}

export default {
  namespaced: true,

  state: {
    color: 'info',
    show: false,
    message: '',
    extraActions: [],
    timeout: 10000,
    showProgressBar: false
  },

  mutations
}
