<template lang="pug">
  v-snackbar(
    v-model="show"
    :color="$store.state.snackbar.color"
    :timeout="timeout"
  )
    v-progress-linear(v-if="showProgressBar" absolute top color="iprovaDark" v-model="progress" )
    span(v-html="$store.state.snackbar.message")
    template(v-slot:action="{ attrs }")
      v-btn(
        v-for="(action, id) in $store.state.snackbar.extraActions"
        :key="id"
        @click="() => { action.action(); closeSnackbar() }"
        plain
        small
        tile
        outlined
        dark
        v-bind="attrs"
        class="ml-2"
      ) {{ action.title }}
      v-btn(
        @click="closeSnackbar"
        tile
        small
        outlined
        icon
        dark
        class="ml-2"
        v-bind="attrs"
      )
        v-icon mdi-close
</template>

<script>
export default {
  data() {
    return {
      timeElapsed: 0,
      intervalId: null,
      progress: 0
    }
  },
  computed: {
    show: {
      get() {
        return this.$store.state.snackbar.show
      },
      set(newValue) {
        if (newValue === true) {
          throw new Error('"show" computed property cannot be set to "true"')
        }

        this.closeSnackbar()
      }
    },

    timeout() {
      return this.$store.state.snackbar.timeout
    },
    showProgressBar() {
      return this.$store.state.snackbar.showProgressBar
    }
  },

  watch: {
    show() {
      if (this.show && this.showProgressBar) {
        this.syncProgressBar()
        return
      }
      clearInterval(this.intervalId)
    }
  },

  methods: {
    closeSnackbar() {
      this.$store.commit('snackbar/close')
    },
    syncProgressBar() {
      this.timeElapsed = 0
      this.progress = 0
      this.intervalId = setInterval(() => {
        this.progress = Math.floor(100 * (this.timeElapsed / this.timeout))
        this.timeElapsed += 100
      }, 100)
    }
  }
}
</script>
