import http from '@/plugins/http'
import { isValidUUID } from '@/utils/validations'

const ENDPOINT = 'sprints'

export function getSprintById(sprintId) {
  return http({
    url: `${ENDPOINT}/${sprintId}`,
    method: 'get'
  })
}

export function getSprintsByProjectId(projectId) {
  if (!isValidUUID(projectId)) throw Error('Parameter projectId is not a valid UUID.')
  return http({
    url: `${ENDPOINT}`,
    method: 'get',
    params: { project_id: projectId }
  })
}

export function createSprint(data) {
  return http({
    url: `${ENDPOINT}`,
    method: 'post',
    data
  })
}

export function updateSprint(data) {
  return http({
    url: `${ENDPOINT}/${data.id}/`,
    method: 'put',
    data
  })
}
