import * as gojs from 'gojs/release/go-module';
import { useConfigStore } from '../store/modules/configuration';
export const $ = gojs.GraphObject.make;
export const go = gojs;
let initialized = false;
export function ensureGoJsLicense() {
    if (initialized)
        return;
    initialized = true;
    const GOJS_LICENSE = useConfigStore().GOJS_LICENSE;
    if (GOJS_LICENSE)
        go.Diagram.licenseKey = GOJS_LICENSE;
}
