import { defineStore } from 'pinia';
import { ref } from 'vue';
import { dateToIsoDate } from '../../../utils/constants';
import { getProjectsTrackedTriggers } from '../../../api/projects';
import { createTrackedTrigger, deleteTrackedTrigger, patchTrackedTrigger } from '../../../api/trackedTriggers';
import { useTrackedTriggersStore } from './trackedTriggers';
import { isTrackedTrigger } from '../../../interfaces/tracker';
import store from '@/store';
import { authStore } from '@/store/modules/auth';
import cloneDeep from 'lodash/cloneDeep';
import { ensureUrlHasProtocol } from '../../../utils/strings';
import { createEmptyTrackedTrigger, createEmptyTrigger, fetchTriggerForUrl, getTriggerTitle, mapEssentialFeatures, printWarningMessages, showErrorMessage, showSuccessMessages } from '../utils/triggerTracking';
export const useTriggerTrackUpdateStore = defineStore('useTriggerTrackUpdateStore', () => {
    const triggerActiveProject = ref(null);
    const projectsTrackedTriggers = ref([]);
    const trackedTrigger = ref(createEmptyTrackedTrigger());
    const trigger = ref(createEmptyTrigger());
    const savedProjects = ref([]);
    const trackedTriggersStore = useTrackedTriggersStore();
    const disabledProjects = (project) => {
        return project.tracked_triggers.some((i) => i.title === trigger.value.title || i.url === trigger.value.url);
    };
    const alreadyInInput = (project) => {
        return trigger.value.projects.some((i) => i.uuid === project.uuid);
    };
    const fetchProjectsTrackedTriggers = async () => {
        const params = {
            archived: false,
            completed: false
        };
        projectsTrackedTriggers.value = (await getProjectsTrackedTriggers(params)).data;
        trigger.value.projects = projectsTrackedTriggers.value.filter(project => (disabledProjects(project) && !alreadyInInput(project)) || triggerActiveProject.value === project.uuid);
        savedProjects.value = cloneDeep(trigger.value.projects);
    };
    const setNotTrackedTrigger = async (t, source = null) => {
        const fromEs = await fetchTriggerForUrl(t.url);
        if (!fromEs) {
            return store.commit('snackbar/show', {
                color: 'error',
                message: "Trigger couldn't be found in database. Please manually add it."
            });
        }
        const content = t.doc_type?.toLowerCase() === 'standard' ? (t.content ?? '') : fromEs?.content?.join(' ') ?? '';
        trigger.value = createEmptyTrigger({
            title: getTriggerTitle(t, fromEs),
            content,
            url: ensureUrlHasProtocol(fromEs.url),
            location_x: t.location_x,
            location_y: t.location_y,
            source,
            triggerDate: fromEs.trigger_date ?? fromEs.date ?? dateToIsoDate(new Date()),
            doc_type: t.doc_type.toLowerCase(),
            essential_features: mapEssentialFeatures(t.essential_features)
        });
    };
    const setTrigger = async (t, source = null) => {
        if (!isTrackedTrigger(t)) {
            setNotTrackedTrigger(t, source);
        }
        else {
            trackedTrigger.value = t;
            trigger.value = {
                assignee: t.assignee ?? '',
                patent_number: t.patent_number ?? '',
                title: t.title,
                content: Array.isArray(t.content) ? t.content.join(' ') : t.content ?? '',
                url: ensureUrlHasProtocol(t.url),
                triggerDate: t.trigger_date ? t.trigger_date : dateToIsoDate(new Date()),
                doc_type: t.doc_type,
                projects: [],
                source,
                location_x: t.location_x,
                location_y: t.location_y,
                essential_features: mapEssentialFeatures(t.essential_features) ?? []
            };
        }
        await fetchProjectsTrackedTriggers();
    };
    const getTriggerPayload = (projectUuid) => {
        const docType = trigger.value.doc_type.toLowerCase();
        return {
            assignee: trigger.value.assignee ?? '',
            patent_number: trigger.value.patent_number ?? '',
            title: trigger.value.title,
            url: trigger.value.url,
            project_id: projectUuid,
            trigger_date: trigger.value.triggerDate ? dateToIsoDate(new Date(trigger.value.triggerDate)) : dateToIsoDate(new Date()),
            doc_type: docType,
            user_id: authStore().profile.user_id,
            content: trigger.value.content,
            source: trigger.value.source,
            location_x: trigger.value.location_x,
            location_y: trigger.value.location_y,
            feed_id: null,
            essential_features: trigger.value?.essential_features ?? []
        };
    };
    const resetEfAndTrackedTrigger = () => {
        trigger.value.essential_features = [];
        trackedTrigger.value = createEmptyTrackedTrigger();
    };
    const saveTriggerInProject = async (project, warningMessages) => {
        try {
            const shouldDelete = !trigger.value.projects.some(p => p.uuid === project.uuid);
            if (shouldDelete) {
                const ttUuid = savedProjects.value
                    .find(p => p.uuid === project.uuid)?.tracked_triggers
                    .find(tt => tt.url === trigger.value.url)?.uuid;
                if (ttUuid) {
                    return deleteTrackedTrigger(ttUuid);
                }
            }
            const data = getTriggerPayload(project.uuid);
            const shouldUpdate = trackedTriggersStore.trackedTriggers.find((trigger) => trigger.url === trackedTrigger.value.url);
            if (shouldUpdate)
                await patchTrackedTrigger(trackedTrigger.value.id, data);
            else
                await createTrackedTrigger(data);
            resetEfAndTrackedTrigger();
        }
        catch (e) {
            showErrorMessage(e, warningMessages);
        }
    };
    const saveTrackedTrigger = async () => {
        const warningMessages = [];
        const quotedSavedProjectNames = [];
        await Promise.all(trigger.value.projects
            .filter(p => !disabledProjects(p) || p.uuid === triggerActiveProject.value)
            .map(p => saveTriggerInProject(p, warningMessages)
            .then(() => quotedSavedProjectNames.push(`"${p.name}"`))));
        const isUpdate = trackedTriggersStore.trackedTriggers.some((t) => t.url === trigger.value.url);
        printWarningMessages(warningMessages);
        showSuccessMessages(quotedSavedProjectNames, isUpdate);
        if (triggerActiveProject.value)
            await trackedTriggersStore.fetchTrackedTriggers();
    };
    return {
        triggerActiveProject,
        setTrigger,
        saveTrackedTrigger,
        trigger
    };
});
