export function emptyProfile() {
    return {
        id: null,
        profile_picture: null,
        user_id: null,
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        is_superuser: false,
        signon_method: undefined,
        date_joined: undefined,
        last_login: undefined,
        trusted: false,
        trusted_method: undefined,
        project_assignments: []
    };
}
