/**
 * InventionConcept store
 */
import {
  getInventionConcept,
  getInventionConceptTransitions,
  getInventionConceptExtraFields
} from '@/api/inventionConcepts'
import store from '@/store'

const storeGetters = {
  transitionMatrix: (state) => state.transitionMatrix,
  extraFields: (state) => state.extraFields,
  currentInventionConcept: (state) => state.currentInventionConcept
}

const storeMutations = {
  setTransitionMatrix(state, newTransitionMatrix) {
    state.transitionMatrix = newTransitionMatrix
  },

  setExtraFields(state, newExtraFields) {
    state.extraFields = newExtraFields
    localStorage.setItem('extra_ic_fields',
      JSON.stringify({ fields: newExtraFields, version: store.getters['app/version'] }))
  },

  setCurrentInventionConcept(state, newInventionConcept) {
    state.currentInventionConcept = newInventionConcept
  }
}

const storeActions = {
  /**
   * Get the Transitions matrix for the invention concept
   * Fetch it from the API if not fetched yet.
   */
  async getIcTransitionMatrix({ commit, getters }) {
    const transitionMatrix = getters.transitionMatrix
    if (transitionMatrix) return transitionMatrix
    const res = (await getInventionConceptTransitions()).data
    commit('setTransitionMatrix', res)
    return res
  },

  /**
   * Get the json with the extra fields for the invention concept
   * Fetch it from the API if not fetched yet.
   */
  async getIcExtraFields({ commit, getters }) {
    let extraFields = getters.extraFields
    if (extraFields) return extraFields

    const localExtraFields = JSON.parse(localStorage.getItem('extra_ic_fields'))
    if (localExtraFields && localExtraFields.version === store.getters['app/version']) {
      extraFields = localExtraFields.fields
    } else {
      extraFields = (await getInventionConceptExtraFields()).data
    }

    commit('setExtraFields', extraFields)
    return extraFields
  },

  /**
   * Fetch Invention concept details
   */
  async fetchInventionConcept({ commit }, icId) {
    const res = (await getInventionConcept(icId)).data
    res.invention_workbook_blocks_ids = res.invention_workbook_blocks.map(block => block.id)
    res.owner_id = res.owner.id
    commit('setCurrentInventionConcept', res)
    return res
  },

  /**
   * Get the current invention concept details. Fetch it if necessary.
   */
  async getCurrentInventionConcept({ dispatch, getters }, icId) {
    const currentInventionConcept = getters.currentInventionConcept
    if (currentInventionConcept?.id === icId) return currentInventionConcept
    return dispatch('fetchInventionConcept', icId)
  },

  async setInventionConcept({ commit }, inventionConcept) {
    inventionConcept.invention_workbook_blocks_ids = inventionConcept.invention_workbook_blocks.map(block => block.id)
    inventionConcept.owner_id = inventionConcept.owner.id
    commit('setCurrentInventionConcept', inventionConcept)
  },

  clearCurrentInventionConcept({ commit }) {
    commit('setCurrentInventionConcept')
  }
}

export default {
  namespaced: true,

  state: {
    transitionMatrix: null,
    extraFields: null,
    currentInventionConcept: null
  },

  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions
}
