/**
 * Tracked Triggers store
 */
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { deleteTrackedTrigger, getTrackedTriggers } from '../../../api/trackedTriggers';
import { useAsyncState } from '../../../utils/asyncState';
import { projectsStore } from '../../projects/store';
import sleep from '../../../utils/sleep';
export const useTrackedTriggersStore = defineStore('trackedTriggers', () => {
    const projectId = ref();
    const trackedTriggers = useAsyncState(async () => (await getTrackedTriggers({ project_id: projectId.value })).data, [], { allowParallel: false, debounceMs: 100 });
    async function fetchTrackedTriggers(newProjectId) {
        projectId.value = newProjectId ?? projectsStore().currentProjectId;
        if (!projectId.value)
            return;
        await trackedTriggers.execute();
        let anyTrackedTriggerWithoutLocation;
        // wait for the triggers to be set into the trackedTriggers state
        setTimeout(async () => {
            anyTrackedTriggerWithoutLocation = trackedTriggers.state.value
                .some((trackedTrigger) => !trackedTrigger.location_x || !trackedTrigger.location_y);
            // poll until all triggers have a location
            if (anyTrackedTriggerWithoutLocation) {
                while (anyTrackedTriggerWithoutLocation) {
                    await sleep(3000);
                    await trackedTriggers.execute();
                    anyTrackedTriggerWithoutLocation = trackedTriggers.state.value
                        .some((trackedTrigger) => !trackedTrigger.location_x || !trackedTrigger.location_y);
                }
            }
        }, 1000);
    }
    async function removeTrackedTrigger(id) {
        await deleteTrackedTrigger(id);
        await fetchTrackedTriggers();
    }
    return {
        trackedTriggers: trackedTriggers.state,
        fetchTrackedTriggers,
        removeTrackedTrigger
    };
});
