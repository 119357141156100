import { defineStore } from 'pinia';
import Vue, { computed, ref, watch } from 'vue';
import { projectsStore } from '../../projects/store';
import { createInventionWorkbook, deleteInventionWorkbook, duplicateInventionWorkbook, getInventionWorkbook, getInventionWorkbooks, patchInventionWorkbook } from '../../../api/inventionWorkbooks';
import store from '@/store';
import { useRouter } from 'vue-router/composables';
import { getPreference, setPreference } from '../../../utils/preferencesManager';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { assign } from 'lodash';
export const useCanvasStore = defineStore('canvas', () => {
    const canvasPerProject = ref({});
    const currentCanvasIndex = ref(undefined);
    const availableCanvases = ref([]);
    const loading = ref(false);
    const projectStore = projectsStore();
    const currentProjectId = computed(() => projectsStore().currentProjectId || '');
    const teamCanvasTitle = computed(() => `Team Canvas - ${projectStore.currentProject?.name ?? ''}`);
    const canvasses = computed(() => {
        return canvasPerProject.value[currentProjectId.value] || [];
    });
    const router = useRouter();
    const getCanvasesModifiedData = (result) => {
        const teamCanvases = result.filter(iwb => iwb.is_team_canvas).map(iwb => ({ ...iwb, title: teamCanvasTitle.value }));
        const otherCanvases = result.filter(iwb => !iwb.is_team_canvas);
        return [...teamCanvases, ...otherCanvases];
    };
    async function fetchCanvassesForProject() {
        if (loading.value)
            return;
        const projectId = currentProjectId.value;
        if (!projectId) {
            console.error('No project selected');
            return;
        }
        try {
            loading.value = true;
            const result = await getInventionWorkbooks({ project: projectId });
            const canvasesResults = getCanvasesModifiedData(result.data);
            Vue.set(canvasPerProject.value, projectId, canvasesResults);
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'There was an error while trying to fetch the workbooks.'
            });
        }
        finally {
            loading.value = false;
        }
    }
    function openCanvas() {
        window.open(`/workbook/${currentProjectId.value}/`, 'Canvas', 'width=1200,height=800');
    }
    const currentCanvas = computed(() => {
        if (currentCanvasIndex.value === undefined)
            return undefined;
        return availableCanvases.value[currentCanvasIndex.value];
    });
    function loadCanvas(iwb) {
        const currentOpenIndex = availableCanvases.value.findIndex(x => x.uuid === iwb.uuid);
        if (currentOpenIndex < 0) {
            availableCanvases.value = [iwb, availableCanvases.value[0], availableCanvases.value[1]].filter(x => x !== undefined);
            currentCanvasIndex.value = 0;
        }
        else {
            currentCanvasIndex.value = currentOpenIndex;
        }
        setPreference('INVENTION_WORKBOOKS_POPOVER_SETTINGS', {
            currentCanvas: currentCanvasIndex.value,
            openWorkbooks: availableCanvases.value.map(x => x.uuid)
        }, { projectId: currentProjectId.value });
        if (!isEqual(router.currentRoute.params.canvasId, iwb.uuid)) {
            return router.replace({
                name: 'workbook-new-window',
                params: { projectId: currentProjectId.value, canvasId: iwb.uuid }
            });
        }
    }
    const createNewCanvas = async (title) => {
        try {
            const result = await createInventionWorkbook({
                title,
                project: currentProjectId.value
            });
            await loadCanvas(result.data);
            await fetchCanvassesForProject();
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'There was an error while trying to create the Canvas.'
            });
        }
    };
    async function patchCanvas(workbook, data, failureMessage, successMessage) {
        try {
            const result = await patchInventionWorkbook(workbook.uuid, data);
            assign(workbook, result.data);
            // if the workbook is open as a tab, then it's in the availableCanvases, and it needs to be updated too
            const workbookTab = availableCanvases.value.find(x => x.uuid === workbook.uuid);
            if (workbookTab)
                assign(workbookTab, result.data);
            if (successMessage) {
                store.commit('snackbar/show', {
                    color: 'success',
                    message: successMessage
                });
            }
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: failureMessage
            });
        }
        await fetchCanvassesForProject();
    }
    function closeCanvas(index) {
        if (currentCanvasIndex.value === undefined)
            return;
        availableCanvases.value.splice(index, 1);
        if (availableCanvases.value.length === 0)
            currentCanvasIndex.value = undefined;
        else if (currentCanvasIndex.value >= availableCanvases.value.length)
            currentCanvasIndex.value = availableCanvases.value.length - 1;
    }
    async function deleteCanvas(canvasId) {
        try {
            await deleteInventionWorkbook(canvasId);
            store.commit('snackbar/show', {
                color: 'success',
                message: 'Successfully deleted the Canvas'
            });
            availableCanvases.value = availableCanvases.value.filter(iwb => iwb.uuid !== canvasId);
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'There was an error while trying to delete the Canvas'
            });
        }
        await fetchCanvassesForProject();
    }
    async function duplicateCanvas(canvasId, data) {
        try {
            const result = await duplicateInventionWorkbook(canvasId, data);
            store.commit('snackbar/show', {
                color: 'success',
                message: 'Canvas successfully created',
                extraActions: [{
                        title: 'Open Canvas',
                        action: () => loadCanvas(result.data)
                    }]
            });
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'There was an error while trying to create the Canvas'
            });
        }
        await fetchCanvassesForProject();
    }
    const getCanvasById = async (canvasId) => {
        try {
            const res = await getInventionWorkbook(canvasId);
            return res.data.is_team_canvas ? { ...res.data, title: teamCanvasTitle.value } : res.data;
        }
        catch (e) {
            store.commit('snackbar/show', {
                color: 'error',
                message: 'Failed to fetch workbook! Please try again.'
            });
        }
    };
    const loadCanvasSettings = async () => {
        const prefs = await getPreference('INVENTION_WORKBOOKS_POPOVER_SETTINGS', { projectId: currentProjectId.value });
        if (!prefs)
            return;
        const { currentCanvas, openWorkbooks } = prefs;
        currentCanvasIndex.value = currentCanvas;
        availableCanvases.value = canvasses.value.filter(iwb => (openWorkbooks ?? []).includes(iwb.uuid));
    };
    const debounceCanvasSettings = debounce(async () => {
        const prefs = {
            currentCanvas: currentCanvasIndex.value,
            openWorkbooks: availableCanvases.value.map(iwb => iwb.uuid)
        };
        const currentPrefs = await getPreference('INVENTION_WORKBOOKS_POPOVER_SETTINGS', { projectId: currentProjectId.value });
        if (isEqual(prefs, currentPrefs))
            return;
        return setPreference('INVENTION_WORKBOOKS_POPOVER_SETTINGS', prefs, {
            projectId: currentProjectId.value
        });
    }, 500);
    watch([currentCanvasIndex, availableCanvases], debounceCanvasSettings);
    watch(currentCanvas, (newValue) => {
        const routeParams = {
            projectId: currentProjectId.value,
            canvasId: newValue?.uuid
        };
        if (!isEqual(router.currentRoute.params, routeParams)) {
            return router.replace({
                name: 'workbook-new-window',
                // @ts-ignore
                params: routeParams
            });
        }
    });
    watch(currentProjectId, async (value) => {
        if (!value)
            return;
        await fetchCanvassesForProject();
        const canvasId = router.currentRoute.params.canvasId;
        if (canvasId) {
            const workbook = await getCanvasById(canvasId);
            if (workbook)
                loadCanvas(workbook);
        }
    });
    return {
        canvasPerProject,
        currentProjectId,
        currentCanvasIndex,
        availableCanvases,
        canvasses,
        loading,
        currentCanvas,
        debounceCanvasSettings,
        fetchCanvassesForProject,
        openCanvas,
        loadCanvas,
        closeCanvas,
        patchCanvas,
        deleteCanvas,
        duplicateCanvas,
        createNewCanvas,
        getCanvasById,
        loadCanvasSettings
    };
});
