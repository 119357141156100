import { createDocFromState, defaultMarks, defaultNodes, DocxSerializer, DocxSerializerState, writeDocx } from 'prosemirror-docx';
import { downloadFile } from '../../../utils/export';
import { FILE_TYPES } from '../../../interfaces/exports';
import { ExternalHyperlink } from 'docx';
export function makeWordSerializer() {
    /**
     * Creates a serializer to write a document to. Usage:
     * const serializer = makeSerializer()
     * serializer.renderContent(editor1.doc)
     * serializer.renderContent(editor2.doc)
     * serializer.renderContent(editor3.doc)
     * writeWordToFile(serializer)
     */
    const nodeSerializer = {
        ...defaultNodes,
        hardBreak: defaultNodes.hard_break,
        codeBlock: defaultNodes.code_block,
        orderedList: defaultNodes.ordered_list,
        listItem: defaultNodes.list_item,
        bulletList: defaultNodes.bullet_list,
        horizontalRule: defaultNodes.horizontal_rule,
        trigger(state, node) {
            state.addRunOptions({ style: 'Hyperlink' });
            state.text(node.attrs.triggerTitle);
            state.current[0] = new ExternalHyperlink({
                link: node.attrs.link,
                children: [state.current[0]]
            });
            state.closeBlock();
            const otherChildren = [];
            node.forEach((c) => otherChildren.push(c));
            state.renderList(otherChildren, 'bullets');
            state.closeBlock(node);
        },
        essentialfeature(state, node) {
            state.addParagraphOptions({ numbering: state.currentNumbering });
            state.text(`${node.attrs.eftype}: ${node.textContent}`);
            state.closeBlock(node);
        }
    };
    const serializer = new DocxSerializer(nodeSerializer, defaultMarks);
    const opts = {
        getImageBuffer(src) {
            return Buffer.from(src.split('base64,')[1], 'base64');
        }
    };
    return new DocxSerializerState(serializer.nodes, serializer.marks, opts);
}
export function writeWordToFile(wordDocument, name) {
    return writeDocx(createDocFromState(wordDocument), (buffer) => {
        downloadFile(buffer, FILE_TYPES.DOCX, name);
    });
}
