import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { ideaCreationSpaceStore } from '../../candidate_ideas/store/ideaCreationSpace';
import { ideaRefinementStore } from '../../candidate_ideas/store/ideaRefinement';
import store from '@/store';
export const useCanvasWidgetDrawerStore = defineStore('canvasWidgetDrawerStore', () => {
    const activeWidget = ref(null);
    const icData = ref({
        title: '',
        problem: '',
        concept: ''
    });
    const selectedBlocks = ref([]);
    const mainTrigger = ref(null);
    const supportingTriggers = ref([]);
    const hasActiveWidget = computed(() => Boolean(activeWidget.value));
    const isSaving = ref(false);
    const setActiveWidget = (widget) => {
        if (!widget)
            resetData();
        if (activeWidget.value === widget)
            activeWidget.value = null;
        else
            activeWidget.value = widget;
    };
    const resetData = () => {
        icData.value = {
            title: '',
            problem: '',
            concept: ''
        };
        mainTrigger.value = null;
        supportingTriggers.value = [];
        selectedBlocks.value = [];
    };
    const saveIc = async () => {
        if (isSaving.value)
            return;
        try {
            isSaving.value = true;
            const allTriggers = [...supportingTriggers.value];
            if (mainTrigger.value)
                allTriggers.push(mainTrigger.value);
            const idea = await ideaCreationSpaceStore().createIdeaFromCanvas(icData.value);
            if (idea) {
                if (allTriggers.length)
                    await ideaRefinementStore().addTriggersToIdeaFromCanvas(idea, allTriggers);
                await ideaRefinementStore().saveAsInventionConceptFromCanvas(idea, selectedBlocks.value.map(block => block.id));
                resetData();
                store.commit('snackbar/show', {
                    message: 'Successfully saved Invention Concept',
                    color: 'success'
                });
            }
            setActiveWidget(null);
        }
        catch (e) {
            store.commit('snackbar/show', {
                message: 'Failed to save Invention Concept',
                color: 'error'
            });
        }
        finally {
            isSaving.value = false;
        }
    };
    return {
        activeWidget,
        hasActiveWidget,
        icData,
        mainTrigger,
        supportingTriggers,
        isSaving,
        selectedBlocks,
        saveIc,
        setActiveWidget
    };
});
