import _ from 'lodash'
import Vue from 'vue'
import { differenceBy } from 'lodash/array'

function replaceObject(arrayToPatch, newElem, indexToPatch, schemaId) {
  // There is only an ID, we replace it
  if (indexToPatch < 0 && newElem === undefined) {
    // It doesn't exist anywhere, we probably don't want to do anything
    console.warn(`A schema tried to patch a value in an array that exist neither in the
        arrayToPatch or the newArray. Maybe it is a problem with your schema?
        Id not found: ${schemaId}`)
    return
  }
  // The elem doesn't exist in the new array to patch, we push it
  if (indexToPatch < 0) arrayToPatch.push(newElem)
  // The elem doesn't exist anymore in the newArray, we remove it
  else if (newElem === undefined) arrayToPatch.splice(indexToPatch, 1)
  // Both elem exist, we replace it
  else Vue.set(arrayToPatch, indexToPatch, newElem)
}

/**
 * Patch an array specifically
 * @param {Array} arrayToPatch: The array to patch
 * @param {Array} newArray: The array that contain the new data that are going to be patch
 * @param {Array} schema: The schema on how to patch the array
 */

function getArrayIndexes(arrayToPatch, newArray, subSchema) {
  let indexToPatch, newArrIdx
  if (Object.hasOwn(subSchema, 'id')) {
    indexToPatch = arrayToPatch.findIndex(i => i.id === subSchema.id)
    newArrIdx = newArray.findIndex(i => i.id === subSchema.id)
  } else if (Object.hasOwn(subSchema, 'uuid')) {
    indexToPatch = arrayToPatch.findIndex(i => i.uuid === subSchema.uuid)
    newArrIdx = newArray.findIndex(i => i.uuid === subSchema.uuid)
  } else {
    throw new Error('Every object in a schema array should have an ID or UUID in order to identify it!')
  }
  return [indexToPatch, newArrIdx]
}

function patchArray(arrayToPatch, newArray, schema, removeMissingArrayItems) {
  // remove the elements that don't exist anymore (ie: were deleted in newArray)
  // to do that add them to the schema just with the id.
  if (removeMissingArrayItems) {
    const missingElems = differenceBy(arrayToPatch, newArray, 'id')
    missingElems.forEach(missingEl => {
      if (schema.findIndex(s => s.id === missingEl.id) === -1) schema.push({ id: missingEl.id })
    })
    // if the schema is empty the arrayToPatch will not be modified
    if (schema.length === 0) return
  }

  if (schema.length === 0) {
    throw new Error("Array in schemas shouldn't be empty! If you want to " +
      "replace it, please use the 'true' value")
  }
  for (const subSchema of schema) {
    if (Array.isArray(subSchema)) {
      throw new Error("Doesnt't handle the Array of arrays type!")
    } else if (_.isObject(subSchema)) {
      const [indexToPatch, newArrIdx] = getArrayIndexes(arrayToPatch, newArray, subSchema)
      if (Object.keys(subSchema).length > 1 && indexToPatch > -1) {
        // There is multiple properties to the subSchema object, we continue to patch it
        patch(arrayToPatch[indexToPatch], newArray[newArrIdx], subSchema, removeMissingArrayItems, `[${newArrIdx}]`)
        continue
      }

      // There is only an ID, we replace it
      replaceObject(arrayToPatch, newArray[newArrIdx], indexToPatch, schema.Id)
    } else {
      throw new Error(`Schema shouldn't have an array of primitive type! Found: ${typeof subSchema}`)
    }
  }
}

/**
 * Patch an plain object specifically
 * @param {Object} objectToPatch: The object to patch
 * @param {Object} newObject: The object that contain the new data that are going to be patch
 * @param {Object} schema: The schema on how to patch the object
 */
function patchObject(objectToPatch, newObject, schema, removeMissingArrayItems) {
  for (const [key, value] of Object.entries(schema)) {
    if (key === 'id' || key === 'uuid') continue
    if (value === true) {
      Vue.set(objectToPatch, key, newObject[key])
    } else {
      patch(objectToPatch[key], newObject[key], value, removeMissingArrayItems, key)
    }
  }
}

/**
 * Patch an object in a reactive way with the right VueJs functions.
 * @param {Object|Array} objectToPatch: Any array or bject that need to be patch
 * @param {Object|Array} newObject: Any array or object that contain the new datas
 * @param {Object|Array} schema: A schema that represent the way the object should be patch
 * @param {Boolean} removeMissingArrayItems: optional, if set to true will remove items that exist in the
 *  arrayToPatch but not in the newArray
 * @example
 * const objectToPatch = [
 *  {
 *    id: 1,
 *    name: "Kid cat"
 *    race: "cat"
 *    father: {
 *      id: 2,
 *      name: "Mr cat",
 *      brothers: [
 *        {
 *          id: 3,
 *          name: "Uncle 1 cat"
 *        },
 *        {
 *          id: 4,
 *          name: "Uncle 2 cat"
 *        }
 *      ]
 *    },
 *    mother: {...}
 *  },
 *  {...}
 * ]
 *
 * // The newObject should have the same structure than the objectToPatch but with the updated
 * // datas
 * const newObject = {...}
 *
 * // There is some rules for the schema:
 * // 1. Arrays need to contain only objects with an 'id' attribute (In order to identify them)
 * // 2. If you want to update any attribute completely, just set the attribute to 'true'
 * // 3. In an array, if the object only have an 'id' attribute, it will be replace
 * // 4. If an 'id' is specified in an array that exist in the objectToUpdate but not in the
 * // newObject, it will be delete
 * // 5. If an 'id' is specified in an array that exist in the newObject but not in the
 * // objectToUpdate, it will be push
 *
 * // With the following schema, we are going to update the followings:
 * // 1. The name of the cat with ID 1
 * // 2. The complete object of the cat with ID 3
 * // 3. The name of the cat with ID 4
 * // All other attributes are going to stay the same
 * const schema = [
 *  {
 *    id: 1,
 *    name: true,
 *    father: {
 *      brothers: [
 *        { id: 3 },
 *        { id: 4, name: true }
 *      ]
 *    }
 *  }
 * ]
 *
 */
export function patch(objectToPatch, newObject, schema, removeMissingArrayItems = false, key = '') {
  if (!objectToPatch || !newObject || !schema) {
    const err = new Error()
    err.name = 'objectUndefined'
    if (!objectToPatch) err.errObj = 'objectToPatch'
    else err.errObj = !newObject ? 'newObject' : 'schema'
    err.key = [key]
    err.message = `There is an issue with the object ${err.errObj} in the ObjectPatcher: #firstCall`
    throw err
  }
  try {
    if (objectToPatch.constructor !== newObject.constructor ||
      objectToPatch.constructor !== schema.constructor) {
      throw new Error('objectToPatch, newObject and schema are not of the same type!')
    }
    if (Array.isArray(schema)) {
      patchArray(objectToPatch, newObject, schema, removeMissingArrayItems)
    } else if (_.isPlainObject(schema)) {
      patchObject(objectToPatch, newObject, schema, removeMissingArrayItems)
    } else {
      // This is a primitive type
      throw new Error("Doesn't allow primitive type in schema")
    }
  } catch (e) {
    if (e.name === 'objectUndefined') {
      const err = new Error()
      err.name = e.name
      err.errObj = e.errObj
      err.key = e.key.concat([key])
      err.message = `There is an issue with the object ${err.errObj} in the ObjectPatcher:${[...err.key].reverse().join(' -> ')}`
      throw err
    }
    throw e
  }
}
