import { defineStore, storeToRefs } from 'pinia';
import { ref, reactive, computed } from 'vue';
import { useFlagsStore } from '../../../store/modules/flags';
export const useExploreTriggersStore = defineStore('explore-triggers-store', () => {
    const tableAtlasToggle = ref(false);
    const flagsStore = useFlagsStore();
    const { OPPORTUNITY_ATLAS_ENABLED } = storeToRefs(flagsStore);
    const oppAtlasActiveView = computed(() => tableAtlasToggle.value && OPPORTUNITY_ATLAS_ENABLED.value);
    const state = ref({ tableAtlasToggle, oppAtlasActiveView });
    const actions = reactive({});
    return { state, actions };
});
