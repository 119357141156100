import { FIELD_TYPE, FIELDS_PER_IC_STATUS } from '../../../utils/inventionConceptFields';
import { IC_STATUS } from '../../../utils/constants';
export const VALIDATION_DIALOG_ITEMS = {
    can_publish: [{ type: FIELD_TYPE.COMPONENT, name: 'InventionConceptRatingUI' }],
    has_reason_to_rework: FIELDS_PER_IC_STATUS[IC_STATUS.REWORK],
    has_reason_to_hold: FIELDS_PER_IC_STATUS[IC_STATUS.ONHOLD],
    has_reason_to_stop: FIELDS_PER_IC_STATUS[IC_STATUS.STOP],
    has_accepted_date: FIELDS_PER_IC_STATUS[IC_STATUS.ACCEPTED]
};
export const EXPORT_FIELDS = ['created_at', 'title', 'concept', 'problem', 'owner', 'status', 'tracked_triggers', 'must_not_match'];
export const mockTransitions = {
    NEW: {
        REVIEW: true,
        NEW: true,
        CUSTOM: true
    },
    CUSTOM: {
        NEW: true
    },
    REVIEW: {
        REWORK: true,
        IO: true,
        ONHOLD: true,
        STOP: true,
        REVIEW: true
    },
    REWORK: {
        RaREW: true,
        STOP: true,
        REWORK: true
    },
    ONHOLD: {
        REWORK: true,
        IO: true,
        STOP: true,
        ONHOLD: true
    },
    RaREW: {
        REWORK: true,
        IO: true,
        ONHOLD: true,
        STOP: true,
        RaREW: true
    },
    IO: {
        USED: true,
        ONHOLD: true,
        STOP: true,
        IO: true
    },
    USED: {
        ACCPTD: true,
        REJCTD: true,
        USED: true
    },
    STOP: {
        REWORK: true,
        STOP: true
    },
    ACCPTD: {
        ACCPTD: true
    },
    REJCTD: {
        REJCTD: true
    }
};
export const mockComments = [
    {
        content: 'test',
        created_at: '2021-08-05T14:52:00.000000Z',
        created_by: {
            id: '123',
            username: 'foo'
        },
        id: 1,
        invention_concept_id: 1,
        updated_at: '2021-08-05T14:52:00.000000Z'
    },
    {
        content: 'test2',
        created_at: '2021-08-05T14:52:00.000000Z',
        created_by: {
            id: '123',
            username: 'foo'
        },
        id: 2,
        invention_concept_id: 1,
        updated_at: '2021-08-05T14:52:00.000000Z'
    }
];
