import http from '../plugins/http';
const WORKBOOKS_ENDPOINT = 'projects/workbooks/invention-workbooks';
const BLOCKS_ENDPOINT = 'projects/workbooks/blocks';
export function getInventionWorkbooks(params) {
    return http.get(WORKBOOKS_ENDPOINT, { params });
}
export function getInventionWorkbook(id) {
    return http.get(`${WORKBOOKS_ENDPOINT}/${id}/`);
}
export function createInventionWorkbook(data) {
    return http.post(WORKBOOKS_ENDPOINT, data);
}
export function duplicateInventionWorkbook(inventionWorkbookId, data) {
    return http.post(`${WORKBOOKS_ENDPOINT}/${inventionWorkbookId}/duplicate/`, data);
}
export function patchInventionWorkbook(inventionWorkbookId, data) {
    return http.patch(`${WORKBOOKS_ENDPOINT}/${inventionWorkbookId}/`, data);
}
export function deleteInventionWorkbook(inventionWorkbookId) {
    return http.delete(`${WORKBOOKS_ENDPOINT}/${inventionWorkbookId}/`);
}
export function getWorkbookBlock(blockId) {
    return http.get(`${BLOCKS_ENDPOINT}/${blockId}/`);
}
export function getWorkbookBlocks(inventionWorkbookId) {
    return http.get(`${BLOCKS_ENDPOINT}/`, { params: { invention_workbook: inventionWorkbookId } });
}
export function createWorkbookBlock(data) {
    return http.post(`${BLOCKS_ENDPOINT}/`, data);
}
export function patchWorkbookBlock(blockId, data, config) {
    return http.patch(`${BLOCKS_ENDPOINT}/${blockId}/`, data, config);
}
export function deleteWorkbookBlock(blockId) {
    return http.delete(`${BLOCKS_ENDPOINT}/${blockId}/`);
}
