import Vue from 'vue'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import { appStore } from '@/store/modules/app'
import { useConfigStore } from '@/store/modules/configuration'

export function initRum(router) {
  const configStore = useConfigStore()
  configStore.fetchConfigs().then(() => {
    if (configStore.RUM_SERVER_URL && process.env.NODE_ENV === 'production') {
      const CLIENT = configStore.CLIENT
      const ENV = configStore.ENVIRONMENT
      const VERSION = configStore.INVENTION_STUDIO_VERSION
      const vue = Vue.use(ApmVuePlugin, {
        router,
        config: {
          serviceName: 'invention-studio-spa',
          serviceVersion: VERSION,
          serverUrl: configStore.RUM_SERVER_URL,
          environment: ENV
        }
      })
      vue.prototype.$apm.addLabels({
        organization_id: CLIENT,
        organization_name: CLIENT && (CLIENT[0].toUpperCase() + CLIENT.slice(1)),
        service_id: `invention-studio-spa_env-${CLIENT}-${ENV}`,
        service_version: VERSION
      })
      appStore().isRUMActive = true
    }
  })
}
