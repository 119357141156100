/**
 * Explore Standards Triggers store
 */
import { defineStore } from 'pinia';
import { makeTriggerSearchStore } from '../../../utils/makeTriggerSearchStore';
const config = {
    include3GPPLiterature: true,
    isStandardsModule: true
};
export const useStandardsTriggerListStore = defineStore('standardsTriggersList', makeTriggerSearchStore(config));
