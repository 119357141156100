import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

const iprovaDark = {
  base: '#172345',
  lighten1: '#42516a',
  lighten2: '#6d788a',
  lighten3: '#979fac',
  lighten4: '#c5cbd2',
  lighten5: '#e1e3e6'
}

const iprovaLight = {
  base: '#1DAFED',
  lighten1: '#40c2ee',
  lighten2: '#66cef1',
  lighten3: '#97d9f2',
  lighten4: '#c6e9f5',
  lighten5: '#e0f3f9'
}

const iprovaAccent = {
  base: '#EC1414',
  lighten1: '#ed5153',
  lighten2: '#f08181',
  lighten3: '#f49c9d',
  lighten4: '#f9c8c7',
  lighten5: '#fce2e1'
}

export default new Vuetify({
  theme: {
    dark: window.matchMedia?.('(prefers-color-scheme: dark)')?.matches,
    themes: {
      light: {
        primary: iprovaDark,
        secondary: iprovaLight,
        accent: iprovaAccent,
        iprovaLight,
        iprovaPrimary: iprovaLight,
        iprovaDark
      },
      dark: {
        primary: iprovaLight,
        secondary: iprovaDark,
        accent: iprovaAccent,
        iprovaLight,
        iprovaPrimary: iprovaDark.lighten2,
        iprovaDark
      }
    }
  }
})
