import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from './modules/snackbar'
import inventionConcept from '@/legacy_modules/inventionConcept/store'
import kpis from '@/legacy_modules/kpis/store'
import scoping from '@/legacy_modules/scoping/store'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    kpis,
    inventionConcept,
    snackbar,
    scoping
  }
})
