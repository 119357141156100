export default [
  {
    path: '/project/:project_id/opportunityatlas',
    name: 'problemmining-home',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "problemmining-home" */ '@/legacy_modules/scoping/views/ScopingHome.vue'),
    props: { nextView: 'problemmining-feed' }
  },
  {
    path: '/project/:project_id/opportunityatlas/:feed_id',
    name: 'problemmining-feed',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "project-problemmining" */ '@/legacy_modules/problemMining/views/ProblemMining.vue')
  }
]
