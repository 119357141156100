export default [
  {
    path: '/system-configuration',
    name: 'system-configuration',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "management" */ '@/legacy_modules/management/layouts/SystemConfigurationLayout')
  },
  {
    path: '/system-configuration/users',
    name: 'users',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "users" */ '@/legacy_modules/management/layouts/SystemConfigurationLayout')
  },
  {
    path: '/system-configuration/criteria-management',
    name: 'criteria-management',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "criteria-management" */ '@/legacy_modules/management/layouts/SystemConfigurationLayout')
  },
  {
    path: '/system-configuration/criteria-sets-management',
    name: 'criteria-sets-management',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "criteria-sets-management" */ '@/legacy_modules/management/layouts/SystemConfigurationLayout')
  }
]
