/**
 * Explore Triggers store
 */
import { defineStore } from 'pinia';
import { makeTriggerSearchStore } from '../../../utils/makeTriggerSearchStore';
const config = {
    include3GPPLiterature: false,
    isStandardsModule: false
};
export const useTriggersListStore = defineStore('triggersList', makeTriggerSearchStore(config));
