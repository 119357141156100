<template lang="pug">
  v-app(id="invention-studio")
    div.mx-8.my-6
      div(v-if="configLoadingError")
        critical-error(errorMsg="The service is currently unavailable. Please check back shortly. If the problem persists, please contact our Technical Support team.")
      Login(v-else-if="!hasUser")
      template(v-else)

        v-navigation-drawer(v-model="navigationOpen" color="iprovaDark" dark fixed temporary)
          v-list(dense nav)
            v-list-item
              v-list-item-avatar.me-6(size="40")
                v-gravatar(:email="auth.profile.email" class="v-avatar")
              v-list-item-content
                v-list-item-title {{ auth.profile.first_name }} {{ auth.profile.last_name }}
            v-divider
            v-list-item
              v-list-item-action.my-1.me-4
                v-switch(color="#1DAFED" inset v-model="darkMode" @change="changeTheme")
              v-list-item-content
                v-list-item-title Dark mode
            v-divider
            v-list-item(:to="{ name: 'dashboard' }")
              v-list-item-icon.ms-2
                v-icon mdi-monitor-dashboard
              v-list-item-content
                v-list-item-title Dashboard
            v-divider
            v-list-item(:to="legacyLink" v-if="LEGACY_ACCESS_ENABLED")
              v-list-item-icon.ms-2
                v-icon mdi-rocket
              v-list-item-content
                v-list-item-title Invention Studio
            v-divider
            v-list-item(href="https://iprova-jira.atlassian.net/servicedesk/customer/portal/2" target="_blank" tile)
              v-list-item-icon.ms-2
                v-icon mdi-comment-quote-outline
              v-list-item-content
                v-list-item-title Give Feedback
            v-divider
            v-list-item(href="https://iprova-jira.atlassian.net/servicedesk/customer/portal/2/article/2281373697" target="_blank" tile)
              v-list-item-icon.ms-2
                v-icon mdi-help-circle-outline
              v-list-item-content
                v-list-item-title User Guide
            v-divider
            v-list-item(@click="logout()" tile)
              v-list-item-icon.ms-2
                v-icon mdi-power
              v-list-item-content
                v-list-item-title Logout

        v-row.flex-grow-0
          v-col(cols="0")
            span.text-h4.text-no-wrap
              v-icon.ml-3.mr-1.mb-1(
                size="36"
                color="primary"
                @click="navigationOpen = true"
              ) mdi-menu
              span.ml-3 {{ $route.meta.title }}
          v-col(cols="0")
            RouterLink(:to="{ name: 'dashboard' }" :class="['iprova-icon', 'iprova-normal-icon', currentTheme]")
          slot(name="search")
          v-btn.mr-5.mt-4(
            icon
            v-show="hasHelpsToShow"
            :disabled="!hasDismissedHelps"
            @click="showHelps()"
          )
            v-icon mdi-help-circle-outline
        v-divider.mt-2

        v-main(:class="[currentTheme, $router.currentRoute.name]")
          slot(name="default")

        canvas-popover(
          v-if="currentProject"
          v-show="showPopover"
          :isShown="showPopover"
        )

        main-snackbar
</template>

<script>
import MainSnackbar from '@/components/MainSnackbar.vue'
import { getPreference, setPreference } from '@/utils/preferencesManager'
import Login from '@/modules/auth/views/Login'
import { THEMES } from '@/utils/constants'
import { loadLanguageAsync } from '@/plugins/i18n'
import { authStore } from '@/store/modules/auth'
import { projectsStore } from '@/modules/projects/store'
import { appStore } from '@/store/modules/app'
import { notificationStore } from '@/store/modules/notifications'
import { mapState } from 'pinia'
import CanvasPopover from '../modules/canvas/views/CanvasPopover'
import { useDismissibleHelpStore } from '@/components/store/dismissibleHelpStore'
import { useFlagsStore } from '@/store/modules/flags'
import { useConfigStore } from '@/store/modules/configuration'
import CriticalError from '@/components/CriticalError.vue'

export default {
  components: {
    CriticalError,
    MainSnackbar,
    Login,
    CanvasPopover
  },
  inject: ['currentTheme'],

  data: () => ({
    today: new Date(),
    navigationOpen: false,
    darkMode: false
  }),

  computed: {
    ...mapState(authStore, ['displayName']),
    ...mapState(projectsStore, ['currentProject', 'currentProjectId']),
    ...mapState(useFlagsStore, ['LEGACY_ACCESS_ENABLED', 'INVENTION_TRACKER_ENABLED', 'OPPORTUNITY_ATLAS_ENABLED']),
    hasUser() {
      return Boolean(this.auth.displayName)
    },
    configLoadingError() {
      return Boolean(useConfigStore().error)
    },
    viewTitle() {
      try {
        return this.$route.matched[0].instances?.default?.viewTitle || ''
      } catch (e) {
        // ignore
        return ''
      }
    },
    showPopover() {
      return Boolean(this.currentProject && this.$route.meta.hideCanvasPopover !== true)
    },
    version() {
      return this.OPPORTUNITY_ATLAS_ENABLED ? 'v7.0' : 'v6.6'
    },
    hasHelpsToShow() {
      return useDismissibleHelpStore().availableHelp.length > 0
    },
    hasDismissedHelps() {
      return useDismissibleHelpStore().dismissedHelp.length > 0
    },
    legacyLink() {
      return this.currentProjectId
        ? { name: 'legacy-project', params: { project_id: this.currentProjectId } }
        : { name: 'legacy-dashboard' }
    }
  },

  watch: {
    async hasUser(newValue) {
      if (newValue) {
        notificationStore().startLiveFetchNotifications()
        await projectsStore().getCurrent()
        if (this.$route.name === 'login') {
          const afterLogin = localStorage.getItem('afterLogin') || { name: 'home' }
          localStorage.removeItem('afterLogin')
          await this.$router.replace(afterLogin)
        }
        const userTheme = (await getPreference('CURRENT_THEME')) || THEMES.AUTO
        let effectiveTheme = userTheme
        if (userTheme === THEMES.AUTO) {
          effectiveTheme = window.matchMedia?.('(prefers-color-scheme: dark)')?.matches
            ? THEMES.DARK
            : THEMES.LIGHT
        }
        this.$vuetify.theme.isDark = effectiveTheme === THEMES.DARK
        this.$vuetify.theme.applyTheme()
      }
    },
    async $route(newRoute, oldRoute) {
      const oldProjectId = oldRoute?.params?.project_id
      const newProjectId = newRoute?.params?.project_id
      if (oldProjectId === newProjectId) return
      if (newProjectId === undefined) projectsStore().currentProject = null
      else await projectsStore().fetchCurrent(newProjectId)
    },
    viewTitle(newValue) {
      window.document.title = newValue || 'Invention Studio'
    }
  },
  beforeCreate() {
    this.auth = authStore()
  },
  async created() {
    this.setDate()

    await Promise.all([
      loadLanguageAsync(),
      this.loadPreferences()
    ])
    this.darkMode = await getPreference('CURRENT_THEME') === THEMES.DARK || this.$vuetify.theme.isDark

    notificationStore().startLiveFetchNotifications()
    this.setupLeaveGuard()
  },

  methods: {
    setDate() {
      const updateToday = () => {
        this.today = new Date()
      }
      setTimeout(() => {
        updateToday()
        setInterval(updateToday, 24 * 3600 * 1000)
      }, new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1) - this.today)
    },
    async loadPreferences() {
      await this.auth.fetchProfile()
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', async event => {
        const userTheme = (await getPreference('CURRENT_THEME')) || THEMES.AUTO
        if (userTheme === THEMES.AUTO) {
          this.$vuetify.theme.isDark = event.matches
          this.$vuetify.theme.applyTheme()
        }
      })
    },
    async changeTheme() {
      if (this.darkMode) {
        await setPreference('CURRENT_THEME', THEMES.DARK)
        this.$vuetify.theme.isDark = true
        this.$vuetify.theme.applyTheme()
      } else {
        await setPreference('CURRENT_THEME', THEMES.LIGHT)
        this.$vuetify.theme.isDark = false
        this.$vuetify.theme.applyTheme()
      }
    },
    showHelps() {
      useDismissibleHelpStore().clearDismissed()
    },
    async logout() {
      await this.auth.logout()
      await this.$router.push({ name: 'login' })
    },
    setupLeaveGuard() {
      // Required to handle save changes confirmation on browser page reload.
      // The message specified bellow will be shown on older browsers,
      // new browsers will display their own generic message which cannot be overwritten
      window.addEventListener('beforeunload', (e) => {
        if (appStore().hasPendingChanges) {
          e.preventDefault()
          // chrome requires returnValue to be set
          const message = 'You have unsaved changes.\n\n' +
            'Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">

.iprova-icon {
  scale: 0.6;
  transform-origin: left;
  width: 250px;
  height: 60px;
  display: block;
  background-position: top left;
  background-size: contain;
  margin: -7px 0px -8px 0px;
  background-image: url(resources/invention-studio-evo-logo.png);
}

.v-list-item {
  margin-bottom: 0 !important;

  &::before {
    border-radius: 0 !important;
  }
}

.v-card {
  overflow: auto;
}

.v-toolbar__content {
  max-width: 1845px;
  margin: 0 auto;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.theme--light.v-btn.elevation-0:not(.v-btn--text):not(.v-btn--outlined) {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-btn.elevation-0:not(.v-btn--text):not(.v-btn--outlined) {
  border: thin solid rgba(255, 255, 255, 0.12);
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: none;
}

.gutter.gutter {
  background-color: rgba(128, 128, 128, 0.3)
}

.v-pagination.theme--light button {
  border-radius: 0;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.v-pagination.theme--dark button {
  border-radius: 0;
  border: thin solid rgba(255, 255, 255, 0.12);
  box-shadow: none;
}

[tile][tile][tile][tile][tile] {
  border-radius: 0;

  &::before, &::after {
    border-radius: 0;
  }
}

.v-expansion-panel {
  border: thin solid rgba(128, 128, 128, 0.3);
}

.v-expansion-panel::before {
  display: none;
}

.problemmining-feed {
  overflow: unset;
}

span.search-highlight {
  background-color: rgba(255, 255, 0, 0.4);
  font-weight: bolder;
  white-space: nowrap;
}

.iprova-normal-icon.theme--light {
  background-image: url(resources/invention-studio-evo-logo.png);
}

.iprova-normal-icon.theme--dark {
  background-image: url(resources/invention-studio-evo-logo-dark-mode.png);
}
</style>
<style lang="scss">
.d-contents {
  display: contents;
}
</style>
