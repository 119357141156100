<template lang="pug">
  v-app(id="invention-studio")
    div(v-if="configLoadingError")
      critical-error(errorMsg="The service is currently unavailable. Please check back shortly. If the problem persists, please contact our Technical Support team.")
    Login(v-else-if="!hasUser")
    template(v-else)
      v-navigation-drawer(
        app
        dark
        color="iprovaDark"
        :mini-variant="menuCollapsed"
        mobile-breakpoint=0
        v-if="typeof menuCollapsed === 'boolean'"
      )
        v-list(dense nav)

          v-list-item(two-line exact :to="{ name: 'legacy-dashboard' }" tile)
            v-list-item-icon
              div(:class="`iprova-icon ${iprovaIconClass}`")
            v-list-item-content
              v-list-item-title(class="text-h6") Invention Studio
              v-list-item-subtitle {{ version }}

          v-divider(class="mb-1")

          v-list-item(:to="{ name: 'legacy-dashboard' }" tile)
            v-tooltip(right)
              template(v-slot:activator="{ on, attrs }")
                v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                  v-icon mdi-monitor-dashboard
              span Dashboard
            v-list-item-content
              v-list-item-title Dashboard

          v-list-item(:to="evoLink" tile v-if="EVO_ACCESS_ENABLED")
            v-tooltip(right)
              template(v-slot:activator="{ on, attrs }")
                v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                  v-icon mdi-rocket-launch
              span Invention Studio evo
            v-list-item-content
              v-list-item-title Invention Studio
                span.evo-brand evo

        v-card(v-if="currentProject" class="project-card mx-2" tile)
          v-list(dense)

            v-list-item(
              two-line
              exact
              :to="{ name: 'legacy-project', params: projectRoutesParams }"
            )
              v-tooltip(right)
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                    v-icon mdi-folder-text-outline
                span {{ currentProject.name }} Project
              v-list-item-content
                v-list-item-title(class="text-subtitle-1") {{ currentProject.name }}
                v-list-item-subtitle Project

            v-divider

            v-treeview(
              :items="treeView"
              item-key="path"
              :open.sync="menuOpenState"
              activatable
              :active.sync="menuActiveState"
              @update:active="onMenuActiveChange"
            )
              template(v-slot:prepend="{ item, open }")
                v-tooltip(right :disabled="!menuCollapsed")
                  template(v-slot:activator="{ on }")
                    router-link(:to="{ name: item.path, ...projectRoutesParams }" v-if="item.path")
                      v-icon(v-on="on") {{ item.icon }}
                    v-icon(v-else) {{ item.icon }}
                  span {{ item.name }}
              template(v-slot:label="{ item, open }")
                router-link(:to="{ name: item.path, ...projectRoutesParams }" v-if="item.path" ) {{ item.name }}
                span(v-else) {{ item.name }}

        v-list(dense nav)
          v-divider(class="mb-1")

          v-list-item(:to="{ name: 'kpis' }" tile)
            v-tooltip(right)
              template(v-slot:activator="{ on, attrs }")
                v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                  v-icon mdi-speedometer
              span Global KPIs
            v-list-item-content
              v-list-item-title Global KPIs

          v-divider(class="mb-1")
        template(v-slot:append="")

          v-list(dense nav)

            v-divider(class="mb-1")

            v-list-item(href="https://iprova-jira.atlassian.net/servicedesk/customer/portal/2" target="_blank" tile)
              v-tooltip(right)
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                    v-icon mdi-comment-quote-outline
                span Give Feedback
              v-list-item-content
                v-list-item-title Give Feedback

            v-list-item(href="https://iprova-jira.atlassian.net/servicedesk/customer/portal/2/article/933953685" target="_blank" tile)
              v-tooltip(right)
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                    v-icon mdi-help-circle-outline
                span User Guide
              v-list-item-content
                v-list-item-title User Guide

            v-list-item(v-if="systemConfigRoute" :to="{ name: systemConfigRoute }" tile)
              v-tooltip(right)
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                    v-icon mdi-cogs
                span System configuration
              v-list-item-content
                v-list-item-title System configuration

            v-divider(class="mb-1")

            v-list-item(:to="{ name: 'profile' }" class="profile-item" tile)
              v-tooltip(right)
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                    v-gravatar(:email="profile.email" class="v-avatar")
                span Profile & settings
              v-list-item-content
                v-list-item-title Profile & settings
                v-list-item-subtitle Welcome, {{ displayName }}.

            v-list-item(@click="logout()" tile)
              v-tooltip(right)
                template(v-slot:activator="{ on, attrs }")
                  v-list-item-icon(v-bind="menuCollapsed && attrs" v-on="menuCollapsed && on")
                    v-icon mdi-logout
                span Logout
              v-list-item-content
                v-list-item-title Logout

          div(class="menu-toggle")
            v-icon(v-on:click="toggleMiniMenu()") {{ menuCollapsed ? 'mdi-chevron-right-circle-outline' : 'mdi-chevron-left-circle-outline' }}

      v-responsive(min-width="960px" :class="$router.currentRoute.name")
        v-main(:class="[mainClass, currentTheme]")
          slot

      canvas-popover(
        v-if="currentProject"
        v-show="showPopover"
        :isShown="showPopover"
      )

      main-snackbar
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import MainSnackbar from '@/components/MainSnackbar.vue'
import { getPreference, setPreference } from '@/utils/preferencesManager'
import Login from '@/modules/auth/views/Login'
import { THEMES } from '@/utils/constants'
import vtf from '@/plugins/vuetify'
import { loadLanguageAsync } from '@/plugins/i18n'
import CanvasPopover from '@/modules/canvas/views/CanvasPopover'
import { authStore } from '@/store/modules/auth'
import { appStore } from '@/store/modules/app'
import { notificationStore } from '@/store/modules/notifications'
import { mapState } from 'pinia'
import { isArray } from 'lodash/lang'
import { flatten } from 'lodash/array'
import { projectsStore } from '@/modules/projects/store'
import { useFlagsStore } from '@/store/modules/flags'
import { useConfigStore } from '@/store/modules/configuration'
import CriticalError from '@/components/CriticalError.vue'

const MENU_TREE = [
  {
    name: 'Project Scoping',
    icon: 'mdi-target',
    path: undefined,
    disabled: true,
    children: [
      {
        path: 'scoping-home',
        icon: 'mdi-bullseye-arrow',
        name: 'Scoping Tool',
        flag: 'SCOPING_TOOL_ENABLED'
      },
      {
        path: 'problemmining-home',
        icon: 'mdi-globe-model',
        name: 'Opportunity Atlas'
      }
    ]
  },
  {
    path: 'project-triggers',
    icon: 'mdi-comment-search-outline',
    name: 'Trigger Feeds'
  }, {
    path: 'project-tracker',
    icon: 'mdi-format-list-numbered',
    name: 'Trigger Tracker'
  }, {
    path: 'project-inventionconcepts',
    icon: 'mdi-file-edit-outline',
    name: 'Invention Concepts'
  }
]

export default {
  components: {
    CriticalError,
    CanvasPopover,
    MainSnackbar,
    Login
  },
  inject: ['currentTheme'],

  data: () => ({
    menuCollapsed: true,
    menuActiveState: [],
    menuOpenState: [undefined],
    today: new Date()
  }),

  computed: {
    ...mapState(authStore, ['displayName']),
    ...mapState(projectsStore, ['currentProject', 'currentProjectId']),
    ...mapState(useFlagsStore, ['EVO_ACCESS_ENABLED', 'SCOPING_TOOL_ENABLED', 'OPPORTUNITY_ATLAS_ENABLED']),
    projectRoutesParams() {
      return {
        project_id: this.currentProjectId
      }
    },
    systemConfigRoute() {
      if (this.auth.profile.is_superuser) return 'users'
      return this.auth.profile.project_assignments.some(pa => pa.is_project_manager)
        ? 'criteria-management'
        : ''
    },
    profile() {
      return this.auth.profile
    },
    hasUser() {
      return Boolean(this.auth.displayName)
    },
    configLoadingError() {
      return Boolean(useConfigStore().error)
    },
    iprovaIconClass() {
      if (this.today.getDate() === 1 && this.today.getMonth() === 3) {
        return 'iprova-april-icon'
      }
      if (this.today.getDate() === 31 && this.today.getMonth() === 9) {
        return 'iprova-halloween-icon'
      }
      return 'iprova-normal-icon'
    },
    mainClass() {
      return this.$route.meta.fullWidth ? 'full-width' : ''
    },
    showPopover() {
      return this.$route.name && !this.$route.name.startsWith('project-inventionworkbook')
    },
    viewTitle() {
      try {
        return this.$route.matched[0].instances?.default?.viewTitle || ''
      } catch (e) {
        // ignore
        return ''
      }
    },
    version() {
      return this.OPPORTUNITY_ATLAS_ENABLED ? 'v7.0' : 'v6.6'
    },
    treeView() {
      return cloneDeep(this.filterMenuTree(MENU_TREE))
    },
    MENU_ROUTES() {
      const menuTree = this.filterMenuTree(MENU_TREE)
      return flatten(menuTree.map(i => [i, ...(i.children || [])])).map(i => i.path).filter(i => i)
    },
    evoLink() {
      return this.currentProjectId
        ? { name: 'explore-ideas', params: { project_id: this.currentProjectId } }
        : { name: 'dashboard' }
    }
  },

  watch: {
    async hasUser(newValue) {
      if (newValue) {
        notificationStore().startLiveFetchNotifications()
        await projectsStore().getCurrent()
        if (this.$route.name === 'login') {
          const afterLogin = localStorage.getItem('afterLogin') || { name: 'home' }
          localStorage.removeItem('afterLogin')
          await this.$router.replace(afterLogin)
        }
        const userTheme = (await getPreference('CURRENT_THEME')) || THEMES.AUTO
        let effectiveTheme = userTheme
        if (userTheme === THEMES.AUTO) {
          effectiveTheme = window.matchMedia?.('(prefers-color-scheme: dark)')?.matches
            ? THEMES.DARK
            : THEMES.LIGHT
        }
        vtf.framework.theme.isDark = effectiveTheme === THEMES.DARK
        vtf.framework.theme.applyTheme()
      }
    },
    async $route(newRoute, oldRoute) {
      const oldProjectId = oldRoute?.params?.project_id
      const newProjectId = newRoute?.params?.project_id
      if (Number(oldProjectId) === Number(newProjectId)) return
      if (newProjectId === undefined) projectsStore().currentProject = null
      else await projectsStore().getCurrent(newProjectId)

      if (this.currentProjectId && this.menuActiveState[0] && this.menuActiveState[0] !== newRoute.name) {
        const match = this.MENU_ROUTES.find(name => newRoute.path.startsWith(this.$router.resolve({ name, ...this.projectRoutesParams }).href))
        if (match) this.menuActiveState = [match]
        else this.menuActiveState = []
      }
    },
    viewTitle(newValue) {
      window.document.title = newValue || 'Invention Studio'
    }
  },
  beforeCreate() {
    this.auth = authStore()
  },
  async created() {
    this.setDate()
    this.menuActiveState = [this.$route.name || this.$router.history.pending?.name]

    await Promise.all([
      loadLanguageAsync(),
      this.loadPreferences(),
      this.loadCurrentProjectData()
    ])

    notificationStore().startLiveFetchNotifications()
    this.setupLeaveGuard()
    if (this.$route.name === 'legacy-dashboard') {
      projectsStore().currentProject = null
    }
  },

  methods: {
    setDate() {
      const updateToday = () => {
        this.today = new Date()
      }
      setTimeout(() => {
        updateToday()
        setInterval(updateToday, 24 * 3600 * 1000)
      }, new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + 1) - this.today)
    },
    async loadPreferences() {
      if (this.auth.isAuthenticated) {
        await this.auth.fetchProfile()

        const menuCollapsed = await getPreference('MAIN_MENU_COLLAPSED')
        if (typeof menuCollapsed === 'boolean') {
          this.menuCollapsed = menuCollapsed
        }
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', async event => {
        const userTheme = (await getPreference('CURRENT_THEME')) || THEMES.AUTO
        if (userTheme === THEMES.AUTO) {
          vtf.framework.theme.isDark = event.matches
          vtf.framework.theme.applyTheme()
        }
      })
    },
    async loadCurrentProjectData() {
      const routeProjectId = this.$route.params?.project_id
      if (routeProjectId && routeProjectId !== this.currentProjectId) {
        await projectsStore().fetchCurrent(routeProjectId)
      }
    },
    async logout() {
      await this.auth.logout()
      await this.$router.push({ name: 'login' })
    },
    async toggleMiniMenu() {
      this.menuCollapsed = !this.menuCollapsed
      await setPreference('MAIN_MENU_COLLAPSED', this.menuCollapsed)
    },
    setupLeaveGuard() {
      // Required to handle save changes confirmation on browser page reload.
      // The message specified bellow will be shown on older browsers,
      // new browsers will display their own generic message which cannot be overwritten
      window.addEventListener('beforeunload', (e) => {
        if (appStore().hasPendingChanges) {
          e.preventDefault()
          // chrome requires returnValue to be set
          const message = 'You have unsaved changes.\n\n' +
            'Are you sure you want to leave this page?'
          e.returnValue = message
          return message
        }
      })
    },
    onMenuActiveChange([newValue]) {
      if (!newValue && this.MENU_ROUTES.indexOf(this.$route.name) >= 0) {
        this.menuActiveState = [this.$route.name]
        return
      }

      let item
      this.treeView.find(function iter(a) {
        if (a.path !== newValue) return isArray(a.children) && a.children.find(iter)
        item = a
        return true
      })
      if (!item || this.$route.path.startsWith(this.$router.resolve({ name: item.path, ...this.projectRoutesParams }).href)) return
      this.$router.push({ name: item.path, params: { ...this.projectRoutesParams } })
    },
    filterMenuTree(menuTree) {
      return menuTree.map(item => {
        if (item.children) {
          const filteredChildren = this.filterMenuTree(item.children)
          if (filteredChildren.length > 0) {
            return { ...item, children: filteredChildren }
          }
        } else if (!item.flag || this[item.flag]) {
          return item
        }

        return null
      }).filter(item => item !== null)
    }

  }
}
</script>
<style scoped lang="scss">
.v-main:not(.full-width) {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
}

.v-navigation-drawer--mini-variant {
  .project-card {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  :deep(.v-treeview-node__level + .v-treeview-node__level) {
    width: 0px !important;
  }

  :deep(.v-treeview > .v-treeview-node--disabled > .v-treeview-node__root) {
    height: 0;
    min-height: 0;
  }
}

.project-card {
  will-change: margin-left, margin-right;
  transition: margin-left 200ms linear, margin-right 200ms linear;
  background-color: #42516a !important;
  border-color: #42516a !important;
}

.project-card .v-list-item__icon.v-list-item__icon {
  margin-right: 24px;
}

.v-avatar {
  width: 40px;
  height: 40px;
  margin: -3px -8px;
}

.iprova-icon {
  width: 57px;
  height: 50px;
  background-position: top left;
  background-size: cover;
  margin: -8px -16px;
}

.iprova-normal-icon {
  background-image: url(resources/iprova-icon-small-white.png);
}

.iprova-april-icon {
  background-image: url(resources/fish.gif);
}

.iprova-halloween-icon {
  background-image: url(resources/zombie.gif);
}

.v-navigation-drawer {
  overflow: initial;
}

.profile-item {
  height: 50px;
}

.menu-toggle {
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 1;
}

.menu-toggle > button {
  margin-left: calc(50% - 2px);
  margin-top: -2px;
  padding: 2px;
  background: rgb(112, 122, 151);
  border-radius: 50%;
  box-shadow: 1px 0 0 white;
}

.v-tooltip__content {
  margin-left: 10px;
  background-color: rgb(97, 97, 97);
}

.v-list-item--disabled i {
  color: rgba(255, 255, 255, 0.5);
}

.v-treeview {

  font-size: 13px;
  line-height: 16px;
  font-family: IBM Plex Sans, common-ligatures discretionary-ligatures historical-ligatures tabular-nums, sans-serif;
  overflow-x: initial;
  overflow-y: hidden;

  :deep(button) {
    visibility: hidden;
  }

  :deep(a) {
    text-decoration: none;
    color: white;
  }

  :deep(.v-treeview-node__root.v-treeview-node--active) {
    color: white !important;
  }

  :deep(.v-treeview-node--disabled > .v-treeview-node__root .v-icon) {
    color: inherit;
  }

  :deep(.v-treeview-node__level) {
    transition: width 200ms linear;
  }

  :deep( > div > .v-treeview-node__root) {
    height: 48px;
    min-height: 0;
    transition: height 200ms linear;
    overflow: hidden;
  }

  :deep(.v-treeview-node__level + .v-treeview-node__level) {
    width: 48px;
  }

  .v-icon {
    margin-right: 18px;
  }

  :deep(>.v-treeview-node) {
    margin-left: -27px;
    padding-left: 5px;
  }
}

.evo-icon {
  font-size: 13px;
  padding-top: 5px;
}

.evo-brand {
  font-weight: bold;
  font-style: italic;
  vertical-align: super;
  font-size: 0.7rem;
}
</style>
<style lang="scss">
.v-toolbar__content {
  max-width: 1845px;
  margin: 0 auto;
}

.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}

.theme--light.v-btn.elevation-0:not(.v-btn--text):not(.v-btn--outlined) {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.theme--dark.v-btn.elevation-0:not(.v-btn--text):not(.v-btn--outlined) {
  border: thin solid rgba(255, 255, 255, 0.12);
}

.theme--dark.v-btn-toggle:not(.v-btn-toggle--group) {
  background: none;
}

.gutter.gutter {
  background-color: rgba(128, 128, 128, 0.3)
}

.v-pagination.theme--light button {
  border-radius: 0;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.v-pagination.theme--dark button {
  border-radius: 0;
  border: thin solid rgba(255, 255, 255, 0.12);
  box-shadow: none;
}

[tile][tile][tile][tile][tile] {
  border-radius: 0;

  &::before, &::after {
    border-radius: 0;
  }
}

.v-expansion-panel {
  border: thin solid rgba(128, 128, 128, 0.3);
}

.v-expansion-panel::before {
  display: none;
}

.problemmining-feed {
  overflow: unset;
}

span.search-highlight {
  background-color: rgba(255, 255, 0, 0.4);
  font-weight: bolder;
  white-space: nowrap;
}
</style>
