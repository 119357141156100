import { cloneDeep } from 'lodash/lang';
export function makeIdeaRefinementState(initialIdea) {
    return {
        fetchingOptions: false,
        regeneratingConcept: false,
        reassessingConcept: false,
        conceptRegenerated: false,
        bookmarkedConcept: initialIdea.bookmarked ? initialIdea : undefined,
        savedAsInventionConcept: initialIdea.invention_concept_id ? initialIdea : undefined,
        triggers: [],
        refinementTextToDisplay: null,
        action: '',
        selectedContent: initialIdea.content,
        ideaStack: [initialIdea],
        editionTracker: [{ assessed: cloneDeep(initialIdea.content), initial: cloneDeep(initialIdea.content) }],
        currentIndex: 0
    };
}
