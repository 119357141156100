export function makeReconnectingWebsocket(url, configure, maxReconnectAttempts = 5, reconnectTimeout = 1000) {
    const state = {
        ws: null,
        reconnectAttempts: 0,
        maxReconnectAttempts,
        reconnectTimeout,
        isConnected: false
    };
    function connect() {
        if (state.reconnectAttempts >= state.maxReconnectAttempts) {
            console.error('Max reconnect attempts reached. No further attempts will be made.');
            return;
        }
        state.ws = new WebSocket(url);
        configure(state.ws);
        state.ws.onopen = () => {
            state.isConnected = true;
            state.reconnectAttempts = 0; // Reset on successful connection
        };
        state.ws.onclose = (e) => {
            state.isConnected = false;
            console.debug('Socket is closed. Reconnect will be attempted in', state.reconnectTimeout / 1000, 'seconds.', e.reason);
            if (state.reconnectAttempts < state.maxReconnectAttempts) {
                state.reconnectAttempts++;
                setTimeout(connect, state.reconnectTimeout);
            }
        };
        state.ws.onerror = (err) => {
            // @ts-ignore
            console.warn('Socket encountered error:', err.message, 'Closing socket');
            state.ws?.close();
        };
    }
    connect();
    return state;
}
