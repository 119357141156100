import { isEmpty, isFunction, isObject } from 'lodash/lang'
import { apm } from '@elastic/apm-rum'
import { isArray } from 'lodash'

function findParentInstruments(vueEl) {
  if (!vueEl.$parent) return
  return vueEl.$parent.$instruments || findParentInstruments(vueEl.$parent)
}

export function instrumentizeCreation(component, config) {
  /**
   * Instrumentizes a component by adding an `$instruments` variable and initializing/starting
   * a transaction.
   */
  function instrumentMounted() {
    let transaction
    if (config.$inheritTransaction) transaction = findParentInstruments(this)?.transaction
    this.$instruments = {
      transaction: transaction || apm.startTransaction(component.name),
      spans: {},
      labels: {
        route: this.$route.name,
        ...this.$route.params
      }
    }
    for (const watcher of component.$instrumentizedWatchers) {
      watcher.call(this)
    }
  }
  const wrappedMounted = component.mounted
  component.mounted = function mounted() {
    instrumentMounted.call(this)
    if (isFunction(wrappedMounted)) wrappedMounted.call(this)
    else if (isArray(wrappedMounted)) wrappedMounted.forEach(fn => fn.call(this))
  }
  ensureRouteParamsInTransaction(component)
}

export function instrumentizeDeletion(component) {
  /**
   * Instrumentizes a component by ending the transaction when it's destroyed.
   */
  function instrumentDestroy() {
    this.$instruments.transaction.end()
  }
  const wrappedDestroy = component.beforeDestroy
  component.beforeDestroy = function beforeDestroy() {
    if (isFunction(wrappedDestroy)) wrappedDestroy.call(this)
    else if (isArray(wrappedDestroy)) wrappedDestroy.forEach(fn => fn.call(this))
    instrumentDestroy.call(this)
  }
}

export function ensureRouteParamsInTransaction(component) {
  attachWatcher(component, '$route.params', function watcher(newValue) {
    if (!isEmpty(newValue)) {
      this.$instruments.labels = newValue
      this.$instruments.transaction?.addLabels(newValue)
      for (const span of Object.values(this.$instruments.spans)) {
        span?.addLabels(newValue)
      }
    }
  })
}

export function attachWatcher(component, valueName, watcher) {
  component.$instrumentizedWatchers.push(watcher)

  if (!component.watch) component.watch = {}

  if (!component.watch[valueName]) component.watch[valueName] = watcher

  else if (isFunction(component.watch[valueName])) {
    const currentWatcher = component.watch[valueName]
    component.watch[valueName] = function() {
      currentWatcher.call(this, ...arguments)
      watcher.call(this, ...arguments)
    }
  } else if (isObject(component.watch[valueName]) && isFunction(component.watch[valueName].handler)) {
    const currentWatcher = component.watch[valueName].handler
    component.watch[valueName].handler = function() {
      currentWatcher.call(this, ...arguments)
      watcher.call(this, ...arguments)
    }
  } else {
    throw Error(`unsupported watcher config for ${component.name}.${valueName}`)
  }
}
