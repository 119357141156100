import { defineStore } from 'pinia';
import { HocuspocusProvider } from '@hocuspocus/provider';
import { WS_HOST } from '../../../environment';
import { encodeStateAsUpdate } from 'yjs';
import { createWorkbookBlock, deleteWorkbookBlock, getWorkbookBlocks, patchWorkbookBlock } from '../../../api/inventionWorkbooks';
import { arrayBufferToBase64 } from '../utils/base64';
import debounce from 'lodash/debounce';
import { getPreference } from '../../../utils/preferencesManager';
import { BLOCK_TYPES } from '../../../utils/constants';
import { useCanvasStore } from './index';
import { ref, watch } from 'vue';
import sortBy from 'lodash/sortBy';
import store from '@/store';
import { assign } from 'lodash';
import { makeReconnectingWebsocket } from '../../../utils/websockets';
import { authStore } from '../../../store/modules/auth';
import { useIntervalFn } from '@vueuse/core';
export const useCanvasBlockSyncStore = defineStore('canvasBlockSyncStore', () => {
    const canvasStore = useCanvasStore();
    const blocks = ref([]);
    const focusedBlock = ref(undefined);
    const blocksSyncWebsocket = ref();
    const knownProviders = {};
    const connectedUsers = ref([]);
    const computeConnectedUsers = () => {
        const currentCanvasId = canvasStore.currentCanvas?.uuid;
        if (!currentCanvasId || !knownProviders[currentCanvasId]) {
            connectedUsers.value = [];
            return;
        }
        const users = {};
        knownProviders[currentCanvasId].forEach((provider) => {
            // getStates().values() is a MapIterator. Not all browsers implement the forEach() method.
            // @ts-ignore
            Array.from(provider.awareness?.getStates().values()).forEach((state) => {
                const username = state.user?.name ?? state.userMouseMove?.username ?? state.userSelection?.username ?? '';
                if (username) {
                    users[username] = true;
                }
            });
        });
        connectedUsers.value = Object.keys(users);
    };
    const listUsers = useIntervalFn(computeConnectedUsers, 5000, { immediate: false });
    watch(() => canvasStore.currentCanvas?.uuid, computeConnectedUsers);
    function createProvider(blockId, workbookId) {
        /**
         * Returns a HocusPocusProvider for live-synchronizing a given block's content.
         * It will also sync the `yjs_content` when the user types some update.
         */
        let hasDoneSyncStepOne = false;
        const storeYjsContent = debounce((doc) => {
            return patchWorkbookBlock(blockId, { yjs_content: arrayBufferToBase64(encodeStateAsUpdate(doc)) });
        }, 1000);
        const provider = new HocuspocusProvider({
            url: `${WS_HOST}/api/projects/workbooks/blocks/${blockId}/`,
            name: `${blockId}`,
            onOutgoingMessage(data) {
                // ideally we'd compare the type of the message, which should by SyncStepOneMessage but this is not exported,
                // so we need to compare some internal property of the message...
                // @ts-ignore
                if (data.message.description === 'First sync step')
                    hasDoneSyncStepOne = true;
                // @ts-ignore
                if (hasDoneSyncStepOne && data.message.description === 'A document update')
                    storeYjsContent(provider.document);
            }
        });
        if (!knownProviders[workbookId])
            knownProviders[workbookId] = [];
        knownProviders[workbookId].push(provider);
        provider.on('destroy', () => knownProviders[workbookId].splice(knownProviders[workbookId].indexOf(provider), 1));
        return provider;
    }
    async function fetchBlocks() {
        if (!canvasStore.currentCanvas)
            return;
        const response = (await getWorkbookBlocks(canvasStore.currentCanvas.uuid));
        await Promise.all(response.data.map(async (block) => {
            block.folded = Boolean(await getPreference('INVENTION_WORKBOOKS_BLOCK_FOLDED', {
                blockId: block.id
            }));
        }));
        blocks.value = sortBy(response.data, 'order');
        if (blocks.value.length === 0) {
            // @ts-ignore
            await insertBlock({ type: BLOCK_TYPES.TEXT_EDITOR, order: 0 });
        }
    }
    async function insertBlock(data) {
        await createWorkbookBlock({
            invention_workbook: canvasStore.currentCanvas?.uuid,
            content: {},
            order: blocks.value.length,
            widget_height: 0,
            ...data
        });
        blocksSyncWebsocket.value?.ws?.send('update');
        await fetchBlocks();
    }
    async function deleteBlock(block) {
        try {
            await deleteWorkbookBlock(block.id);
            blocksSyncWebsocket.value?.ws?.send('update');
            store.commit('snackbar/show', {
                color: 'success',
                message: 'Block was successfully deleted'
            });
            return fetchBlocks();
        }
        catch (e) {
            // 404: the block has probably already been deleted. Just swallow and refresh
            if (e.response?.status === 404) {
                return fetchBlocks();
            }
            throw e;
        }
    }
    async function updateBlock(block, data, config) {
        try {
            const response = await patchWorkbookBlock(block.id, data, config);
            // we send the user id so that the websocket doesn't send a message to the user that sent the update
            blocksSyncWebsocket.value?.ws?.send(`update:${authStore().profile.user_id}`);
            if (Object.keys(data).length === 1 && data.name)
                return;
            assign(blocks.value.find(b => b.id === block.id), response.data);
        }
        catch (error) {
            // @ts-ignore
            if (error?.response?.status === 413) {
                store.commit('snackbar/show', {
                    color: 'warning',
                    message: "The file you're trying to upload is too large"
                });
            }
            else {
                store.commit('snackbar/show', {
                    color: 'error',
                    message: 'Error while saving changes'
                });
            }
            await fetchBlocks();
        }
    }
    async function reorderBlocks(event) {
        await patchWorkbookBlock(event.moved.element.id, { order: event.moved.newIndex });
        blocksSyncWebsocket.value?.ws?.send('update');
        blocks.value.forEach((block, index) => (block.order = index));
    }
    function recreateWebsocket() {
        if (blocksSyncWebsocket.value) {
            blocksSyncWebsocket.value.maxReconnectAttempts = 0;
            blocksSyncWebsocket.value.ws?.close();
            blocksSyncWebsocket.value.ws = null;
        }
        if (!canvasStore.currentCanvas?.uuid)
            return;
        blocksSyncWebsocket.value = makeReconnectingWebsocket(`${WS_HOST}/api/projects/workbooks/${canvasStore.currentCanvas?.uuid}`, (ws) => {
            ws.onmessage = (msg) => {
                // we do a check here to avoid fetching blocks if the current user is the one that sent the message
                const uid = msg.data.split(':')[1];
                if (uid !== authStore().profile.user_id)
                    return fetchBlocks();
            };
        }, Infinity);
    }
    const foldBlock = async (blockId) => {
        blocks.value = blocks.value.map(b => blockId === b.id ? { ...b, folded: !b.folded } : b);
    };
    watch(() => canvasStore.currentCanvas?.uuid, () => {
        blocks.value = [];
        recreateWebsocket();
        fetchBlocks();
    });
    recreateWebsocket();
    return {
        blocks,
        foldBlock,
        focusedBlock,
        createProvider,
        fetchBlocks,
        insertBlock,
        deleteBlock,
        updateBlock,
        reorderBlocks,
        connectedUsers,
        startListUsers: listUsers.resume,
        stopListUsers: listUsers.pause,
        _knownProviders: knownProviders
    };
});
