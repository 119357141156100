export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      isLoginRoute: true
    },
    component: () => import(/* webpackChunkName: "login" */ '@/modules/auth/views/Login.vue')
  }
]
