import _ from 'lodash';
export function genericValidationFunction(name, v) {
    /*
      This function is used to validate any field in our website and normalize the error message
      resulting from a bad input.
     */
    const errors = [];
    const isArray = Array.isArray(v.$model);
    const nameCapitalized = _.capitalize(name);
    if (!v.$dirty)
        return errors;
    // We use "=== false" because any validation can also be "undefined" and if so, we simply don't
    // want to test it
    if (v.required === false)
        errors.push(`${nameCapitalized} is required.`);
    if (v.maxLength === false) {
        if (isArray) {
            errors.push(`${nameCapitalized} cannot have more than ${v.$params.maxLength.max} elements.`);
        }
        else {
            errors.push(`${nameCapitalized} cannot exceed ${v.$params.maxLength.max} characters.`);
        }
    }
    if (v.minLength === false) {
        if (isArray) {
            errors.push(`${nameCapitalized} cannot have less than ${v.$params.minLength.min} elements.`);
        }
        else {
            errors.push(`${nameCapitalized} cannot be less than ${v.$params.minLength.min} characters.`);
        }
    }
    if (v.minValue === false)
        errors.push(`${nameCapitalized} be less than ${v.$params.maxValue.max}.`);
    if (v.maxValue === false)
        errors.push(`${nameCapitalized} cannot exceed ${v.$params.maxValue.max}.`);
    if (v.integer === false)
        errors.push(`${nameCapitalized} must be integer.`);
    if (v.isUnique === false)
        errors.push(`${nameCapitalized} must be unique.`);
    if (v.email === false)
        errors.push('Provide a valid email address.');
    if (v.url === false)
        errors.push('Must be a valid URL.');
    return errors;
}
const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
// this is the same regex vuelidate uses.
export const STRING_HAS_URL_REGEX = /(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?/i;
export function isValidUUID(uuid) {
    return uuidRegex.test(uuid);
}
export function isValidHttpUrl(string) {
    return Boolean(STRING_HAS_URL_REGEX.test(string));
}
