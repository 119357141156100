import http from '../plugins/http';
const ENDPOINT = 'notifications';
export function getNotifications(params) {
    return http.get(`${ENDPOINT}/notifications/`, { params });
}
export function getNotificationsTypes() {
    return http.get(`${ENDPOINT}/types/`);
}
export function markAllAsRead() {
    return http.post(`${ENDPOINT}/notifications/mark_all_as_read/`);
}
export function deleteAllReadNotifications() {
    return http.post(`${ENDPOINT}/notifications/delete_all_read_notifications/`);
}
export function deleteNotification(id) {
    return http.delete(`${ENDPOINT}/notifications/${id}/`);
}
export function patchNotification(id, data) {
    return http.patch(`${ENDPOINT}/notifications/${id}/`, data);
}
export function getPreferences() {
    return http.get(`${ENDPOINT}/preferences/`);
}
export function setPreferences(data) {
    return http.post(`${ENDPOINT}/preferences/`, data);
}
