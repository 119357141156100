export default [
  {
    path: '/project/:project_id/triggers',
    name: 'project-triggers',
    meta: { legacy: true },
    component: () => import(/* webpackChunkName: "project-triggers" */ '@/legacy_modules/triggers/views/Triggers.vue')
  },
  {
    path: '/project/:project_id/tracker',
    name: 'project-tracker',
    meta: { legacy: true, pageTitle: 'Tracker' },
    component: () => import(/* webpackChunkName: "project-tracker" */ '@/legacy_modules/triggers/views/Tracker.vue')
  }
]
