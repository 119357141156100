// Source: https://github.com/ueberdosis/tiptap/pull/1990
import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';
export const FONT_SIZE_OPTIONS = [
    { text: 8, value: '8pt' },
    { text: 9, value: '9pt' },
    { text: 10, value: '10pt' },
    { text: 11, value: '11pt' },
    { text: 12, value: '12pt' },
    { text: 14, value: '14pt' },
    { text: 16, value: '16pt' },
    { text: 18, value: '18pt' },
    { text: 24, value: '24pt' },
    { text: 30, value: '30pt' },
    { text: 36, value: '36pt' },
    { text: 48, value: '48pt' },
    { text: 60, value: '60pt' }
];
export const FontSize = Extension.create({
    name: 'fontSize',
    addOptions() {
        return {
            types: ['textStyle']
        };
    },
    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontSize: {
                        default: null,
                        parseHTML: element => element.style.fontSize,
                        renderHTML: attributes => {
                            if (!attributes.fontSize) {
                                return {};
                            }
                            return {
                                style: `font-size: ${attributes.fontSize}`
                            };
                        }
                    }
                }
            }
        ];
    },
    addCommands() {
        return {
            setFontSize: (fontSize) => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize })
                    .run();
            },
            unsetFontSize: () => ({ chain }) => {
                return chain()
                    .setMark('textStyle', { fontSize: null })
                    .removeEmptyTextStyle()
                    .run();
            }
        };
    }
});
