export const NODE_CATEGORIES = {
    DEFAULT: 'text',
    IMAGE: 'image',
    TRACKED_TRIGGER: 'trackedTrigger'
};
export const LINK_CATEGORIES = {
    DEFAULT: '',
    DIRECTED: 'directed',
    UNDIRECTED: 'undirected'
};
