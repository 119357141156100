import { useRoute } from 'vue-router/composables';
import { computed, ref, watch } from 'vue';
import { getPreference, setPreference } from '../../utils/preferencesManager';
import { defineStore } from 'pinia';
const routeNameMappings = {
    'project-new': 'project-edit'
};
const prefKey = 'DISMISSED_HELP_BOXES';
export const useDismissibleHelpStore = defineStore('dismissibleHelp', () => {
    const route = useRoute();
    const dismissedHelp = ref([]);
    const availableHelp = ref([]);
    const pageId = computed(() => {
        if (route.name && Object.hasOwn(routeNameMappings, route.name))
            return routeNameMappings[route.name];
        return route.name ?? 'undefined';
    });
    const loadDismissed = async () => {
        availableHelp.value = [];
        dismissedHelp.value = await getPreference(prefKey, { pageId: pageId.value }) ?? [];
    };
    watch(pageId, loadDismissed, { immediate: true });
    const setDismissed = async (id) => {
        dismissedHelp.value.push(id);
        await setPreference(prefKey, dismissedHelp.value, { pageId: pageId.value });
    };
    const clearDismissed = async () => {
        dismissedHelp.value = [];
        await setPreference(prefKey, dismissedHelp.value, { pageId: pageId.value });
    };
    const registerHelp = (id) => {
        availableHelp.value.push(id);
    };
    const isDismissed = (id) => dismissedHelp.value.includes(id);
    return {
        dismissedHelp,
        setDismissed,
        isDismissed,
        clearDismissed,
        availableHelp,
        registerHelp,
        loadDismissed
    };
});
