import dayjs from 'dayjs';
import engb from 'dayjs/locale/en-gb';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
export function setDayjsLocale() {
    /**
     * Runs heuristics to determine the user's browser's language, and apply it.
     *
     * Currently, only british english and american english are supported, and the default is british.
     */
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(isoWeeksInYear);
    dayjs.extend(isLeapYear);
    for (const locale of navigator.languages) {
        if (locale.toLowerCase() === 'en-us') {
            console.debug('Setting locale to american english');
            return;
        }
    }
    console.debug('Setting locale to british english');
    dayjs.locale(engb);
}
