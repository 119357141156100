import { authStore } from '@/store/modules/auth'
import { projectsStore } from '@/modules/projects/store'

export const elementsCache = {}

/**
 * This is all types of permission you can have. This match the arg of the directive and call the
 * appropriate function.
 * @param {any}permissionsValue: The value pass to the directive
 * @param {Object}meta: All the meta needed to check the permission
 * @return {Boolean}hasPermission: Has the user pass this permission function.
 */
const permissionTypes = {
  roles: (permissionsValue, meta) => {
    if (!Array.isArray(permissionsValue) || !permissionsValue.length) {
      throw new Error('v-permission:roles require an array as value. Example: v-permission:roles="[\'ID\']"')
    }
    return meta.roles.some(role => {
      return permissionsValue.includes(role)
    })
  },
  projectAssigned: (permissionsValue, meta) => {
    return meta.isProjectAssigned === permissionsValue
  },
  currentUserId: (permissionsValue, meta) => {
    if (permissionsValue === undefined || permissionsValue === null || permissionsValue === '') {
      throw new Error('v-permission:currentUserId require a value! Example: v-permission:currentUserId="5"')
    }
    return permissionsValue === meta.userId
  }
}

/**
 * All the logic operators for the directive v-permission
 * @param {Boolean}permission1: First permission value
 * @param {Boolean}permission2: Second permission value
 * @return {Boolean}: Result of the operation
 */
const logicOperators = {
  or: (permission1, permission2) => {
    return permission1 || permission2
  },
  and: (permission1, permission2) => {
    return permission1 && permission2
  }
}

/**
 * Get all the metadatas needed to process the permissions
 */
function getMetadatas() {
  const auth = authStore()
  // Set the variable needed to decide the permissions
  const projectId = projectsStore().currentProjectId
  if (!projectId) return { isSuperUser: auth.profile.is_superuser }
  const userId = auth.profile.user_id
  const isPM = projectId !== undefined ? auth.isProjectManager(projectId) : false
  const isID = projectId !== undefined ? auth.isInventionDeveloper(projectId) : false
  const isProjectAssigned = isPM || isID
  const isSuperUser = auth.profile.is_superuser

  const roles = []
  if (isPM) roles.push('PM')
  if (isID) roles.push('ID')

  // All the meta needed to calculate the permissions
  return {
    projectId,
    userId,
    isPM,
    isID,
    isProjectAssigned,
    isSuperUser,
    roles
  }
}

function checkPermission(permissionType, permissionValue) {
  // If the arg doesn't match any permission type, throw an error
  if (!Object.hasOwn(permissionTypes, permissionType)) {
    throw new Error(`${permissionType} is not a valid permission type! Valid type: ${Object.keys(permissionTypes)}`)
  }
  const meta = getMetadatas()
  // If this is the superUser, always return true
  if (meta.isSuperUser) return true

  // If there is no metadata (Project is not defined), we cannot expect the permission system to
  // work, we deny everything
  if (!meta.projectId) return false

  return permissionTypes[permissionType](permissionValue, meta)
}

function addToCache(el, vnode, hasPermission, modifier) {
  if (!Object.hasOwn(elementsCache, el)) {
    elementsCache[el] = {
      numberOfPermissionDirectives: vnode.data.directives.filter(e => e.name === 'permission').length,
      results: []
    }
  }
  elementsCache[el].results.push({ hasPermission, logicOperator: modifier })
}

function clearFromCache(elem) {
  if (Object.hasOwn(elementsCache, elem)) delete elementsCache[elem]
}

function throwError(el, error, message = '') {
  clearFromCache(el)
  if (!error) throw new Error(message)
  throw error
}

function hasPermissionFinal(el) {
  let output
  let nextLogicOperator
  for (const result of elementsCache[el].results) {
    if (output === undefined) {
      output = result.hasPermission
      nextLogicOperator = result.logicOperator
      continue
    }
    if (nextLogicOperator === undefined) {
      throwError(
        el,
        null,
        `When there is multiple v-permission directive, you need to precise for all except
          the last the logic operator to apply.\nExample:\nv-permission:roles.or=["PM"]
          \nv-permission:currentUserId="5"\n\nAvailable operators: ${Object.keys(logicOperators)}`
      )
    }
    output = logicOperators[nextLogicOperator](output, result.hasPermission)
    nextLogicOperator = result.logicOperator
  }
  return output
}

function processPermissions(el, binding, vnode) {
  /**
   * value: What is after the '=' (Depends on each instruction. If nothing is precise -> value=true)
   * arg: What is after the ':' (Possible values: roles, currentUserId)
   * modifiers: What is after the '.' (Possible values: or, and)
   *
   * Exemple: v-permission:roles.and="['PM']"
   *
   * WARNING: This directive can only be use when the parent of the element is a real element
   * (Not a template element for example)
   */
  const { value, arg, modifiers } = binding
  let permissionValue = value

  // If there is no arg to the v-permission, throw an error
  if (!arg) {
    throwError(el, null, 'There should be a arg for the v-permission directive. Exemple: v-permission:arg="value"')
  }

  // If there is no '=', we expect it to be true by default
  if (permissionValue === undefined) permissionValue = true

  let hasPermission
  try {
    // Get the permission result of the actual v-permission
    hasPermission = checkPermission(arg, permissionValue)
  } catch (e) {
    // If while checking the permission an error is throw, we clear the catch and then
    // we let the error continue
    throwError(el, e)
  }

  let modifier
  if (Object.keys(modifiers).length > 1) {
    throwError(el, null, 'There can\'t be more than 1 modifier!')
  }
  if (Object.keys(modifiers).length === 1) {
    // eslint-disable-next-line prefer-destructuring
    modifier = Object.keys(modifiers)[0]
    // If the modifier is not a valid logic operator, throw an error
    if (!Object.hasOwn(logicOperators, modifier)) {
      clearFromCache(el)
      throwError(el, null, `${modifier} is not a valid logic operator! Valid: ${Object.keys(logicOperators)}`)
    }
  }
  // Save the actual v-permission and his result in the cache
  addToCache(el, vnode, hasPermission, modifier)
  // If the cache length is equal to the number of v-permission directive for a node, that mean we
  // processed all the directive and now we need to apply the logic operator between each result
  if (elementsCache[el].results.length === elementsCache[el].numberOfPermissionDirectives) {
    if (!hasPermissionFinal(el)) {
      try {
        el.parentNode?.removeChild(el)
      } catch (e) {
        // under some circumstances we can get the following error
        // NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
        // we just swallow the error...
      }
    }
    clearFromCache(el)
  }
}

export default {
  inserted(el, binding, vnode) {
    processPermissions(el, binding, vnode)
  },
  update(el, binding, vnode) {
    processPermissions(el, binding, vnode)
  },
  checkPermission
}
