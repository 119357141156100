import Vue from 'vue'
import { getFeeds } from '@/api/feeds'
import { patch } from '@/utils/objectPatcher'
import { projectsStore } from '@/modules/projects/store'

/**
 * Scoping store
 */

const storeGetters = {
  projectFeeds: state => state.feedsPerProject[projectsStore().currentProjectId] || []
}

const storeMutations = {
  patchFeed(state, feed) {
    patch(
      state.feedsPerProject[projectsStore().currentProjectId],
      [feed],
      [{ id: feed.id }]
    )
  },
  setFeeds(state, { feeds, projectId }) {
    Vue.set(state.feedsPerProject, projectId, feeds)
  }
}

const storeActions = {
  async fetchFeedsForProject({ commit }) {
    const projectId = projectsStore().currentProjectId
    try {
      const result = await getFeeds({ project: projectId })
      commit('setFeeds', { feeds: result.data, projectId })
    } catch (e) {
      console.error(e)
      commit('snackbar/show', {
        color: 'error',
        message: 'There was an error while trying to fetch the feeds.'
      })
    }
  }
}

export default {
  namespaced: true,

  state: {
    feedsPerProject: {}
  },

  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions
}
