import http from '@/plugins/http'
import { makeExportHeaders } from '@/interfaces/exports'

const ENDPOINT = 'kpis'

export function getUsers() {
  return http({
    url: `${ENDPOINT}/visible-users/`,
    method: 'get'
  })
}

export function getStats(params = {}) {
  return http({
    url: `${ENDPOINT}/stats/`,
    method: 'get',
    meta: {
      cancelPrevReq: true
    },
    params
  })
}

export function getTypeFilters() {
  return http({
    url: `${ENDPOINT}/type_filters/`,
    method: 'get'
  })
}

export function getPlotData(keys, categories, params) {
  return http({
    url: `${ENDPOINT}/plot_data/${keys},${categories}`,
    method: 'get',
    params
  })
}

export function getInventionConcepts(params, exportDataType = null) {
  return http({
    ...(exportDataType && makeExportHeaders(exportDataType)),
    url: `${ENDPOINT}/invention-concepts/`,
    method: 'get',
    params
  })
}

export function getInventionOpportunities(params, exportDataType = null) {
  return http({
    ...(exportDataType && makeExportHeaders(exportDataType)),
    url: `${ENDPOINT}/invention-opportunities/`,
    method: 'get',
    params
  })
}

export function getInventionDisclosures(params, exportDataType = null) {
  return http({
    ...(exportDataType && makeExportHeaders(exportDataType)),
    url: `${ENDPOINT}/invention-disclosures/`,
    method: 'get',
    params
  })
}
