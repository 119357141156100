import http from '../plugins/http';
const ENDPOINT = 'flags';
export function getDefaultFlags() {
    // @ts-ignore
    return http.get(`${ENDPOINT}/`, { meta: { noAuth: true } });
}
export function getFlags() {
    // @ts-ignore
    return http.get(`${ENDPOINT}/`);
}
