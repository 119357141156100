/**
 * Vuex module to manage the authentication
 */
import Vue, { computed, watch } from 'vue';
import { defineStore } from 'pinia';
import http from '../../plugins/http';
import { useAsyncState } from '../../utils/asyncState';
import mapValues from 'lodash/mapValues';
import Hotjar from '@hotjar/browser';
import HotjarPlugin from '@rovahub/vue-hotjar';
import { ensureGoJsLicense } from '../../plugins/gojs';
export const useConfigStore = defineStore('configuration', () => {
    const state = useAsyncState(async () => {
        // @ts-ignore
        const res = (await http.get('/configuration/', { meta: { noAuth: true } })).data;
        console.log(`Loaded configuration with INVENTION_STUDIO_VERSION=${res.INVENTION_STUDIO_VERSION}`);
        return res;
    }, {
        CLIENT: '',
        RUM_SERVER_URL: null,
        GOJS_LICENSE: null,
        HOTJAR_SITE_ID: null,
        INVENTION_STUDIO_VERSION: '',
        CSRF_TOKEN: localStorage.getItem('csrftoken') ?? '',
        ENVIRONMENT: ''
    }, { immediate: true });
    watch(() => state.state.value.CSRF_TOKEN, token => localStorage.setItem('csrftoken', token));
    watch(() => state.state.value.GOJS_LICENSE, value => value && ensureGoJsLicense());
    watch(() => state.state.value.HOTJAR_SITE_ID, siteId => {
        if (siteId) {
            const hotjarVersion = 6;
            Hotjar.init(siteId, hotjarVersion);
            Vue.use(HotjarPlugin, { siteId });
        }
    });
    const forcedValues = {};
    const configValues = mapValues(state.state.value, (v, key) => computed(() => {
        if (Object.hasOwn(forcedValues, key))
            return forcedValues[key];
        return state.state.value[key];
    }));
    http.interceptors.request.use((config) => {
        config.headers['X-CSRFToken'] = state.state.value.CSRF_TOKEN;
        return config;
    });
    return {
        ...configValues,
        fetchConfigs: state.execute,
        error: state.error,
        forceValueInTest(key, value) {
            // @ts-ignore
            forcedValues[key] = value;
        }
    };
});
