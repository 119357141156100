import http from '@/plugins/http'

const ENDPOINT = 'translations'

export function getTranslations() {
  return http({
    url: `${ENDPOINT}/`,
    method: 'get',
    meta: {
      noAuth: true
    }
  })
}
