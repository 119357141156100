import fuzzysort from 'fuzzysort';
import { normalize, htmlEscape } from './strings';
import cloneDeep from 'lodash/cloneDeep';
import { isArray, isString, property, memoize, set } from 'lodash';
import makeAsync from './makeAsync';
const INTERLINEAR_ANNOTATION_ANCHOR = '\uFFF9';
const INTERLINEAR_ANNOTATION_TERMINATOR = '\uFFFB';
const getters = memoize((keys) => keys.map(property));
const prepareForSearch = memoize(function (items, keys) {
    function normalizedPrepare(value) {
        const prepared = fuzzysort.prepare(normalize(value));
        prepared.target = value;
        prepared._targetLower = value;
        return prepared;
    }
    return items.flatMap((item, index) => getters(keys).map((getter, keyIndex) => {
        const value = getter(item);
        return ({
            item,
            index,
            value,
            key: keys[keyIndex],
            ...(value ? normalizedPrepare(value) : fuzzysort.prepare(''))
        });
    }));
});
export function htmlEscapeAllKeys(output, keysArr) {
    output.forEach((item) => {
        getters(keysArr).forEach((getter, keyIndex) => {
            const value = getter(item);
            const key = keysArr[keyIndex];
            if (isString(value)) {
                set(
                // @ts-ignore
                item, key, htmlEscape(value).replaceAll(/(\s)?([\uFFF9\uFFFB])(\s)?/g, (total, firstSpace, annotation, secondSpace) => `${firstSpace ? '&nbsp;' : ''}${annotation === INTERLINEAR_ANNOTATION_ANCHOR ? '<span class="search-highlight">' : '</span>'}${secondSpace ? '&nbsp;' : ''}`));
            }
        });
    });
    return output;
}
/**
 * Filter data using fuzzysort and/or filtering
 * @param {Array} searchData: The data that is filtered
 * @param {String} searchText: The search text
 * @param {String|Array[String]} keys: The property in the data object used for displaying
 * @param {Array} extraFilters: extraFilters is an array of object of the shape:
 * @example
 * extraFilters = [{
 *   enabled: () => Boolean,
 *   filter: obj => Boolean
 * }]
 * If enabled(), then the `output` will run filter(obj) on the searchData
 */
export async function fuzzySearch(searchData, searchText = '', keys = '', extraFilters = [], highlight = true) {
    const keysArr = isArray(keys) ? keys : [keys];
    let cloned = cloneDeep(searchData);
    for (const filterObj of extraFilters) {
        if (filterObj.enabled())
            cloned = cloned.filter(filterObj.filter);
    }
    if (!searchText)
        return htmlEscapeAllKeys(cloned, keysArr);
    const preparedData = await makeAsync(() => prepareForSearch(cloned, keysArr));
    // execute search
    const results = await makeAsync(() => fuzzysort.go(normalize(searchText), preparedData, {
        limit: 50,
        threshold: -10000 // don't return bad results
    }));
    // highlight and unpile results: keeping order of the results, but due to having multiple keys,
    // a single item may correspond to multiple results
    const output = [];
    const visited = {};
    await makeAsync(() => results.forEach((result) => {
        if (highlight) {
            set(
            // @ts-ignore
            result.item, 
            // @ts-ignore
            result.key, fuzzysort.highlight(result, INTERLINEAR_ANNOTATION_ANCHOR, INTERLINEAR_ANNOTATION_TERMINATOR));
        }
        // @ts-ignore
        if (!visited[result.index]) {
            // @ts-ignore
            output.push(result.item);
        }
        // @ts-ignore
        visited[result.index] = true;
    }));
    return makeAsync(() => htmlEscapeAllKeys(output, keysArr));
}
/**
 * Filter data using fuzzysort and/or filtering
 * @param {Array} searchData: The data that is filtered
 * @param {String} searchText: The search text
 * @param {String|Array[String]} keys: The property in the data object used for displaying
 * @param {Array} extraFilters: extraFilters is an array of object of the shape:
 * @example
 * extraFilters = [{
 *   enabled: () => Boolean,
 *   filter: obj => Boolean
 * }]
 * If enabled(), then the `output` will run filter(obj) on the searchData
 */
export function fuzzySearchSync(searchData, searchText = '', keys = '', extraFilters = [], highlight = true) {
    const keysArr = isArray(keys) ? keys : [keys];
    let cloned = cloneDeep(searchData);
    for (const filterObj of extraFilters) {
        if (filterObj.enabled())
            cloned = cloned.filter(filterObj.filter);
    }
    if (!searchText)
        return htmlEscapeAllKeys(cloned, keysArr);
    const preparedData = prepareForSearch(cloned, keysArr);
    // execute search
    const results = fuzzysort.go(normalize(searchText), preparedData, {
        limit: 50,
        threshold: -10000 // don't return bad results
    });
    // highlight and unpile results: keeping order of the results, but due to having multiple keys,
    // a single item may correspond to multiple results
    const output = [];
    const visited = {};
    results.forEach((result) => {
        if (highlight) {
            set(
            // @ts-ignore
            result.item, 
            // @ts-ignore
            result.key, fuzzysort.highlight(result, INTERLINEAR_ANNOTATION_ANCHOR, INTERLINEAR_ANNOTATION_TERMINATOR));
        }
        // @ts-ignore
        if (!visited[result.index]) {
            // @ts-ignore
            output.push(result.item);
        }
        // @ts-ignore
        visited[result.index] = true;
    });
    return htmlEscapeAllKeys(output, keysArr);
}
