import { NODE_CATEGORIES } from './VisualEditorConstants';
import { TRACKED_TRIGGER_SOURCE } from '../../triggers/constants';
import { isTrackedTrigger } from '../../../interfaces/tracker';
export const parseProblem = (event) => {
    const problem = JSON.parse(event.dataTransfer.getData('problemmining/problem'));
    return {
        url: problem.url,
        title: problem.title,
        virtual_id: null,
        essentialFeatures: [{ type: 'PROBLEM', content: problem.problem_text }],
        category: NODE_CATEGORIES.TRACKED_TRIGGER,
        text: undefined,
        summary: problem.doc_content.join(' '),
        date: problem.date,
        feedId: problem.feedId,
        source: TRACKED_TRIGGER_SOURCE.OPP_ATLAS
    };
};
export const parseTrackedTrigger = (event) => {
    const trigger = JSON.parse(event.dataTransfer.getData('trackedtrigger'));
    // apparently under some circumstances there can be data but it's undefined???
    if (!trigger)
        return;
    return {
        url: trigger.url,
        title: trigger.title,
        virtual_id: trigger.virtual_id,
        essentialFeatures: trigger.essential_features,
        category: NODE_CATEGORIES.TRACKED_TRIGGER,
        text: undefined,
        project_id: trigger.project_id,
        trigger_date: trigger.trigger_date,
        content: trigger.content,
        id: trigger.id ?? undefined,
        user_id: trigger.created_by?.id ?? undefined
    };
};
export const triggerAsTiptapContent = (trigger) => {
    let essentialFeatures;
    if (isTrackedTrigger(trigger)) {
        essentialFeatures = trigger.essential_features?.map(ef => ({
            type: ef.type || 'ABILITY',
            content: ef.content
        }));
    }
    else {
        essentialFeatures = trigger.essential_features?.map(ef => ({
            type: ef.type.toUpperCase(),
            // @ts-ignore
            content: ef.text ?? ef.content
        }));
    }
    if (!essentialFeatures?.length) {
        essentialFeatures = [{ type: 'ABILITY', content: ' ' }];
    }
    return {
        type: 'trigger',
        attrs: {
            link: trigger.url,
            type: isTrackedTrigger(trigger) ? trigger.doc_type : trigger.type,
            trackedTrigger: isTrackedTrigger(trigger) ? trigger.virtual_id : '',
            date: trigger?.trigger_date ?? '',
            summary: (isTrackedTrigger(trigger) ? trigger.content : trigger.content?.join(' ')) ?? '',
            source: 'Canvas added manually',
            triggerTitle: trigger.title || 'Missing title'
        },
        content: [...essentialFeatures.map(ef => ({
                type: 'essentialfeature',
                attrs: { eftype: ef.type },
                content: [{ text: ef.content, type: 'text' }]
            }))]
    };
};
