import http from '../plugins/http';
export async function authenticate(username, password, route) {
    // @ts-ignore
    return http({
        url: route,
        method: 'post',
        data: {
            login: username,
            password
        },
        meta: {
            noAuth: true
        }
    });
}
export function getLoginRoutes() {
    // @ts-ignore
    return http({
        url: 'auth/login_routes',
        method: 'get',
        meta: {
            noAuth: true
        }
    });
}
export async function authActiveDirectorySSO(requestGetParams, flow) {
    // @ts-ignore
    return http({
        url: 'auth/aad/login/',
        method: 'get',
        params: {
            ...requestGetParams,
            flow
        },
        meta: {
            noAuth: true
        }
    });
}
export function changePassword(data) {
    return http({
        url: 'auth/change_password',
        method: 'post',
        data
    });
}
export function impersonate(data) {
    return http.post('auth/impersonate/', data);
}
export function getSigninFlow() {
    return http.get('auth/aad/signin_flow/', {
        // @ts-ignore
        meta: {
            noAuth: true
        }
    });
}
export function logout() {
    return http.post('auth/logout/');
}
