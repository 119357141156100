import http from '@/plugins/http'

const ENDPOINT = 'feeds'
const SCOPING_ENDPOINT = 'scoping-feeds'

export function getFeeds(params = {}) {
  return http({
    url: `${ENDPOINT}/`,
    method: 'get',
    params
  })
}

export function patchFeed(id, data) {
  return http({
    url: `${ENDPOINT}/${id}`,
    method: 'patch',
    data
  })
}

export function getFeed(id) {
  return http({
    url: `${ENDPOINT}/${id}`,
    method: 'get'
  })
}

export function getScopingFeed(id) {
  return http({
    url: `${SCOPING_ENDPOINT}/${id}`,
    method: 'get'
  })
}

export function createScopingFeed(data) {
  return http({
    url: `${SCOPING_ENDPOINT}/`,
    method: 'post',
    data
  })
}

export function deleteScopingFeed(feedId, projectId) {
  return http({
    url: `${SCOPING_ENDPOINT}/${feedId}/`,
    method: 'delete',
    data: { project_id: projectId }
  })
}

export function updateScopingFeed(feedId, data) {
  return http({
    url: `${SCOPING_ENDPOINT}/${feedId}/`,
    method: 'patch',
    data
  })
}

export function duplicateScopingFeed(feedId, projectId, feedName) {
  return http({
    url: `${SCOPING_ENDPOINT}/${feedId}/duplicate/`,
    method: 'post',
    data: { project_id: projectId, name: feedName }
  })
}

export function undeployScopingFeed(feedId) {
  return http({
    url: `${SCOPING_ENDPOINT}/${feedId}/undeploy/`,
    method: 'post'
  })
}

export function deployScopingFeed(feedId) {
  return http({
    url: `${SCOPING_ENDPOINT}/${feedId}/deploy/`,
    method: 'post'
  })
}
