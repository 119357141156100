import Image from '@tiptap/extension-image';
function parseStyle(element) {
    const width = element.getAttribute('width');
    return width
        ? `width: ${width}px; height: auto; cursor: pointer;`
        : `${element.style.cssText}`;
}
function createResizingDots($container, style, dispatchNodeView) {
    const dotsPosition = [
        'top: -4px; left: -4px; cursor: nwse-resize;',
        'top: -4px; right: -4px; cursor: nesw-resize;',
        'bottom: -4px; left: -4px; cursor: nesw-resize;',
        'bottom: -4px; right: -4px; cursor: nwse-resize;'
    ];
    let isResizing = false;
    let startX, startWidth, startHeight;
    dotsPosition.forEach((position, index) => {
        const $dot = document.createElement('div');
        $dot.setAttribute('style', `position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; ${position}`);
        $dot.addEventListener('mousedown', (e) => {
            e.preventDefault();
            isResizing = true;
            startX = e.clientX;
            startWidth = $container.offsetWidth;
            startHeight = $container.offsetHeight;
            const onMouseMove = (e) => {
                if (!isResizing)
                    return;
                const deltaX = index % 2 === 0 ? -(e.clientX - startX) : e.clientX - startX;
                const aspectRatio = startWidth / startHeight;
                const newWidth = startWidth + deltaX;
                const newHeight = newWidth / aspectRatio;
                $container.style.width = `${newWidth}px`;
                $container.style.height = `${newHeight}px`;
                $container.firstChild.style.width = `${newWidth}px`;
                $container.firstChild.style.height = `${newHeight}px`;
            };
            const onMouseUp = () => {
                if (isResizing)
                    isResizing = false;
                dispatchNodeView();
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        });
        $container.appendChild($dot);
    });
}
function handleContainerClick($container, style, dispatchNodeView) {
    $container.addEventListener('click', () => {
        $container.setAttribute('style', `position: relative; border: 1px dashed #6C6C6C; ${style} cursor: pointer;`);
        createResizingDots($container, style, dispatchNodeView);
    });
}
function handleDocumentClick($container) {
    document.addEventListener('click', (e) => {
        const $target = e.target;
        const isClickInside = $container.contains($target) ||
            $target.style.cssText === 'width: 24px; height: 24px; cursor: pointer;';
        if (!isClickInside) {
            const containerStyle = $container.getAttribute('style');
            const newStyle = containerStyle?.replace('border: 1px dashed #6C6C6C;', '');
            if (newStyle)
                $container.setAttribute('style', newStyle);
            if ($container.childElementCount > 3) {
                for (let i = 0; i < 4; i++) {
                    if ($container.lastChild)
                        $container.removeChild($container.lastChild);
                }
            }
        }
    });
}
function createNodeView({ node, editor, getPos }) {
    const { view, options: { editable } } = editor;
    // @ts-ignore
    const { style } = node.attrs;
    const $wrapper = document.createElement('div');
    const $container = document.createElement('div');
    const $img = document.createElement('img');
    const dispatchNodeView = () => {
        if (typeof getPos === 'function') {
            // @ts-ignore
            const newAttrs = { ...node.attrs, style: `${$img.style.cssText}` };
            view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs));
        }
    };
    $wrapper.setAttribute('style', 'display: inline-block;');
    $wrapper.appendChild($container);
    $container.setAttribute('style', `${style}`);
    $container.appendChild($img);
    // @ts-ignore
    Object.entries(node.attrs).forEach(([key, value]) => {
        // @ts-ignore
        if (value !== undefined && value !== null)
            $img.setAttribute(key, value);
    });
    if (!editable)
        return { dom: $img };
    handleContainerClick($container, style, dispatchNodeView);
    handleDocumentClick($container);
    return { dom: $wrapper };
}
export default Image.extend({
    addAttributes() {
        return {
            src: { default: null },
            alt: { default: null },
            style: {
                default: 'width: 100%; height: auto; cursor: pointer;',
                parseHTML: parseStyle
            },
            title: { default: null },
            loading: { default: null },
            srcset: { default: null },
            sizes: { default: null },
            crossorigin: { default: null },
            usemap: { default: null },
            ismap: { default: null },
            width: { default: null },
            height: { default: null },
            referrerpolicy: { default: null },
            longdesc: { default: null },
            decoding: { default: null },
            class: { default: null },
            id: { default: null },
            name: { default: null },
            draggable: { default: true },
            tabindex: { default: null },
            'aria-label': { default: null },
            'aria-labelledby': { default: null },
            'aria-describedby': { default: null }
        };
    },
    // @ts-ignore
    addNodeView() {
        return createNodeView;
    }
});
