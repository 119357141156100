<template lang="pug">
  v-dialog.dialogContainer(
    v-model="openDialog"
    :width="width"
    :max-width="maxWidth"
    :persistent="isPersistent"
    @click:outside="clickOutside"
    transition="dialog-bottom-transition"
  )
    template(v-slot:activator="{ on }")
      slot(name="activator" v-bind:on="on")
    v-card
      v-card-title(v-if="hasTitleSlot")
        slot(name="title")
      v-card-title(v-else class="text-h5") {{ title }}
      v-divider(v-if="showDividers")
      v-card-text
        slot(name="content")
      v-divider(v-if="showDividers")
      v-card-actions.pb-4
        v-spacer
        template(v-if="hasActionSlot")
          slot(name="actions")
        template(v-else)
          v-btn(
            v-if="onSave"
            @click="save"
            :disabled="!canSave"
            :color="currentTheme === 'theme--dark' ? 'iprovaLight' : 'iprovaDark'"
            :dark="canSave"
            :loading="isLoading"
            elevation="0"
            large
          ) Save
          v-btn(
            v-if="onReset"
            @click="reset"
            :disabled="!canReset"
            color="iprovaDark"
            :dark="canReset"
            :loading="isLoading"
            elevation="0"
            large
          ) Reset
          v-btn(
            v-if="onDelete"
            @click="mDelete"
            :disabled="!canDelete"
            color="error"
            :loading="isLoading"
            elevation="0"
            large
          ) Delete
        v-btn(
          v-if="cancelable"
          @click="cancel"
          elevation="0"
          large
        ) Cancel
        v-spacer
</template>

<script>
export default {
  name: 'GenericDialog',
  inject: ['currentTheme'],
  props: {
    width: {
      type: String,
      default: '30%'
    },
    maxWidth: {
      type: String,
      default: undefined
    },
    isPersistent: {
      type: Boolean,
      default: false
    },
    showDividers: {
      type: Boolean,
      default: false
    },
    onSave: {
      type: Function,
      default: undefined
    },
    onReset: {
      type: Function,
      default: undefined
    },
    onDelete: {
      type: Function,
      default: undefined
    },
    cancelable: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    canSave: {
      default: true,
      type: Boolean
    },
    canReset: {
      default: true,
      type: Boolean
    },
    canDelete: {
      default: true,
      type: Boolean
    },
    eventBus: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    onOpen: {
      type: Function,
      default: null
    },
    persistentOnSave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openDialog: this.value,
      isLoading: false
    }
  },
  computed: {
    hasActionSlot() {
      return Boolean(this.$slots.actions)
    },
    hasTitleSlot() {
      return Boolean(this.$slots.title)
    }
  },
  watch: {
    value() {
      this.openDialog = this.value
    },
    openDialog() {
      this.$emit('input', this.openDialog)
      if (this.openDialog && this.onOpen) this.onOpen()
    }
  },
  mounted() {
    if (this.eventBus) {
      this.eventBus.$on('close', this.close)
    }
  },
  methods: {
    close() {
      this.openDialog = false
    },
    cancel() {
      this.$emit('cancel')
      this.close()
    },
    clickOutside() {
      if (this.isPersistent) return
      this.$emit('click-outside')
    },
    async save() {
      this.isLoading = true
      try {
        await this.onSave()
        if (!this.persistentOnSave) this.close()
      } finally {
        this.isLoading = false
      }
    },
    async reset() {
      this.isLoading = true
      try {
        await this.onReset()
      } finally {
        this.isLoading = false
      }
    },
    // The reason this function is called 'mDelete' and not 'delete' is because delete is a
    // keyword in javascript
    async mDelete() {
      this.isLoading = true
      try {
        await this.onDelete()
        this.close()
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: break-word;
  justify-content: center;
}

.dialogContainer {
  z-index: 1003;
}

</style>
